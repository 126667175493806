export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// LOANS CONSTS
export const GET_LOANS = "GET_LOANS";
export const GET_LOAN_TOTALS = "GET_LOAN_TOTALS";
export const GET_MONTHLY = "GET_MONTHLY";
export const ADD_LOAN = "ADD_LOAN";
export const DELETE_LOAN = "DELETE_LOAN";
export const SET_CURRENT_LOAN = "SET_CURRENT_LOAN";
export const SET_DELETE_LOAN = "SET_DELETE_LOAN";
export const CLEAR_DELETE_LOAN = "CLEAR_DELETE_LOAN";
export const CLEAR_CURRENT_LOAN = "CLEAR_CURRENT_LOAN";
export const UPDATE_LOAN = "UPDATE_LOAN";
// export const ROLLOVER_LOANS = 'ROLLOVER_LOANS';
export const FILTER_LOAN = "FILTER_LOAN";
export const CLEAR_FILTER_LOAN = "CLEAR_FILTER_LOAN";
export const LOAN_ERROR = "LOAN_ERROR";

// ACCOUNT CONSTS
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";
export const CLEAR_CURRENT_ACCOUNT = "CLEAR_CURRENT_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const FILTER_ACCOUNT = "FILTER_ACCOUNT";
export const CLEAR_FILTER_ACCOUNT = "CLEAR_FILTER_ACCOUNT";
export const ACCOUNT_ERROR = "ACCOUNT_ERROR";

// REPORT CONSTS
export const GET_REPORTS = "GET_REPORTS";
export const GET_ADHOC_REPORT = "GET_ADHOC_REPORT";

// AUTH CONSTS
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const USER_NOT_LOADED = "USER_NOT_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
