import { DateTime } from "luxon";
import React, { Fragment, useContext, useEffect, useState } from "react";
import PeriodContext from "../../context/period/periodContext";

const MissingPeriods = () => {
  const periodContext = useContext(PeriodContext);
  const { getMissingPeriods, missingPeriods, createPeriod } = periodContext;

  useEffect(() => {
    getMissingPeriods();
    // eslint-disable-next-line
  }, []);

  const createAPeriod = () => {
    let p = periodToCreate.period_to_create;
    if (p && p !== null && p !== "Please Select.") {
      let pDate = DateTime.fromFormat(p, "dd/LL/yyyy");
      createPeriod(pDate.toFormat("yyyy-LL-dd"));
    }
  };

  const [periodToCreate, setPeriodToCreate] = useState({
    period_to_create: null,
  });

  const onChange = (e) => {
    const new_val = e.target.value;

    setPeriodToCreate({
      ...periodToCreate,
      [e.target.name]: new_val,
    });
  };

  const buttonStyle = "btn btn-outline-primary btn-sm";

  return (
    <Fragment>
      <span>
        {missingPeriods?.[0] ? (
          <span>
            <select
              name="period_to_create"
              className="format-control"
              onChange={onChange}
            >
              <option key="Please Select." value="Please Select.">
                Please select.
              </option>
              {missingPeriods.map((p) => (
                <option key={p} value={p}>
                  {p}
                </option>
              ))}
            </select>
            <button className={buttonStyle} onClick={() => createAPeriod()}>
              Create
            </button>
            <button className={buttonStyle} onClick={() => getMissingPeriods()}>
              refresh
            </button>
          </span>
        ) : (
          <span>
            Periods up to date, click{" "}
            <button
              className="buttonAsLink"
              onClick={() => getMissingPeriods()}
            >
              refresh
            </button>{" "}
            to check again
          </span>
        )}
      </span>
    </Fragment>
  );
};

export default MissingPeriods;
