import React, { useState, Fragment } from "react";
import TextFilter from "../common/TextFilter";
import PropTypes from "prop-types";

const FilterSortBar = ({
  title,
  recordCount,
  textFilterOptions,
  sortFilterOptions,
  onSortChangeParent,
  setShowTable,
  filterOn,
  clearFilter,
  loading,
}) => {
  const [sort, setSort] = useState("sort");
  const onSortChange = (e) => {
    setSort(e.target.value);
    onSortChangeParent(e.target.value);
  };

  const showStyleButtons = () => (
    <div className="btn-group">
      <button
        key="showStyleList"
        className="btn p-0"
        onClick={() => setShowTable(true)}
      >
        <span
          key="showStyleList-icon"
          className={"material-icons " + vertStyle}
        >
          list_alt
        </span>
      </button>
      <button
        key="showStyleGrid"
        className="btn p-0"
        onClick={() => setShowTable(false)}
      >
        <span
          key="showStyleGrid-icon"
          className={"material-icons " + vertStyle}
        >
          dashboard
        </span>
      </button>
    </div>
  );

  const showSortDropdown = (sortOptions) => (
    <select
      key={"dropdown-sort"}
      className="form-select form-select-lg text-secondary p-2 ps-3"
      onChange={(e) => onSortChange(e)}
      value={sort}
      style={{ fontSize: "1em" }}
    >
      <option value="sort">Sort</option>
      {sortOptions?.length
        ? sortOptions.map((option, idx) => (
            <option key={"sort-list-" + idx} value={option.sort}>
              {option.title}
            </option>
          ))
        : ""}
      <option value="sort">Sort</option>
    </select>
  );

  const vertStyle = "h-100 d-flex align-items-center";
  const displayRecordCount = (recordCount) => {
    let rcUndefined = recordCount === undefined || recordCount === "undefined";
    let displayCount = !rcUndefined && !loading ? `(${recordCount})` : "";
    return displayCount;
  };

  return (
    <Fragment>
      <div className="row mb-1 w-100 m-0" key={"sortFilterBar-card2"}>
        <div className="col-md-4 p-0 mb-1" key={"sortFilterBar-card2-col0"}>
          <span className={vertStyle}>
            <div className="row w-100" key={"sortFilterBar-card2"}>
              <div className="col-8" key={"sortFilterBar-card2-col0"}>
                <div
                  className={"h4 text-primary bold " + vertStyle}
                  style={{ display: "none" }}
                >
                  <strong>
                    {title} {displayRecordCount(recordCount)}
                  </strong>
                </div>
              </div>
              <div className="col-4 g-0  mb-1" key={"sortFilterBar-card2-col1"}>
                <span className={vertStyle + " w-100 justify-content-end"}>
                  <button
                    key={"sortFilterBar-btn-reset"}
                    className="btn btn-sm btn-primary"
                    onClick={() => clearFilter()}
                    disabled={!filterOn}
                  >
                    Reset
                  </button>
                </span>
              </div>
            </div>
          </span>
        </div>
        <div
          className="col-md-4 g-0 pe-md-1  mb-1"
          key={"sortFilterBar-card2-col2"}
        >
          <TextFilter options={textFilterOptions} />
        </div>
        <div
          className="col-md-4 g-0 ps-md-1  mb-1"
          key={"sortFilterBar-card2-col3"}
        >
          <div className={vertStyle}>
            {showSortDropdown(sortFilterOptions)}
            {showStyleButtons()}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FilterSortBar.propTypes = {
  title: PropTypes.string.isRequired,
  recordCount: PropTypes.string.isRequired,
  textFilterOptions: PropTypes.object.isRequired,
  sortFilterOptions: PropTypes.array.isRequired,
  onSortChangeParent: PropTypes.func.isRequired,
  setShowTable: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  filterOn: PropTypes.bool,
  loading: PropTypes.bool,
};
export default FilterSortBar;
