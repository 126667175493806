export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// ITEMS CONSTS
export const GET_ITEM = "GET_ITEM";
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEM_TOTALS = "GET_ITEM_TOTALS";
export const GET_MONTHLY = "GET_MONTHLY";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
export const SET_DELETE_ITEM = "SET_DELETE_ITEM";
export const CLEAR_DELETE_ITEM = "CLEAR_DELETE_ITEM";
export const CLEAR_CURRENT_ITEM = "CLEAR_CURRENT_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
// export const ROLLOVER_ITEMS = 'ROLLOVER_ITEMS';
export const FILTER_ITEM = "FILTER_ITEM";
export const CLEAR_FILTER_ITEM = "CLEAR_FILTER_ITEM";
export const ITEM_ERROR = "ITEM_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_ERROR = "CLEAR_ERROR";
