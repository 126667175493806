// supports multiple filter types
//    1. text box fuzzy search on 'status', 'code', 'date_due'
//    2. button on totals - paid, overdue, soon, upcoming
//    3. button on status - paid, not paid, not due, blank
//  improvements
//    * filter works if items exist but not if no items matched - should show blank list
//        maybe need to add FilterOn: true / false when filter clicked, then balance

import { daysBetweenDates, isNumeric, isDate, today } from "./functionsCommon";

// read data from record object
//    if the field contains subobject, split the field name to read the data
export const readData = (record, fldName) => {
  //  "from.to.dateTime"
  let flds = fldName.split(".");
  let data = record;
  for (let i = 0; i < flds.length; i++) {
    if (data?.[flds[i]]) {
      data = data[flds[i]];
    } else {
      return "";
    }
  }
  return data;
};

// comparison function
export const getFieldValue = (record, key, value) => {
  let data = readData(record, key);
  return data && data.match(value);
};

// used for old fuzzy search on multiple fields
export const checkFieldValueFromList = (record, fields, values) => {
  for (let value of values) {
    const regex = new RegExp(`${value.value}`, "gi");
    for (let field of fields) {
      if (getFieldValue(record, field, regex)) return true;
    }
  }
  return false;
};

export const checkDuePeriod = (key, balance) => {
  let daysDue = daysBetweenDates(balance.date_due, today());

  if (key === "paid")
    return isNumeric(balance.paid) && isDate(balance.date_paid);
  if (key === "overdue")
    return daysDue < 0 && balance.status.toLowerCase() !== "paid";
  if (key === "soon") {
    return (
      daysDue !== null &&
      daysDue >= 0 &&
      daysDue <= 4 &&
      balance?.status?.toLowerCase() !== "paid"
    );
  }
  if (key === "upcoming")
    return (
      (daysDue === null || daysDue > 4) &&
      balance?.status?.toLowerCase() !== "paid"
    );
};

export const checkStatus = (key, record) => {
  return record.status.toLowerCase() === key.toLowerCase();
};

export const checkIfMatches = (record, action, fields) => {
  // if passed with new filter json structure, check payload for filter type
  //    else do old search for fuzzy search on status, code, due_date
  // console.log("functionsFilters:checkIfMatches");

  let data = action.payload.criteria;
  if (data["action"] && data["action"] === "filter") {
    if (data["type"] === "textCompare") {
      // check for actual status - paid, not paid, not due, blank
      let result = checkStatus(data["values"][0], record);
      return result;
    } else {
      return checkDuePeriod(data["values"][0], record);
    }
  } else {
    return checkFieldValueFromList(record, fields, data);
  }
};
