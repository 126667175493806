import { DateTime } from "luxon";
import PropTypes from "prop-types";

import React, { Fragment, useContext } from "react";
import ItemContext from "../../context/item/itemContext";

import { findIndexInObj, ShowValueOrName } from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseButton,
  modalButton,
} from "../../utils/functionsForms";

const ItemItem = ({ item, fields, isTable }) => {
  const itemContext = useContext(ItemContext);
  const { setCurrentItem, setDeleteItem } = itemContext;

  const cardClass = ["light", "text-white", "light"];
  const buttonStyle =
    "btn btn-outline-" + (isTable ? "primary" : cardClass[2]) + " btn-sm";

  const fns = {
    btn_Edit: function btn_Edit() {
      return ActionButton(buttonStyle, setCurrentItem, "Edit", item);
    },
    btn_Delete: function btn_Delete() {
      return modalButton(
        "deleteModal",
        () => clickedDelete(item._id),
        buttonStyle,
        "Delete"
      );
    },
  };

  const clickedDelete = (itemId) => {
    setDeleteItem(itemId);
  };

  const displayFields = (cardFields, leftright, fields, record) =>
    cardFields[leftright].map((name) => {
      let i = findIndexInObj(fields, "field", name);

      return (
        <div key={"card-field-" + name + "-" + record._id}>
          {name.substring(0, 4) === "btn_"
            ? fns[name]()
            : ShowValueOrName(name, fields[i].type, record[fields[i].field])}
          <br />
        </div>
      );
    });

  const displayCardContent = (cardDetail, fields, record) =>
    ["left", "right"].map((leftright) => (
      <div
        className="col"
        key={"card-fields-col-" + leftright + "-" + record._id}
      >
        <div
          className={leftright === "right" ? "float-end" : "float-start"}
          key={"card-fields-" + leftright + "-" + record._id}
        >
          {displayFields(cardDetail, leftright, fields, record)}
        </div>
      </div>
    ));

  const cardBody = {
    left: ["dateStart", "expectedAmount"],
    right: ["dateEnd", "active"],
  };
  const cardFooter = {
    left: ["dateStart"],
    right: ["dateEnd"],
  };

  const showCollapseHeader = () =>
    collapseButton("item-" + item._id, item.item, cardClass[1]);

  const displayEditDeleteButtons = () => (
    <Fragment>
      {fns["btn_Edit"]()}
      {fns["btn_Delete"]()}
    </Fragment>
  );

  const displayFooterFields = () =>
    displayCardContent(cardFooter, fields, item);

  const ShowMobileCode = () => {
    let k = "card-" + item._id;

    return isTable ? (
      <Fragment>
        <td key={"cell-btns-body-row2" + item?._id}>
          {displayEditDeleteButtons()}
        </td>
        {item &&
          fields &&
          fields.map((fld) => {
            let fieldName = fld.field;
            let value = item[fieldName] ? item[fieldName] : "";
            if (fld.type.toLowerCase() === "date") {
              value = value ? DateTime.fromISO(value).toFormat("dd/LL/yy") : "";
            }
            if (fld.type.toLowerCase() === "float") {
              value = value ? "$" + value : "";
            }
            let k = "cell-" + fieldName + "-" + item._id;

            return (
              <td className="small align-middle" key={k}>
                {value}
              </td>
            );
          })}
      </Fragment>
    ) : (
      <div className={`card bg-${cardClass[0]} ${cardClass[1]}`} key={k}>
        <div className="card-header" key={k + "-header"}>
          <div className="row w-100 g-0" key={k + "-header-r1"}>
            <div className="col-8" key={k + "-header-r1c1"}>
              <div className="float-start" key={k + "-header-r1c1-d1"}>
                {showCollapseHeader()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="collapse card-body"
          key={"card-body-" + item._id}
          id={"item-" + item._id}
          style={{ padding: "5px" }}
        >
          <div className="row" key={"card-body-row1" + item._id}>
            {displayCardContent(cardBody, fields, item)}
          </div>
          <div className="row" key={"card-body-row2" + item._id}>
            <div
              className="d-flex justify-content-center"
              key={"card-body-buttons-" + item._id}
            >
              {displayEditDeleteButtons()}
            </div>
          </div>
        </div>

        <div className="card-footer" key={"card-footer-" + item._id}>
          <div className="row w-100 g-0" key={"card-footer-row1" + item._id}>
            {displayFooterFields()}
          </div>
        </div>
      </div>
    );
  };

  return <Fragment>{ShowMobileCode()}</Fragment>;
};

ItemItem.propTypes = {
  item: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  isTable: PropTypes.bool,
};

export default ItemItem;
