export const BudgetFields = [
  { field: "name", title: "Budget", type: "string" },
  {
    field: "items",
    title: "Items",
    type: "string",
    input: ["list", "items"],
  },
  { field: "dateStart", title: "Start", type: "date" },
  { field: "dateEnd", title: "End", type: "date" },
  { field: "active", title: "Active", type: "boolean" },
  { field: "comments", title: "Comments", type: "string" },
];
