import React, { useReducer } from "react";
import axios from "axios";
import BudgetContext from "./budgetContext";
import budgetReducer from "./budgetReducer";
import {
  GET_BUDGET,
  GET_BUDGETS,
  GET_BUDGET_ACTIVE,
  ADD_BUDGET,
  DELETE_BUDGET,
  UPDATE_BUDGET,
  SET_CURRENT_BUDGET,
  CLEAR_CURRENT_BUDGET,
  BUDGET_ERROR,
  GET_MONTHLY,
  FILTER_BUDGET,
  CLEAR_FILTER_BUDGET,
  CLEAR_ERRORS,
  SET_DELETE_BUDGET,
  CLEAR_DELETE_BUDGET,
} from "../typesBudget";

let debug = 0;
let db = debug >= 1;
let dp = "com.budgetstate";
import PropTypes from "prop-types";

const BudgetState = (props) => {
  const initialState = {
    budget: null,
    activeBudget: null,
    budgets: null,
    current: null,
    filtered: null,
    filterOn: false,
    deleteId: null,
    error: [],
    budgetTotals: null,
    monthly: null,
  };

  const [state, dispatch] = useReducer(budgetReducer, initialState);

  const cleanFilters = (filters) => {
    if (filters) {
      for (const property in filters)
        if (filters[property] === "" || filters[property] === null)
          delete filters[property];
    }
    return filters;
  };

  // Get Budget
  const getActiveBudget = async (period) => {
    let lm = dp + ".getActiveBudget";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let res = [];
      res = await axios.post("/api/budgets/active/", { period }, config);

      dispatch({
        type: GET_BUDGET_ACTIVE,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getBudgets:end`);
    } catch (err) {
      dispatch({
        type: BUDGET_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Budget
  const getBudget = async (filters) => {
    let lm = dp + ".getBudget";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      filters = cleanFilters(filters);

      let res = [];
      // get the current budgets
      res = await axios.get(`/api/budgets/`, { params: filters });

      dispatch({
        type: GET_BUDGET,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getBudgets:end`);
    } catch (err) {
      dispatch({
        type: BUDGET_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Budgets
  const getBudgets = async (filters, sort) => {
    let lm = dp + ".getBudgets";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      filters = cleanFilters(filters);

      let res = [];
      // get the current budgets
      res = await axios.get(`/api/budgets/all`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_BUDGETS,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getBudgets:end`);
    } catch (err) {
      dispatch({
        type: BUDGET_ERROR,
        payload: err.message,
      });
    }
  };

  // Filter Contacts
  const filterBudgets = (array) => {
    dispatch({
      type: FILTER_BUDGET,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_BUDGET });
  };

  // Get Budgets
  const getMonthly = async (filters) => {
    let lm = dp + ".getMonthly";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      filters = cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/budgets/monthly`, { params: filters });

      dispatch({
        type: GET_MONTHLY,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getBudgets:end`);
    } catch (err) {
      dispatch({
        type: BUDGET_ERROR,
        payload: err.message,
      });
    }
  };

  // Add Contact
  const addBudget = async (budget) => {
    let lm = dp + ".addBudget";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (db) console.log(`${lm}`);

    await axios
      .post("/api/budgets", budget, config)
      .then((response) => {
        dispatch({
          type: ADD_BUDGET,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: BUDGET_ERROR,
          payload: err,
        });
      });
  };

  // Update Contact
  const updateBudget = async (budget) => {
    let lm = dp + ".updateBudget";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(`/api/budgets/${budget._id}`, budget, config);

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_BUDGET, payload: res.data });
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: BUDGET_ERROR,
        payload: err,
      });
    }
  };

  const deleteBudget = async (id) => {
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/budgets/${id}`);

      dispatch({ type: DELETE_BUDGET, payload: id });
    } catch (err) {
      dispatch({
        type: BUDGET_ERROR,
        payload: err,
      });
    }
  };

  const setCurrentBudget = (budget) => {
    dispatch({ type: SET_CURRENT_BUDGET, payload: budget });
  };

  const clearCurrentBudget = () => {
    dispatch({ type: CLEAR_CURRENT_BUDGET });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteBudget = (budget) => {
    dispatch({ type: SET_DELETE_BUDGET, payload: budget });
  };

  const clearDeleteBudget = () => {
    dispatch({ type: CLEAR_DELETE_BUDGET });
  };

  return (
    <BudgetContext.Provider
      value={{
        budget: state.budget,
        activeBudget: state.activeBudget,
        budgets: state.budgets,
        current: state.current,
        filtered: state.filtered,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        budgetTotals: state.budgetTotals,
        monthly: state.monthly,
        error: state.error,
        getBudget,
        getActiveBudget,
        getBudgets,
        getMonthly,
        addBudget,
        updateBudget,
        deleteBudget,
        setCurrentBudget,
        clearCurrentBudget,
        setDeleteBudget,
        clearDeleteBudget,
        filterBudgets,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </BudgetContext.Provider>
  );
};

BudgetState.propTypes = {
  children: PropTypes.object,
};

export default BudgetState;
