import React, { useReducer } from "react";
import axios from "axios";
import AccountContext from "./accountContext";
import accountReducer from "./accountReducer";

import {
  GET_ACCOUNTS,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
  SET_CURRENT_ACCOUNT,
  CLEAR_CURRENT_ACCOUNT,
  ACCOUNT_ERROR,
  IMPORT_ACCOUNT,
  GET_IMPORT_TEMPLATE,
  CLEAR_IMPORT_RESULT,
  FILTER_ACCOUNT,
  CLEAR_FILTER_ACCOUNT,
  CLEAR_DELETE_ACCOUNT,
  SET_DELETE_ACCOUNT,
} from "../types";
import PropTypes from "prop-types";
import { cleanFilters } from "../../utils/functionsCommon";

const AccountState = (props) => {
  const initialState = {
    accounts: null,
    current: null,
    filtered: null,
    filterOn: false,
    deleteId: null,
    error: null,
    import_template: null,
    import_result: null,
  };

  const [state, dispatch] = useReducer(accountReducer, initialState);

  // Get Accounts
  const getAccounts = async (filters, sort) => {
    try {
      if (filters) cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/accounts/`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_ACCOUNTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: err.message,
      });
    }
  };

  // Add Contact
  const addAccount = async (account) => {
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      console.log("add accounts: calling api");
      console.log("add accounts:  with following data:-");
      console.log(account);
      const res = await axios.post("/api/accounts", account, config);
      console.log(res);

      dispatch({
        type: ADD_ACCOUNT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Update Contact
  const updateAccount = async (account) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(
        `/api/accounts/${account._id}`,
        account,
        config
      );

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_ACCOUNT, payload: res.data });
    } catch (err) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const deleteAccount = async (id) => {
    try {
      await axios.delete(`/api/accounts/${id}`);

      dispatch({ type: DELETE_ACCOUNT, payload: id });
    } catch (err) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const setCurrentAccount = (account) => {
    dispatch({ type: SET_CURRENT_ACCOUNT, payload: account });
  };

  const clearCurrentAccount = () => {
    dispatch({ type: CLEAR_CURRENT_ACCOUNT });
  };

  // Get Import Template
  const getImportTemplate = async () => {
    try {
      const res = await axios.get("/api/accounts/getimporttemplate");

      dispatch({
        type: GET_IMPORT_TEMPLATE,
        payload: res,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ACCOUNT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Update Contact
  const importAccounts = async (import_data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var theData = [{ data: import_data }];

      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(`/api/accounts/import/`, theData, config);

      dispatch({ type: IMPORT_ACCOUNT, payload: res.data });
    } catch (err) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const clearImportResult = () => {
    dispatch({ type: CLEAR_IMPORT_RESULT });
  };

  // Filter Contacts
  const filterAccounts = (array) => {
    dispatch({
      type: FILTER_ACCOUNT,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_ACCOUNT });
  };

  const setDeleteAccount = (account) => {
    dispatch({ type: SET_DELETE_ACCOUNT, payload: account });
  };

  const clearDeleteAccount = () => {
    dispatch({ type: CLEAR_DELETE_ACCOUNT });
  };

  return (
    <AccountContext.Provider
      value={{
        accounts: state.accounts,
        current: state.current,
        import_template: state.import_template,
        import_result: state.import_result,
        filterOn: state.filterOn,
        filtered: state.filtered,
        deleteId: state.deleteId,
        filterAccounts,
        clearFilter,
        getAccounts,
        importAccounts,
        addAccount,
        updateAccount,
        deleteAccount,
        setCurrentAccount,
        clearCurrentAccount,
        setDeleteAccount,
        clearDeleteAccount,
        getImportTemplate,
        clearImportResult,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

AccountState.propTypes = {
  children: PropTypes.object,
};

export default AccountState;
