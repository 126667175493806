import React, { useState, useContext, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import BalanceContext from "../../context/balance/balanceContext";
import AccountContext from "../../context/account/accountContext";

import {
  isArray,
  findInObj,
  // returnISODate,
  fldUpdIfEmp,
} from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseMultiButton,
  showFields,
} from "../../utils/functionsForms";
import {
  BalanceCode,
  cardCategoryBalance,
} from "../../utils/functionsBalances";
import { isHide } from "../../utils/functionsTables";

const BalanceFormAddEdit = ({ add, fields, isMobile, isTable, hideCols }) => {
  const balanceContext = useContext(BalanceContext);
  const { current, addBalance, updateBalance, clearCurrentBalance } =
    balanceContext;

  const accountContext = useContext(AccountContext);
  const { accounts } = accountContext;

  // create default balance set
  let defaultBalanceSet = {};
  for (let field of fields) defaultBalanceSet[field.field] = "";

  const [balance, setBalance] = useState(defaultBalanceSet);

  useEffect(() => {
    // set the balance as default balance if adding a record, or the current record if edit
    if (add) {
      setBalance(defaultBalanceSet);
    } else {
      setBalance(current);
    }
    // eslint-disable-next-line
  }, [balanceContext, current]);

  const daysForWarning = 4;
  const cardClass = add
    ? ["primary", "text-white", "light"]
    : cardCategoryBalance(current, daysForWarning);

  const arrays = { accounts: accounts };
  const buttonAlign = isTable ? "justify-content" : "justify-content-center";
  const buttonColour = isTable ? "btn-secondary" : "btn-light";
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const setPaidAmount = (e) => {
    let value = balance.paid;
    let values = {
      min: balance?.minimum || 0,
      due: balance?.due || 0,
      out: balance?.outstanding || 0,
    };
    if (values?.[e]) value = values[e];

    setBalance({ ...balance, paid: value });
  };

  const setPaidDate = (e) => {
    let values = {
      paidToday: new Date().toISOString(),
      paidDueDate: balance.date_due,
    };
    setBalance({ ...balance, date_paid: values?.[e] ?? balance?.date_due });
  };

  const onBlur = (e, a) => {
    let name, value;
    let nb = {...balance}

    if (e?.target?.name && (e.target.name === "due" || e.target.name === "outstanding") && e?.target?.value) {

      name = e.target.name
      value = e.target.value

      // autofill the outstanding and minimum balance if the type is loan or bill
      nb = fillValuesIfBillOrLoan(e, nb)

      // autofill the available balance if limit exists and outstanding is entered
      nb = fillAvailableIfOutstanding(e, nb)
    }

    setBalance({
      ...nb,
      [name]: value,
    });
  }


  const onChange = (e, a) => {
    let name, value;
    let nb = {...balance}

    // array is passed when status button pressed
    if (isArray(e) && e.length === 2) {
      name = e[0];
      value = e[1];

      // default the paid value as minimum and date as today
      if (name === "status" && value === "paid") {
        nb.date_paid = fldUpdIfEmp(
          nb?.date_paid,
          new Date().toISOString()
        );
        nb.paid = fldUpdIfEmp(nb?.paid, nb?.minimum);
      }

      // a.action exists on account dropdown change
      // find the _id out of the account object as the value
    } else if (a && a.action) {
      name = "account";
      value = findInObj(accounts, "_id", e.value);

      // any other field updates
    } else {
      name = e.target.name;
      let field = findInObj(fields, "field", name); // get field object from field def

      if (field.type === "date") {
        // default null if empty string is passed
        // value = e.target.value === "" ? null : returnISODate(e.target.value);
        value = e.target.value === "" ? null : e.target.value;
      } else {
        value = e.target.value;
      }
      // fill the outstanding and minimum balance if the type is loan or bill
      // nb = fillValuesIfBillOrLoan(e, nb)

      // fill the available balance if limit exists and outstanding is entered
      // nb = fillAvailableIfOutstanding(e, nb)
    }

    setBalance({
      ...nb,
      [name]: value,
    });
  };

  const fillValuesIfBillOrLoan = (e, balance) => {
    if (
      e.target.name === "due" &&
      balance.account.type &&
      ["loan", "bill"].includes(balance?.account?.type.toLowerCase())
    ) {
      balance.outstanding = e.target.value;
      balance.minimum = e.target.value;
      balance.available = fldUpdIfEmp(balance?.available, 0);
      balance.rewards = fldUpdIfEmp(balance?.rewards, 0);
    }
    return balance
  }

  const fillAvailableIfOutstanding = (e, balance) => {
    if (
      e.target.name === "outstanding" &&
      e?.target?.value &&
     !isNaN(e?.target?.value) && 
      balance?.account?.limit &&
      balance?.account?.limit > 0
    ) {
      balance.available = balance.account.limit - e?.target?.value;
    }
    return balance
  }

  const onDateClear = (e) => {
    setBalance({
      ...balance,
      [e.target.name]: null,
    });
  };

  const onSaveAdd = (balance) => {
    if (add) {
      addBalance(balance);
    } else {
      updateBalance(balance);
    }
    clearForm();
  };

  const clearForm = () => {
    if (add) {
      setBalance(defaultBalanceSet);
    } else {
      clearCurrentBalance();
    }
  };

  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(buttonStyle, onSaveAdd, "Save", balance);
    },

    btn_Add: function btn_Add() {
      return ActionButton(buttonStyle, onSaveAdd, "Add", balance);
    },

    btn_Cancel: function btn_Delete() {
      return add
        ? cancelAddForm()
        : ActionButton(buttonStyle, clearCurrentBalance, "Cancel", "");
    },
  };

  const cancelAddForm = () =>
    collapseMultiButton(
      ".multi-collapse",
      "multiBalance1 multiBalance2",
      "Cancel",
      buttonStyle,
      false,
      clearForm
    );

  // const bAdd = false;
  const fieldArgs = {
    arrays: arrays,
    fields: fields,
    record: balance,
    onChange: onChange,
    onBlur: onBlur,
    onFocusOut: onBlur,
    onDateClear: onDateClear,
    isMobile: isMobile,
    add: add,
  };

  const showTitle = (k) =>
    add
      ? collapseMultiButton(
          ".multi-collapse",
          k + "-multiBalance1 multiBalance2",
          "Add Balance",
          buttonStyle,
          false,
          null
        )
      : `Edit Balance: ${BalanceCode(balance.account)}`;

  const showPaidTypeButtons = () => (
    <div className={"d-flex mt-1 " + buttonAlign}>
      {ActionButton(buttonStyle, setPaidAmount, "Min", "min")}
      {ActionButton(buttonStyle, setPaidAmount, "Due", "due")}
      {ActionButton(buttonStyle, setPaidAmount, "Out", "out")}
    </div>
  );

  const showPaidTodayButtons = () => (
    <div className={"d-flex mt-1 " + buttonAlign}>
      {ActionButton(buttonStyle, setPaidDate, "Paid Today", "paidToday")}
      {ActionButton(buttonStyle, setPaidDate, "Paid On Due", "paidDueDate")}
    </div>
  );

  const showFormActionButtons = () => (
    <div className={"d-flex mt-1 " + buttonAlign}>
      {add ? fns["btn_Add"]() : fns["btn_Save"]()}
      {fns["btn_Cancel"]()}
    </div>
  );

  const showMobileCode = () => {
    let className = add
      ? "bg-primary"
      : `card bg-${cardClass[0]} ${cardClass[1]}`;
    let k = add ? "add" : "edit-" + balance._id;
    let style = { isTable: isTable };
    let actStyle = isHide(hideCols, "Actions") ? { display: "none" } : {};

    return style?.isTable ? (
      <Fragment>
        <td className={"align-start"} key={"cell-btns2" + k} style={actStyle}>
          {showFormActionButtons()}
          {showPaidTypeButtons()}
          {showPaidTodayButtons()}
        </td>
        {showFields(fieldArgs, { ...style, hideCols })}
      </Fragment>
    ) : (
      <Fragment>
        <div className={`card ${className}`} key={"card-1"}>
          <div className="card-header">{showTitle(k)}</div>
          <div
            className={(add ? "collapse " : "") + " multi-collapse"}
            id={k + "-multiBalance1"}
          >
            <div className="card-body">
              <form id={"form-" + k}>
                {showFields(fieldArgs, { isTable: false })}
              </form>
            </div>
            {showPaidTypeButtons()}
            {showPaidTodayButtons()}
            {add ? showFormActionButtons() : ""}
          </div>
          <div className="card-footer">
            {!add ? showFormActionButtons() : ""}
          </div>
        </div>
      </Fragment>
    );
  };

  return showMobileCode();
};

BalanceFormAddEdit.propTypes = {
  add: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
};

export default BalanceFormAddEdit;
