export const BudgetItemFields = [
  { field: "dueDay", title: "Day", type: "number" },
  { field: "item", title: "Item", type: "string" },
  { field: "description", title: "Desc", type: "string" },
  { field: "expectedAmount", title: "Cost", type: "currency" },
  { field: "actualAmount", title: "Actual", type: "currency" },
  { field: "dateStart", title: "Start", type: "date" },
  { field: "dateEnd", title: "End", type: "date" },
  { field: "active", title: "Active", type: "boolean" },
  { field: "comments", title: "Comments", type: "string" },
];
