import React, { useContext, useEffect } from "react";
import BalanceContext from "../../context/balance/balanceContext";
import PeriodContext from "../../context/period/periodContext";
import { formatValue, isArray } from "../../utils/functionsCommon";

const Reports = ({ report_name, isMobile }) => {
  const balanceContext = useContext(BalanceContext);
  const { getReports, report_data } = balanceContext;

  const periodContext = useContext(PeriodContext);
  const { currentPeriod } = periodContext;

  useEffect(() => {
    if (currentPeriod !== null) getReports(currentPeriod, report_name);

    // eslint-disable-next-line
  }, []);

  const totals = {};
  const fieldData = {};

  // const findProp = (fields, name, prop) => {
  //   let t = "";
  //   for (let f of fields) {
  //     if (f.source === name) {
  //       t = f[prop];
  //       break;
  //     }
  //   }
  //   return t === "" ? "string" : t; // return as string if no type found
  // };

  const findProps = (fields, name) => {
    let t = "";
    for (let f of fields) {
      if (f.source === name) {
        t = f;
        break;
      }
    }
    return t === "" ? "string" : t; // return as string if no type found
  };

  const showHeaderRow = (report_name, field) => (
    <th key={report_name + "-header-row" + field.title}>{field.title}</th>
  );

  const showTotalCell = (report_name, total, rIdx) => {
    return (
      <td key={report_name + "-td-total" + rIdx}>
        {totals?.[total.source]
          ? formatData(totals?.[total.source], total.type)
          : ""}
      </td>
    );
  };

  const showDataRow = (report_name, total, rIdx) => {
    let keyId = "";
    if (isArray(total._id)) {
      keyId = Object.values(total._id).join("-");
    } else {
      keyId = total._id;
    }

    return (
      <tr key={report_name + "-tr-" + rIdx}>
        {Object.keys(total).map((field, index) =>
          showDataCell(report_name, field, total[field], index, keyId)
        )}
      </tr>
    );
  };

  const showDataCell = (report_name, field_name, field_data, index, id) => {
    // check if field is array field with multiple items
    // if not, spread the one item into an array
    let checks;
    if (isArray(field_data)) {
      checks = Object.keys(field_data).map((fld) => "_id[" + fld + "]");
    } else {
      checks = [field_name];
    }

    // check the fields type in the report_data.[].fields for field data (type, title, etc.)
    // add to fieldData to use for display types an other
    for (let check of checks) {
      if (!fieldData[check]) {
        let props = findProps(report_data[report_name].fields, check);
        fieldData[check] = props;
      }
    }

    // normalise the data into an array if it's only one piece
    // so all data can be handle the same way
    if (!isArray(field_data)) field_data = [field_data];

    // return the row and add totals if required
    return Object.keys(field_data).map((fldName) => {
      let data = field_data[fldName];
      let k = report_name + "-cell-" + id + "-" + fldName;
      let fldIdx =
        field_name && field_data.length > 1
          ? "_id[" + fldName + "]"
          : field_name;
      let fldData = fieldData?.[fldIdx];
      if (fldData.group === "sum") {
        totals[fldIdx] = totals[fldIdx] ? totals[fldIdx] + data : data;
      }
      return showActualRow(k, data, fldData?.type);
    });
  };

  const showActualRow = (key, data, type) => (
    <td key={key}>{formatData(data, type)}</td>
  );

  const formatData = (value, data_type) => {
    return formatValue(data_type, value);
  };

  const showMobileCode = () => {
    return (
      <table
        className="table table-light table-striped"
        key={report_name + "-table"}
      >
        <thead key={report_name + "-thead"}>
          {report_data !== undefined && report_name in report_data ? (
            <tr key={report_name + "-thr"}>
              {report_data[report_name].fields.map((f) =>
                showHeaderRow(report_name, f)
              )}
            </tr>
          ) : (
            <tr>
              <th>loading</th>
            </tr>
          )}
        </thead>
        <tbody key={report_name + "-tbody"}>
          {report_data !== undefined && report_name in report_data ? (
            report_data[report_name].data.map((f, idx) => {
              return showDataRow(report_name, f, idx);
            })
          ) : (
            <tr>
              <td>loading</td>
            </tr>
          )}
        </tbody>
        <tfoot key={report_name + "-tfoot"}>
          <tr key={report_name + "-tbody-tr"}>
            {report_data !== undefined && report_name in report_data ? (
              report_data[report_name].fields.map((f, idx) => {
                return showTotalCell(report_name, f, idx);
              })
            ) : (
              <td></td>
            )}
          </tr>
        </tfoot>
      </table>
    );
  };

  return isMobile ? showMobileCode() : showMobileCode();
};

export default Reports;
