import React, { useState, useContext, useRef } from "react";
import { DateTime } from "luxon";
import Reports from "./Reports";
import BalanceContext from "../../context/balance/balanceContext";
import { CSVLink } from "react-csv";

const ShowAllReports = () => {
  const balanceContext = useContext(BalanceContext);
  const { report_data } = balanceContext;

  const [showReports, setShowReports] = useState({
    bankTotals: false,
    balanceTotals: false,
    dueDateTotals: false,
    dateTotals: false,
  });

  const csvlink = useRef("");

  const dateTime = DateTime.local();
  // we use a date string to generate our filename.
  const dateStr = dateTime.toFormat("ccc-LL-LLL-hh-mm");

  const downloadReportHeaders = {
    balanceTotals: [
      { label: "Status", key: "_id.status" },
      { label: "Outstanding", key: "outstanding" },
      { label: "Due", key: "due" },
      { label: "Minimum", key: "minimum" },
      { label: "Available", key: "available" },
    ],
    bankTotals: [
      { label: "Bank", key: "_id[0]" },
      { label: "Outstanding", key: "outstanding" },
      { label: "Due", key: "due" },
      { label: "Minimum", key: "minimum" },
      { label: "Available", key: "available" },
    ],
    dueDateTotals: [
      // { label: "Due Date", key: "_id.date_due" },
      { label: "Due Date", key: "_id[0]" },
      { label: "Status", key: "_id[1]" },
      // { label: "Status", key: "_id.status" },
      { label: "Outstanding", key: "outstanding" },
      { label: "Due", key: "due" },
      { label: "Minimum", key: "minimum" },
      { label: "Available", key: "available" },
    ],
    dateTotals: [
      // { label: "Due Date", key: "_id.date_due" },
      { label: "Due Date", key: "_id[0]" },
      { label: "Bank", key: "_id[1]" },
      // { label: "Bank", key: "_id._id[0]" },
      { label: "Outstanding", key: "outstanding" },
      { label: "Due", key: "due" },
      { label: "Minimum", key: "minimum" },
      { label: "Available", key: "available" },
    ],
  };

  const onClickShowReport = (report) => {
    setShowReports({
      ...showReports,
      [report]: showReports[report] ? false : true,
    });
  };

  const showReport = (name, title) => {
    const keyId = "report-" + name;
    const hasReport = report_data && report_data[name];
    const hasData = hasReport && report_data[name]?.data;
    const style = `btn btn${
      showReports[name] ? "" : "-outline"
    }-primary  btn-sm`;

    return (
      <div key={keyId}>
        <div className="form-group" key={keyId + "-title"}>
          {title}&nbsp;{" "}
          <button
            key={keyId + "-button-show"}
            className={style}
            type="button"
            onClick={() => onClickShowReport(name)}
          >
            {showReports[name] ? "hide" : "show"}
          </button>
          &nbsp;
          {hasData ? (
            <CSVLink
              ref={csvlink}
              name="csvlink"
              filename={name.replace(" ", "-") + `-${dateStr}.csv`}
              data={hasData ? report_data[name].data : []}
              headers={downloadReportHeaders[name]}
            >
              <button
                key={keyId + "-button-download"}
                className={style}
                type="button"
              >
                Download
              </button>
            </CSVLink>
          ) : (
            ""
          )}
        </div>
        <div key={keyId + "-body"}>
          {showReports[name] ? (
            <div key={keyId + "-detail"} style={{ overflow: "auto" }}>
              <Reports report_name={name}></Reports>
              <br />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const ShowTheReports = () => (
    <div>
      {showReport("balanceTotals", "Balance Totals")}
      {showReport("bankTotals", "Bank Totals")}
      {showReport("dueDateTotals", "Date Due Totals")}
      {showReport("dateTotals", "Date Totals")}
    </div>
  );

  return ShowTheReports();
};

export default ShowAllReports;
