import { checkIfMatches } from "../../utils/functionsFilters";
import {
  GET_ITEM,
  GET_ITEMS,
  GET_ITEM_TOTALS,
  GET_MONTHLY,
  // COPY_ITEM_PERIOD,
  // IMPORT_ITEM,
  ADD_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  SET_CURRENT_ITEM,
  CLEAR_CURRENT_ITEM,
  // GET_IMPORT_TEMPLATE,
  // CLEAR_IMPORT_RESULT,
  // GET_REPORTS,
  FILTER_ITEM,
  CLEAR_FILTER_ITEM,
  ITEM_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_ITEM,
  CLEAR_DELETE_ITEM,
} from "../typesItem";

const fields = ["name", "dateStart", "dateEnd"];

const itemReducer = (state, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case ADD_ITEM:
      return {
        ...state,
        items: state?.items?.length
          ? [action.payload, ...state.items]
          : state.items,
      };

    case GET_ITEM:
      return {
        ...state,
        item: action.payload,
      };

    case UPDATE_ITEM:
      return {
        ...state,
        item: action.payload,
        // as above state is being passed and state is immutable, need to make a copy
      };

    // case UPDATE_ITEM:
    //   return {
    //     ...state,
    //     items: state.items.map((item) =>
    //       item._id === action.payload._id ? action.payload : item
    //     ),
    //     // as above state is being passed and state is immutable, need to make a copy
    //   };

    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
      };

    // case DELETE_ITEM:
    //   return {
    //     ...state,
    //     items: state.items.filter(
    //       (item) => item._id !== action.payload
    //     ),
    //   };

    case SET_CURRENT_ITEM:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAR_CURRENT_ITEM:
      return {
        ...state,
        current: null,
      };

    case SET_DELETE_ITEM:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_ITEM:
      return {
        ...state,
        deleteId: null,
      };

    case GET_ITEM_TOTALS:
      return {
        ...state,
        itemTotals: action.payload,
      };

    case GET_MONTHLY:
      return {
        ...state,
        monthly: action.payload,
      };

    case FILTER_ITEM:
      return {
        ...state,
        filtered: state.items.filter((item) =>
          checkIfMatches(item, action, fields)
        ),
        filterOn: true,
      };

    case CLEAR_FILTER_ITEM:
      return {
        ...state,
        filtered: null,
        filterOn: false,
      };

    case ITEM_ERROR:
      return {
        ...state,
        error: state?.error?.length
          ? [action.payload, ...state.error]
          : state.error,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default itemReducer;
