
export const AccountHeaders = [
  { label: "Name", key: "name" },
  { label: "Code", key: "code" },
  { label: "Type", key: "type" },
  { label: "Bank", key: "bank" },
  { label: "Country", key: "country" },
  { label: "Country Code", key: "country_code" },
  { label: "Active", key: "active" },
];

export const AccountSortFilterOptions = [
  { sort: "name.asc", title: "Name - A to Z" },
  { sort: "name.desc", title: "Name - Z to A" },
  { sort: "bank.asc", title: "Bank - A to Z" },
  { sort: "bank.desc", title: "Bank - Z to A" },
  { sort: "country_code.asc", title: "Country - A to Z" },
  { sort: "country_code.desc", title: "Country - Z to A" },
  { sort: "type.asc", title: "Type - A to Z" },
  { sort: "type.desc", title: "Type - Z to A" },
  { sort: "limit.asc", title: "Limit - small to large" },
  { sort: "limit.desc", title: "Limit - large to small" },

];

export const AccountFields = [
  { field: "code", title: "Code", type: "string" },
  {
    field: "name",
    title: "Name",
    type: "string",
  },
  {
    field: "country",
    title: "Country",
    type: "string",
  },
  {
    field: "country_code",
    title: "Country Code",
    type: "string",
  },
  {
    field: "bank",
    title: "Bank",
    type: "string",
  },
  {
    field: "limit",
    title: "Limit",
    type: "number",
  },
  {
    field: "type",
    title: "Type",
    type: "string",
  },
  {
    field: "active",
    title: "Active",
    type: "boolean",
  },
  {
    field: "created",
    title: "Created",
    type: "date",
  },
  {
    field: "updated",
    title: "Updated",
    type: "date",
  },
];
