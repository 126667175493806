import {
  GET_BALANCES,
  GET_BALANCE_TOTALS,
  GET_TOTALS,
  GET_TOTALS_DAYSDUE,
  COPY_BALANCE_PERIOD,
  IMPORT_BALANCE,
  ADD_BALANCE,
  UPDATE_BALANCE,
  DELETE_BALANCE,
  SET_CURRENT_BALANCE,
  CLEAR_CURRENT_BALANCE,
  GET_IMPORT_TEMPLATE,
  CLEAR_IMPORT_RESULT,
  GET_REPORTS,
  FILTER_BALANCE,
  CLEAR_FILTER_BALANCE,
  BALANCE_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_BALANCE,
  CLEAR_DELETE_BALANCE,
} from "../types";

import { isArray } from "../../utils/functionsCommon";
import { checkIfMatches } from "../../utils/functionsFilters";
import { setReportData, updateItemArray } from "../../utils/functionsState";
import { BalanceFilterFields } from "../../utils/fieldDefBalance";

let idSet;

const deleteItemFromArray = (itemArray, itemToDeleteId) =>
  itemArray.filter((record) => record._id !== itemToDeleteId);

const balanceReducer = (state, action) => {
  switch (action.type) {
    case GET_BALANCES:
      idSet =
        state.filterOn && state.filtered
          ? state.filtered.map((item) => item._id)
          : [];

      return {
        ...state,
        balances: action.payload,
        filtered:
          state.filtered && state.filterOn && action?.payload?.length
            ? action.payload.filter((item) => idSet.includes(item._id))
            : [],
        loading: false,
      };

    case GET_TOTALS:
      return {
        ...state,
        totals: action.payload,
        loading: false,
      };

    case GET_TOTALS_DAYSDUE:
      return {
        ...state,
        totalsDaysDue: action.payload,
        loading: false,
      };

    case GET_BALANCE_TOTALS:
      return {
        ...state,
        balanceTotals: action.payload,
        loading: false,
      };

    case GET_REPORTS:
      return {
        ...state,
        report_data: setReportData(state.report_data, action.payload),
        loading: false,
      };

    case IMPORT_BALANCE:
      return {
        ...state,
        import_result: action.payload,
        currentPeriod: action.period,
        loading: false,
      };

    case CLEAR_IMPORT_RESULT:
      return {
        ...state,
        import_result: null,
        loading: false,
      };

    case ADD_BALANCE:
      return {
        ...state,
        balances: [action.payload, ...state.balances],
        loading: false,
      };

    case UPDATE_BALANCE:
      return {
        ...state,
        balances: updateItemArray(state.balances, action.payload, "_id"),
        filtered:
          state.filtered && state.filterOn
            ? updateItemArray(state.filtered, action.payload, "_id")
            : false,
        loading: false,
      };

    case DELETE_BALANCE:
      return {
        ...state,
        balances: deleteItemFromArray(state.balances, action.payload),
        filtered:
          state.filtered && state.filterOn
            ? deleteItemFromArray(state.filtered, action.payload)
            : false,
        // balances: state.balances.filter(
        //   (balance) => balance._id !== action.payload
        // ),
        // filtered: state.filtered && state.filterOn ? state.filtered.filter(
        //   (balance) => balance._id !== action.payload
        // ): false,
        loading: false,
      };

    case SET_CURRENT_BALANCE:
      return {
        ...state,
        current: action.payload,
      };

    case SET_DELETE_BALANCE:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_BALANCE:
      return {
        ...state,
        deleteId: null,
      };

    case CLEAR_CURRENT_BALANCE:
      return {
        ...state,
        current: null,
      };

    case COPY_BALANCE_PERIOD:
      return {
        ...state,
        balances: action.payload,
        // as above state is being passed and state is immutable, need to make a copy
      };

    case GET_IMPORT_TEMPLATE:
      return {
        ...state,
        import_template: action.payload,
        // balances: state.balances,
        // as above state is being passed and state is immutable, need to make a copy
      };

    case FILTER_BALANCE:
      return {
        ...state,
        filtered: state.balances.filter((balance) =>
          checkIfMatches(balance, action, BalanceFilterFields)
        ),
        filterOn: true,
        filters: action?.payload?.criteria || [],
      };

    case CLEAR_FILTER_BALANCE:
      return {
        ...state,
        filtered: null,
        filterOn: false,
        filters: null,
      };

    case BALANCE_ERROR:
      return {
        ...state,
        error: isArray(state.error)
          ? [action.payload, ...state.error]
          : [action.payload],
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default balanceReducer;
