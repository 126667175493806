import {
  CREATE_PERIOD,
  GET_ALLPERIODS,
  GET_MAXPERIOD,
  SET_CURRENTPERIOD,
  CLEAR_CURRENTPERIOD,
  GET_MISSINGPERIODS,
  PERIOD_ERROR,
  CLEAR_ERRORS,
} from "../types";

// eslint-disable-next-line
const periodReducer = (state, action) => {
  switch (action.type) {
    case CREATE_PERIOD:
      return {
        ...state,
        allPeriods: [...state.allPeriods, action.payload.newPeriod],
        currentPeriod: action.payload.newPeriod,
        error: action.payload.importResult,
      };

    case GET_ALLPERIODS:
      return {
        ...state,
        allPeriods: action.payload,
      };

    case GET_MAXPERIOD:
      return {
        ...state,
        maxPeriod: action.payload,
        // balances: state.balances,
        // as above state is being passed and state is immutable, need to make a copy
      };

    case GET_MISSINGPERIODS:
      return {
        ...state,
        missingPeriods: action.payload,
      };

    case SET_CURRENTPERIOD:
      return {
        ...state,
        currentPeriod: action.payload,
      };

    case CLEAR_CURRENTPERIOD:
      return {
        ...state,
        currentPeriod: null,
      };

    case PERIOD_ERROR:
      return {
        ...state,
        error:
          state.error === null || state.error === []
            ? [action.payload]
            : [action.payload, ...state.error],
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default periodReducer;
