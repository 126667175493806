import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  // useState
} from "react";
import BalanceContext from "../../context/balance/balanceContext";
import PeriodContext from "../../context/period/periodContext";
// import AccountContext from "../../context/account/accountContext";

import BalanceItem from "./BalanceItem";
import BalanceFormAddEdit from "./BalanceFormAddEdit";
import TotalsDisplay from "../common/TotalsDisplay";

import Spinner from "../layout/Spinner";
// import FilterSortBar from "../common/FilterSortBar";
import StatusFilter from "../common/StatusFilter";
import {
  // getRecordCount,
  getRecords,
} from "../../utils/functionsCommon";
import PropTypes from "prop-types";
import { showFieldHeaders, showHiddenCols } from "../../utils/functionsTables";

// BalanceList

// improvements
//    * when no records exist, filters & buttons are split across 3 columns
//    * can only add one balance at a time, maybe need to clear state
//    * maybe add side menu for items not regularly used instead of accordian
//    * maybe disable or make not active instead of delete or at least add a delete pop-up box to confirm
//    * fix which fields shown on collapsed cards - current two dates exist on paid cards

let debug = 0;
const db = debug >= 1 ? true : false;

const BalanceList = ({ fields, isMobile, isTable, onSortChangeParent }) => {
  const periodContext = useContext(PeriodContext);
  const { currentPeriod } = periodContext;

  // const accountContext = useContext(AccountContext);
  // const { accounts } = accountContext;
  // console.log(accounts && accounts?.length);

  const balanceContext = useContext(BalanceContext);
  const {
    balances,
    current,
    getBalances,
    loading,
    filterBalances,
    filtered,
    filterOn,
    clearFilter,
    // getAdhocReport,
    totalsDaysDue,
  } = balanceContext;

  useEffect(() => {
    if (db) console.log(`com.bal.list.useeffect:start`);
    getBalances({ period: currentPeriod });
    if (db) console.log(`com.bal.list.useeffect:finish`);
    // if (currentPeriod !== null) getAdhocReport(currentPeriod, "dueReport");

    // eslint-disable-next-line
  }, [currentPeriod, current]);
  // }, [currentPeriod, filtered, current]);

  const [hideCols, setHideCols] = useState([]);

  const [sort, setSort] = useState("sort");
  const onSortChange = (e) => {
    setSort(e.target.value);
    onSortChangeParent(e.target.value);
  };

  // const [showTable, setShowTable] = useState(false);
  // let showTable = false;

  const statusFilterOptions = {
    dataset: balanceContext.balances,
    filter: balanceContext.filterBalances,
    clearFilter: balanceContext.clearFilter,
    statusList: ["paid", "not paid", "not due", "blank"],
    filterFields: ["status"],
    blankValue: "not entered",
  };

  const totalsDisplayBarOptions = {
    totals: totalsDaysDue,
    defaultCalcField: "Due",
    filterFields: ["date_due"],
    filterType: ["dateCompare"],
    reportStyles: {
      paid: ["success", "text-white"],
      overdue: ["danger", "text-white"],
      soon: ["warning", "text-white"],
      upcoming: ["light", "text-dark"],
    },
    titleFields: ["overdue", "soon", "upcoming", "paid"],
    calcFields: ["Outstanding", "Due", "Minimum", "Available"],
    filter: filterBalances,
    clearFilter: clearFilter,
  };

  const showBalances = (balances) =>
    balances &&
    balances.map((balance) =>
      isTable ? (
        <tr key={"balanceList-col-" + balance._id}>
          {showBalanceRow(balance)}
        </tr>
      ) : (
        <div className="col" key={"balanceList-col-" + balance._id}>
          {showBalanceRow(balance)}
        </div>
      )
    );

  const showBalanceRow = (record) => {
    let showAddEdit = current !== null && current._id === record._id;

    return showBalanceTag(
      showAddEdit,
      false,
      isTable,
      isMobile,
      record._id,
      record
    );
  };

  const showBalanceTag = (showAddEdit, add, isTable, isMobile, key, record) => {
    return showAddEdit ? (
      <BalanceFormAddEdit
        add={add}
        key={key}
        fields={fields}
        isMobile={isMobile}
        // isTable={showTable}
        isTable={isTable}
        hideCols={hideCols}
      />
    ) : (
      <BalanceItem
        key={key}
        balance={record}
        fields={fields}
        isMobile={isMobile}
        // isTable={showTable}
        isTable={isTable}
        hideCols={hideCols}
      />
    );
  };

  const showClearFilter = () => (
    <div className="row my-auto" key={"balanceList-card-add"}>
      <div className="col w-100 text-center" key={"balanceList-cardbody-add"}>
        Add a balance or change /&nbsp;
        <button
          key={"balanceList-btn-reset"}
          className="btn btn-link align-baseline"
          role="link"
          onClick={() => clearFilter()}
        >
          <u>reset</u>
        </button>
        &nbsp; the filter
      </div>
    </div>
  );

  const hideColsSet = {
    v: hideCols,
    f: setHideCols,
  };

  const sortColsSet = {
    v: sort,
    f: onSortChange,
  };

  const showContent = (records, isMobile) => {
    // let recordCount = getRecordCount(balances, filtered, filterOn);
    // console.log(recordCount);
    return (
      <Fragment>
        <div className="row mb-1 w-100 g-0" key={"balanceList-card1"}>
          <TotalsDisplay options={totalsDisplayBarOptions} />
          <StatusFilter options={statusFilterOptions} />
        </div>

        {/* {!showTable ? ( */}
        {isTable ? (
          <div
            className="col cols-12"
            key={"balanceList-Table"}
            style={{ overflowX: "auto" }}
          >
            <div className="row" key={"loanList-hiddenCols"}>
              <div className="col-12" key={"loanList-hiddenCols"}>
                {showHiddenCols(hideColsSet)}
              </div>
            </div>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {showFieldHeaders(
                    [{ field: "Actions", title: "Actions" }],
                    hideColsSet
                  )}
                  {showFieldHeaders(fields, hideColsSet, sortColsSet)}
                </tr>
              </thead>
              <tbody className="divTableBody">
                <tr key={"balanceList-col-add"}>
                  {showBalanceTag(true, true, isTable, isMobile, "add-balance")}
                </tr>
                {showBalances(records)}
              </tbody>
            </table>
          </div>
        ) : (
          <Fragment>
            <div
              className="row row-cols-1 row-cols-md-3 g-2"
              key={"balanceList-cardrow"}
            >
              <div className="col" key={"balanceList-col-add"}>
                {showBalanceTag(true, true, isTable, isMobile, "add-balance")}
              </div>
              {records && records !== null && records.length > 0
                ? showBalances(records)
                : showClearFilter()}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  if (loading) return <Spinner />;
  const records = getRecords(balances, filtered, filterOn);
  return showContent(records, isMobile);
};

BalanceList.propTypes = {
  fields: PropTypes.array.isRequired,
  onSortChangeParent: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
};

export default BalanceList;
