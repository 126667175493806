import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AccountFields, AccountHeaders, AccountSortFilterOptions } from "../../utils/fieldDefAccount";
import AlertContext from "../../context/alert/alertContext";
import AccountContext from "../../context/account/accountContext";
import AccountList from "./AccountList";
import DeleteModal from "../common/DeleteModal";
import { CSVLink } from "react-csv";
import { modalDeleteHandler } from "../../utils/functionsForms";
import { getRecordCount } from "../../utils/functionsCommon";
import FilterSortBar from "../common/FilterSortBar";

const Accounts = () => {
  const accountContext = useContext(AccountContext);
  const {
    getAccounts,
    accounts,
    loading,
    deleteAccount,
    deleteId,
    filterAccounts,
    filterOn,
    filtered,
    clearFilter,
  } = accountContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const csvlink = useRef("");
  const [showTable, setShowTable] = useState(false);


  const showError = (context) => {
    if (context?.error?.length > 0) {
      setAlert(context.error, "warning");
      context.clearErrors();
    }
  };

  useEffect(() => {
    showError(accountContext);
    // getAccounts();
    // eslint-disable-next-line
  }, [accounts]);

  let isMobile = true;

  const accordianSections = [];

  const textFilterOptions = {
    title: "Accounts",
    datasets: [accounts],
    filter: filterAccounts,
    filtered: filtered,
    clearFilter: clearFilter,
    filters: [
      {
        dataset: accounts,
        fields: ["name", "bank", "code", "country_code", "country", "type"],
      },
    ],
  };


  const ShowMobileCode = () => (
    <div id="accordion">
      {accordianSections.map((section, index) => (
        <div className="card" key={`card-${index}`}>
          <div className="card-header" key={`card-header-${index}`}>
            <a
              className="collapsed card-link"
              data-bs-toggle="collapse"
              href={`#collapse${index}`}
            >
              {section.title}
            </a>
          </div>
          <div
            id={`collapse${index}`}
            className="collapse"
            data-parent="#accordion"
            key={`card-accordian-${index}`}
          >
            <div className="card-body" key={`card-body-${index}`}>
              {section.type === "Function"
                ? section.content()
                : section.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const ShowNonMobileCode = () => (
    <div>
      <div className="divTable blueTable">
        <div className="divTableHeading">
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Download Accounts</h4>
              {accounts !== null && !loading ? (
                <CSVLink
                  ref={csvlink}
                  name="csvlink"
                  data={accountContext.accounts}
                  headers={AccountHeaders}
                >
                  <font className="white_text">Click to download</font>
                </CSVLink>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const onSortChangeParent = (sortValue) => {
    getAccounts({}, sortValue);
  };

  let recordCount = getRecordCount(accounts, filtered, filterOn);
  return (
    <Fragment>
      <FilterSortBar
        title="Accounts"
        recordCount={recordCount}
        textFilterOptions={textFilterOptions}
        sortFilterOptions={AccountSortFilterOptions}
        filterOn={filterOn}
        clearFilter={clearFilter}
        onSortChangeParent={onSortChangeParent}
        setShowTable={setShowTable}
        showTable={showTable}
      />
      {isMobile ? ShowMobileCode() : ShowNonMobileCode()}
      <AccountList
        fields={AccountFields}
        isMobile={isMobile}
        isTable={showTable}
      />
      <DeleteModal
        deleteHandler={() => modalDeleteHandler(deleteAccount, deleteId)}
      />
    </Fragment>
  );
};

export default Accounts;
