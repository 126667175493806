import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import BudgetItem from "./BudgetItem";
import BudgetContext from "../../context/budget/budgetContext";
import BudgetFormAddEdit from "./BudgetFormAddEdit";
import Spinner from "../layout/Spinner";

import { CSVLink } from "react-csv";
import { DateTime } from "luxon";
import {
  formatDLDate,
  getRecordCount,
  getRecords,
} from "../../utils/functionsCommon";
import PropTypes from "prop-types";
import FilterSortBar from "../common/FilterSortBar";

// BudgetList

// improvements
//    * mark budget as green if paid
//    * add filter (by default) to not show paid budgets

let debug = 0;
const db = debug >= 1 ? true : false;
const lm = "com.budgets.budgetlist.";

const BudgetList = ({ fields, isMobile }) => {
  const budgetContext = useContext(BudgetContext);
  const {
    budgets,
    current,
    getBudgets,
    loading,
    filterBudgets,
    filtered,
    filterOn,
    clearFilter,
  } = budgetContext;

  const csvlink = useRef("");

  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (db) console.log(`${lm}:useeffect:start`);
    getBudgets({});
    if (db) console.log(`${lm}:useeffect:finish`);

    // eslint-disable-next-line
  }, [filtered]);

  const textFilterOptions = {
    title: "Budgets",
    datasets: [],
    filter: filterBudgets,
    filtered: filtered,
    clearFilter: clearFilter,
    filters: [
      {
        dataset: budgets,
        fields: ["name"],
      },
    ],
  };

  const sortFilterOptions = [
    { sort: "name.asc", title: "Name - A to Z" },
    { sort: "name.desc", title: "Name - Z to A" },
    { sort: "dateStart.asc", title: "Start - earliest first" },
    { sort: "dateStart.desc", title: "Start - latest first" },
    { sort: "dateEnd.asc", title: "End - earliest first" },
    { sort: "dateEnd.desc", title: "End - latest first" },
  ];

  const onSortChangeParent = (sortValue) => {
    getBudgets({}, sortValue);
  };

  const showBudgetRow = (records) =>
    current !== null && current._id === records._id ? (
      <BudgetFormAddEdit
        add={false}
        key={records._id}
        fields={fields}
        isMobile={isMobile}
      />
    ) : (
      <BudgetItem
        key={records._id}
        budget={records}
        fields={fields}
        isMobile={isMobile}
      />
    );

  const budgetDownloadheaders = [
    { label: "Name", key: "name" },
    { label: "Active", key: "active" },
    { label: "Start", key: "dateStart" },
    { label: "End", key: "dateEnd" },
    { label: "Comments", key: "comments" },
  ];

  const ShowCsvLink = () => {
    const dateTime = DateTime.local();
    // we use a date string to generate our filename.
    const dateStr = dateTime.toFormat("ccc-LL-LLL-HH:mm");
    let data = !budgets
      ? []
      : budgets.map((row) => ({
          ...row,
          dateStart: formatDLDate(row.startDate),
          dateEnd: formatDLDate(row.endDate),
        }));

    return budgets !== null && !loading ? (
      <CSVLink
        ref={csvlink}
        name="csvlink"
        filename={`budget-extract-${dateStr}.csv`}
        data={data}
        headers={budgetDownloadheaders}
      >
        <button className="btn btn-light">Download Budget List</button>
      </CSVLink>
    ) : (
      <span></span>
    );
  };

  const showBudgets = (budgets) =>
    budgets.map((budget) => (
      <div className="col" key={"budgetsList-col-" + budget._id}>
        {showBudgetRow(budget)}
      </div>
    ));

  const showEmptyRecords = () => (
    <div className="card">
      <div className="card-body text-center">
        Add a budget or change /&nbsp;
        <button
          className="btn btn-link align-baseline"
          role="link"
          onClick={() => clearFilter()}
        >
          <u>reset</u>
        </button>
        &nbsp;the filter
      </div>
    </div>
  );

  const showContent = (records, isMobile) => {
    let recordCount = getRecordCount(budgets, filtered, filterOn);

    return (
      <Fragment>
        {/* <div className="card">
          <div className="card-body"> */}
        <FilterSortBar
          title="Budgets"
          recordCount={recordCount}
          textFilterOptions={textFilterOptions}
          sortFilterOptions={sortFilterOptions}
          filterOn={filterOn}
          clearFilter={clearFilter}
          onSortChangeParent={onSortChangeParent}
          setShowTable={setShowTable}
          showTable={showTable}
        />
        {/* </div>
        </div> */}

        {isMobile ? (
          <Fragment>
            <div>
              {/* <div className="card mb-1">
            <BudgetTotalsDisplay budgets={records} />
          </div>
          <div className="card mb-1">
            <BudgetTotalOutstanding budgets={records} />
          </div>
          <div className="card mb-1" style={{ clear: "right" }}>
            <BudgetTextFilter isMobile={isMobile} />
          </div>

          <BudgetMonthlyInstallments /> */}
              <div className="card bg-primary mb-2">
                <div className="card-body">{ShowCsvLink()}</div>
              </div>
            </div>

            {/* <div className="card-columns h-100" style={{ minHeight: "100%" }}> */}
            <div
              className="row row-cols-1 row-cols-md-3 g-2"
              key={"budgetsList-cardrow"}
            >
              <div className="col" key={"budgetsList-col-add"}>
                <BudgetFormAddEdit
                  add={true}
                  fields={fields}
                  isMobile={isMobile}
                />
              </div>
              {records === null ? showEmptyRecords() : showBudgets(records)}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="divTable blueTable">
              <div className="divTableHeading">
                <div className="divTableRow">
                  {fields.map((field, idx) => (
                    <div className="divTableHead" key={"th-" + idx}>
                      {field.title}
                    </div>
                  ))}
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                <BudgetFormAddEdit
                  add={true}
                  fields={fields}
                  isMobile={isMobile}
                />
                {records === null ? showEmptyRecords() : showBudgets(records)}
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  if (loading) return <Spinner />;
  const records = getRecords(budgets, filtered, filterOn);
  return showContent(records, isMobile);
};

BudgetList.propTypes = {
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
};

export default BudgetList;
