import { LoanFilterFields } from "../../utils/fieldDefLoan";
import { isArray } from "../../utils/functionsCommon";
import { checkIfMatches } from "../../utils/functionsFilters";
import { setReportData, updateItemArray } from "../../utils/functionsState";
import {
  GET_LOANS,
  GET_LOAN_TOTALS,
  GET_MONTHLY,
  ADD_LOAN,
  UPDATE_LOAN,
  DELETE_LOAN,
  SET_CURRENT_LOAN,
  CLEAR_CURRENT_LOAN,
  GET_REPORTS,
  FILTER_LOAN,
  CLEAR_FILTER_LOAN,
  LOAN_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_LOAN,
  CLEAR_DELETE_LOAN,
} from "../typesLoans";

let idSet;
const loanReducer = (state, action) => {
  switch (action.type) {
    case GET_LOANS:
      idSet =
        state.filterOn && state.filtered
          ? state.filtered.map((item) => item._id)
          : [];

      return {
        ...state,
        loans: action.payload,
        filtered:
          state.filtered && state.filterOn && action?.payload?.length
            ? action.payload.filter((item) => idSet.includes(item._id))
            : [],
        loading: false,
      };

    case ADD_LOAN:
      return {
        ...state,
        loans: [action.payload, ...state.loans],
        loading: false,
      };

    case UPDATE_LOAN:
      return {
        ...state,
        loans: updateItemArray(state.loans, action.payload, "_id"),
        filtered:
          state.filtered && state.filterOn
            ? updateItemArray(state.filtered, action.payload, "_id")
            : false,
        loading: false,
      };

    case DELETE_LOAN:
      return {
        ...state,
        loans: state.loans.filter((loan) => loan._id !== action.payload),
        loading: false,
      };

    case SET_CURRENT_LOAN:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT_LOAN:
      return {
        ...state,
        current: null,
        loading: false,
      };

    case SET_DELETE_LOAN:
      return {
        ...state,
        deleteId: action.payload,
        loading: false,
      };

    case CLEAR_DELETE_LOAN:
      return {
        ...state,
        deleteId: null,
        loading: false,
      };

    case GET_LOAN_TOTALS:
      return {
        ...state,
        loanTotals: action.payload,
        loading: false,
      };

    case GET_MONTHLY:
      return {
        ...state,
        monthly: action.payload,
        loading: false,
      };

    case GET_REPORTS:
      return {
        ...state,
        report_data: setReportData(state.report_data, action.payload),
        loading: false,
      };

    case FILTER_LOAN:
      return {
        ...state,
        filtered: state.loans.filter((loan) =>
          checkIfMatches(loan, action, LoanFilterFields)
        ),
        filterOn: true,
        filters: action?.payload?.criteria || [],
        loading: false,
      };

    case CLEAR_FILTER_LOAN:
      return {
        ...state,
        filtered: null,
        filterOn: false,
        filters: null,
        loading: false,
      };

    case LOAN_ERROR:
      return {
        ...state,
        error: isArray(state.error)
          ? [action.payload, ...state.error]
          : [action.payload],
        loading: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default loanReducer;
