import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Alerts from "./components/layout/Alerts";
import About from "./components/pages/About";
import Balances from "./components/pages/Balances";
import Budgets from "./components/pages/Budgets";
import Loans from "./components/pages/Loans";
// import Jobs from './components/pages/Jobs';
import Accounts from "./components/pages/Accounts";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/routing/PrivateRoute";
import Home from "./components/pages/Home";

import AlertState from "./context/alert/AlertState";
import BalanceState from "./context/balance/BalanceState";
import BudgetState from "./context/budget/BudgetState";
import ItemState from "./context/item/ItemState";
import LoanState from "./context/loan/LoanState";
// import JobState from './context/job/JobState';
import AccountState from "./context/account/AccountState";
import PeriodState from "./context/period/PeriodState";
import AuthState from "./context/auth/AuthState";

import setAuthToken from "./utils/setAuthToken";

import "./App.css";
import "./table.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <AuthState>
      <BalanceState>
        <BudgetState>
          <ItemState>
            <LoanState>
              <PeriodState>
                <AccountState>
                  {/* <JobState> */}
                  <AlertState>
                    <Router>
                      <Fragment>
                        <Navbar
                          title="Balances"
                          icon="bi bi-wallet white-text"
                        />
                        <div className="container">
                          <Alerts />
                          <Switch>
                            <Route exact path="/" component={Home} />
                            <PrivateRoute
                              exact
                              path="/balances"
                              component={Balances}
                            />
                            <PrivateRoute
                              exact
                              path="/budgets"
                              component={Budgets}
                            />
                            <PrivateRoute
                              exact
                              path="/loans"
                              component={Loans}
                            />
                            <PrivateRoute
                              exact
                              path="/accounts"
                              component={Accounts}
                            />
                            {/* <PrivateRoute exact path='/jobs' component={Jobs} /> */}
                            <Route exact path="/about" component={About} />
                            <Route
                              exact
                              path="/register"
                              component={Register}
                            />
                            <Route exact path="/login" component={Login} />
                          </Switch>
                        </div>
                      </Fragment>
                    </Router>
                  </AlertState>
                  {/* </JobState> */}
                </AccountState>
              </PeriodState>
            </LoanState>
          </ItemState>
        </BudgetState>
      </BalanceState>
    </AuthState>
  );
};

export default App;
