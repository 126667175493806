import React, { useReducer } from "react";
import axios from "axios";
import ItemContext from "./itemContext";
import itemReducer from "./itemReducer";
import {
  GET_ITEM,
  GET_ITEMS,
  ADD_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  SET_CURRENT_ITEM,
  CLEAR_CURRENT_ITEM,
  ITEM_ERROR,
  GET_MONTHLY,
  FILTER_ITEM,
  CLEAR_FILTER_ITEM,
  CLEAR_ERRORS,
  SET_DELETE_ITEM,
  CLEAR_DELETE_ITEM,
} from "../typesItem";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "com.itemstate";

const ItemState = (props) => {
  const initialState = {
    item: null,
    items: null,
    current: null,
    filtered: null,
    filterOn: false,
    deleteId: null,
    error: [],
    itemTotals: null,
    monthly: null,
  };

  const [state, dispatch] = useReducer(itemReducer, initialState);

  const cleanFilters = (filters) => {
    if (filters) {
      for (const property in filters)
        if (filters[property] === "" || filters[property] === null)
          delete filters[property];
    }
    return filters;
  };

  // Get Item
  const getItem = async (filters) => {
    let lm = dp + ".getItem";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      filters = cleanFilters(filters);

      let res = [];
      // get the current items
      res = await axios.get(`/api/items/`, { params: filters });

      dispatch({
        type: GET_ITEM,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getItems:end`);
    } catch (err) {
      dispatch({
        type: ITEM_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Items
  const getItems = async (filters) => {
    let lm = dp + ".getItems";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      filters = cleanFilters(filters);

      let res = [];
      // get the current items
      res = await axios.get(`/api/items/all`, { params: filters });

      dispatch({
        type: GET_ITEMS,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getItems:end`);
    } catch (err) {
      dispatch({
        type: ITEM_ERROR,
        payload: err.message,
      });
    }
  };

  // Filter Contacts
  const filterItems = (array) => {
    dispatch({
      type: FILTER_ITEM,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_ITEM });
  };

  // Get Items
  const getMonthly = async (filters) => {
    let lm = dp + ".getMonthly";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      filters = cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/items/monthly`, { params: filters });

      dispatch({
        type: GET_MONTHLY,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getItems:end`);
    } catch (err) {
      dispatch({
        type: ITEM_ERROR,
        payload: err.message,
      });
    }
  };

  // Add Contact
  const addItem = async (item, budget) => {
    let lm = dp + ".addItem";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (db) console.log(`${lm}`);
    await axios
      .post("/api/budgets/items", { item: item, budgetId: budget._id }, config)
      .then((response) => {
        dispatch({
          type: ADD_ITEM,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ITEM_ERROR,
          payload: err,
        });
      });
  };

  // Update Contact
  const updateItem = async (item, budget) => {
    let lm = dp + ".updateItem";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log(item);
    console.log(budget);

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(
        `/api/budgets/items/${item._id}`,
        { item: item, budgetId: budget._id },
        config
      );

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_ITEM, payload: res.data });
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: ITEM_ERROR,
        payload: err,
      });
    }
  };

  const deleteItem = async (id) => {
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/items/${id}`);

      dispatch({ type: DELETE_ITEM, payload: id });
    } catch (err) {
      dispatch({
        type: ITEM_ERROR,
        payload: err,
      });
    }
  };

  const setCurrentItem = (item) => {
    dispatch({ type: SET_CURRENT_ITEM, payload: item });
  };

  const clearCurrentItem = () => {
    dispatch({ type: CLEAR_CURRENT_ITEM });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteItem = (item) => {
    dispatch({ type: SET_DELETE_ITEM, payload: item });
  };

  const clearDeleteItem = () => {
    dispatch({ type: CLEAR_DELETE_ITEM });
  };

  return (
    <ItemContext.Provider
      value={{
        item: state.item,
        items: state.items,
        current: state.current,
        filtered: state.filtered,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        itemTotals: state.itemTotals,
        monthly: state.monthly,
        error: state.error,
        getItem,
        getItems,
        getMonthly,
        addItem,
        updateItem,
        deleteItem,
        setCurrentItem,
        clearCurrentItem,
        setDeleteItem,
        clearDeleteItem,
        filterItems,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </ItemContext.Provider>
  );
};

ItemState.propTypes = {
  children: PropTypes.object,
};

export default ItemState;
