import React, { useRef, useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import PropTypes from "prop-types";

const TextFilter = ({ options }) => {
  const filterText = useRef("");
  const [filterSet, setFilterSet] = useState({ filters: [] });
  const { title, datasets, filters, filter, filtered, clearFilter } = options;

  const getFilterList = () => {
    let list = [];

    for (let filter of filters) {
      // run through the filters list
      if (filter.dataset && filter.dataset !== null) {
        // check if dataset exists
        for (let field of filter.fields) {
          // run through each field
          for (let data of filter.dataset) {
            if (data[field] !== undefined && !list.includes(data[field]))
              list.push(data[field]);
          }
        }
      }
    }

    let filterList = list.map((item) => {
      return { label: item, value: item };
    });

    return filterList;
  };

  const dataExists = (datasets) => {
    let exists = false;
    for (let data of datasets) {
      if (data && data?.length) {
        exists = true;
        break;
      }
    }
    return exists;
  };

  useEffect(() => {
    if (dataExists(datasets)) setFilterSet({ filters: getFilterList() });
    if (filtered === null) {
      // filterText.current.value = "";
      filterText.current = "";
    }
    //eslint-disable-next-line
  }, [...datasets]);

  const onChange = (e, a) => {
    if (a.action === "create-option") {
      setFilterSet({
        filters: [...filterSet.filters, e[e.length - 1]],
      });
    }
    clearFilter();
    if (e?.length) {
      filter(e);
    }
  };

  const handleInputChange = () => {
    // console.log(e);
  };

  return (
    <form>
      <Creatable
        className=""
        isMulti
        isClearable
        ref={filterText}
        placeholder={title}
        options={filterSet.filters}
        onChange={onChange}
        onInputChange={handleInputChange}
      />
    </form>
  );
};

TextFilter.propTypes = {
  options: PropTypes.object.isRequired,
};

export default TextFilter;
