import React, { useState, useContext, useEffect, Fragment } from "react";
import ItemContext from "../../context/item/itemContext";
import {
  ActionButton,
  collapseMultiButton,
  showFields,
} from "../../utils/functionsForms";

import { isArray, findInObj, returnISODate } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

const ItemFormAddEdit = ({ add, fields, isMobile, isTable, budget }) => {
  const itemContext = useContext(ItemContext);
  const { current, addItem, updateItem, clearCurrentItem } = itemContext;

  let default_item_set = {};
  for (let field of fields) default_item_set[field.field] = "";
  const [item, setItem] = useState(default_item_set);

  useEffect(() => {
    if (add) {
      setItem(default_item_set);
    } else {
      setItem(current);
    }
    // eslint-disable-next-line
  }, [itemContext, current]);

  const cardClass = add
    ? ["light", "text-white", "light"]
    : ["light", "text-white", "light"];

  const arrays = {};

  const buttonAlign = isTable ? "justify-content" : "justify-content-center";

  const buttonStyle = `btn btn-${isTable ? "primary" : "light"} btn-sm`;

  const onChange = (e) => {
    let name, value;

    // array is passed when status button pressed
    if (isArray(e) && e.length === 2) {
      name = e[0];
      value = e[1];
    } else {
      name = e.target.name;
      let field = findInObj(fields, "field", name);

      if (field.type === "date") {
        value = e.target.value === "" ? null : returnISODate(e.target.value);
      } else {
        value = e.target.value;
      }
    }

    setItem({
      ...item,
      [name]: value,
    });
  };

  const onDateClear = (e) => {
    setItem({
      ...item,
      [e.target.name]: null,
    });
  };

  const onSaveAdd = (item) => {
    if (add) {
      addItem(item, budget);
    } else {
      updateItem(item, budget);
    }
    clearForm();
  };

  const clearForm = () => {
    if (add) {
      setItem(default_item_set);
    } else {
      clearCurrentItem();
    }
  };

  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(buttonStyle, onSaveAdd, "Save", item);
    },

    btn_Add: function btn_Add() {
      return ActionButton(buttonStyle, onSaveAdd, "Add", item);
    },

    btn_Cancel: function btn_Delete() {
      return add
        ? cancelAddForm()
        : ActionButton(buttonStyle, clearCurrentItem, "Cancel", "");
    },
  };

  const cancelAddForm = () =>
    collapseMultiButton(
      ".multi-collapseItem",
      "multiItem1 multiItem2",
      "Cancel",
      buttonStyle,
      false,
      clearForm
    );

  // const bAdd = false;
  const fieldArgs = {
    arrays: arrays,
    fields: fields,
    record: item,
    onChange: onChange,
    onDateClear: onDateClear,
    isMobile: isMobile,
    add: add,
  };

  const showTitle = (k) =>
    add
      ? collapseMultiButton(
          ".multi-collapseItem",
          k + "-multiItem1 multiItem2",
          "Add Item",
          buttonStyle,
          false,
          null
        )
      : `Edit Item: ${item.item}`;

  const showFormActionButtons = () => (
    <div className={"d-flex mt-1 " + buttonAlign}>
      {add ? fns["btn_Add"]() : fns["btn_Save"]()}
      {fns["btn_Cancel"]()}
    </div>
  );

  const showMobileCode = () => {
    let className = add
      ? "bg-primary"
      : `card bg-${cardClass[0]} ${cardClass[1]}`;
    let k = add ? "add" : "edit-" + item._id;
    let style = { isTable: isTable };

    return style?.isTable ? (
      <Fragment>
        <td className={"align-start"} key={"cell-btns2" + k}>
          {showFormActionButtons()}
        </td>
        {showFields(fieldArgs, style)}
      </Fragment>
    ) : (
      <Fragment>
        <div className={`card ${className}`} key={"card-1"}>
          <div className="card-header">{showTitle(k)}</div>
          <div
            className={(add ? "collapse " : "") + " multi-collapseItem"}
            id={k + "-multiItem1"}
          >
            <div className="card-body">
              <form id={"form-" + k}>
                {showFields(fieldArgs, { isTable: false })}
              </form>
            </div>
            {/* {showPaidTypeButtons()}
            {showPaidTodayButtons()} */}
            {add ? showFormActionButtons() : ""}
          </div>
          <div className="card-footer">
            {!add ? showFormActionButtons() : ""}
          </div>
        </div>
      </Fragment>
    );
  };

  return showMobileCode();
};

ItemFormAddEdit.propTypes = {
  add: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
  budget: PropTypes.object.isRequired,
};

export default ItemFormAddEdit;
