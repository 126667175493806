const BalanceFields = [
  {
    field: "status",
    title: "Status",
    type: "string",
    align: "center",
    input: ["button", ["not paid", "paid", "not due"]],
  },
  {
    field: "account",
    title: "Account",
    type: "string",
    align: "left",
    input: ["dropdown", "accounts", "code"],
  },
  { field: "period", title: "Period", type: "date", align: "center" },
  { field: "bank", title: "Bank", type: "string", align: "center" },
  { field: "date_due", title: "Date Due", type: "date", align: "center" },
  { field: "due", title: "Due", type: "currency" },
  {
    field: "outstanding",
    title: "Outstanding",
    type: "currency",
  },
  { field: "minimum", title: "Minimum", type: "currency" },
  { field: "available", title: "Available", type: "currency" },
  { field: "rewards", title: "Rewards", type: "number", align: "right" },
  {
    field: "date_paid",
    title: "Date Paid",
    type: "date",
    align: "center",
    additional: [{ title: "Copy", type: "button" }],
  },
  { field: "paid", title: "Paid", type: "currency" },
];

const BalanceDownloadHeaders = [
  { label: "Status", key: "status" },
  { label: "Period", key: "period" },
  { label: "Bank", key: "bank" },
  { label: "Code", key: "code" },
  { label: "Due Date", key: "date_due" },
  { label: "Due", key: "due" },
  { label: "Outstanding", key: "outstanding" },
  { label: "Minimum", key: "minimum" },
  { label: "Available", key: "available" },
  { label: "Rewards", key: "rewards" },
];

const BalanceFilterFields = [
  "status",
  "code",
  "date_due",
  "account.code",
  "account.bank",
  "account.name",
  "account.country_code",
];

module.exports = { BalanceFields, BalanceDownloadHeaders, BalanceFilterFields };
