import React, { Fragment, useContext, useEffect, useState } from "react";
import ItemItem from "./ItemItem";
import ItemContext from "../../context/item/itemContext";
import ItemAddEdit from "./ItemAddEdit";
import Spinner from "../layout/Spinner";

import PropTypes from "prop-types";

const ItemList = ({ fields, isMobile, budget }) => {
  const itemContext = useContext(ItemContext);
  const {
    current,
    // getItems,
    loading,
    filtered,
    filterOn,
    clearFilter,
    // getAdhocReport,
  } = itemContext;

  useEffect(() => {
    // eslint-disable-next-line
  }, [filtered, current]);

  const [showTable, setShowTable] = useState(true);
  // const [sort, setSort] = useState("sort");

  const showItemRow = (records) => {
    let showAddEdit = current !== null && current._id === records._id;

    return showAddEdit ? (
      <ItemAddEdit
        add={false}
        key={records._id}
        fields={fields}
        isMobile={isMobile}
        isTable={showTable}
        setShowTable={setShowTable}
        budget={budget}
      />
    ) : (
      <ItemItem
        key={records._id}
        item={records}
        fields={fields}
        isMobile={isMobile}
        isTable={showTable}
        budget={budget}
      />
    );
  };

  const showItems = (items) =>
    items &&
    items.map((item) =>
      showTable ? (
        <tr key={"itemList-col-" + item._id}>{showItemRow(item)}</tr>
      ) : (
        <div className="col" key={"itemList-col-" + item._id}>
          {showItemRow(item)}
        </div>
      )
    );

  const showClearFilter = () => (
    <div className="row my-auto" key={"itemList-card-add"}>
      <div className="col w-100 text-center" key={"itemList-cardbody-add"}>
        Add a item or change /&nbsp;
        <button
          key={"itemList-btn-reset"}
          className="btn btn-link align-baseline"
          role="link"
          onClick={() => clearFilter()}
        >
          <u>reset</u>
        </button>
        &nbsp; the filter
      </div>
    </div>
  );

  // const showStyleButtons = () => (
  //   <div className="btn-group">
  //     <button
  //       key="showStyleList"
  //       className="btn b-0 g-0 p-0"
  //       style={{ display: "inline" }}
  //       onClick={() => setShowTable(true)}
  //     >
  //       <span
  //         key="showStyleList-icon"
  //         className="material-icons"
  //         style={{ display: "inline" }}
  //       >
  //         list_alt
  //       </span>
  //     </button>
  //     <button
  //       key="showStyleGrid"
  //       className="btn b-0 g-0 p-0"
  //       style={{ display: "inline" }}
  //       onClick={() => setShowTable(false)}
  //     >
  //       <span
  //         key="showStyleGrid-icon"
  //         className="material-icons"
  //         style={{ display: "inline" }}
  //       >
  //         dashboard_outlined
  //       </span>
  //     </button>
  //   </div>
  // );

  // const onSortChange = (e) => {
  //   setSort(e.target.value);
  //   getItems({}, e.target.value);
  // };

  // const showSortDropdown = () => (
  //   <select
  //     key={"dropdown-sort"}
  //     className="input"
  //     onChange={(e) => onSortChange(e)}
  //     value={sort}
  //   >
  //     <option value="sort">Sort</option>
  //     <option value="date_due.asc">Date Due - earliest first</option>
  //     <option value="date_due.desc">Date Due - latest first</option>
  //     <option value="bank.asc">Bank - A to Z</option>
  //     <option value="bank.desc">Bank - Z to A</option>
  //     <option value="available.asc">Available - small to large</option>
  //     <option value="available.desc">Available - large to small</option>
  //   </select>
  // );

  const showContent = (records, isMobile) => (
    <Fragment>
      {/* <div className="row mb-1 w-100" key={"itemList-card1"}>
        <ItemTotalsDisplay items={records} />
        <StatusFilter isMobile={isMobile} />
      </div> */}
      {/* <div className="row mb-2  w-100" key={"itemList-card2"}>
        <div className="col-9" key={"itemList-card2-col1"}>
          <ItemTextFilter isMobile={isMobile} />
        </div>
        <div className="col-2" key={"itemList-card2-col2"}>
          {showSortDropdown()}
        </div>
        <div className="col-1" key={"itemList-card2-col3"}>
          {showStyleButtons()}
        </div>
      </div> */}
      {!showTable ? (
        <Fragment>
          <div
            className="row row-cols-1 row-cols-md-3 g-2"
            key={"itemList-cardrow"}
          >
            <div className="col" key={"itemList-col-add"}>
              <ItemAddEdit
                add={true}
                fields={fields}
                isMobile={isMobile}
                isTable={showTable}
                budget={budget}
              />
            </div>
            {records && records !== null && records.length > 0
              ? showItems(records)
              : showClearFilter()}
          </div>
        </Fragment>
      ) : (
        <div
          className="col cols-12"
          key={"itemList-Table"}
          style={{ overflowX: "auto" }}
        >
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th className="divTableHead">Actions</th>
                {fields.map((field, idx) => (
                  <th className="divTableHead" key={"th-" + idx}>
                    {field.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divTableBody">
              <tr key={"itemList-col-add"}>
                <ItemAddEdit
                  add={true}
                  fields={fields}
                  isMobile={isMobile}
                  isTable={showTable}
                  budget={budget}
                />
              </tr>
              {showItems(records)}
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );

  if (loading) return <Spinner />;

  const getRecords = () => {
    if (filterOn) {
      return filtered.length > 0 ? filtered : null;
    } else {
      return budget?.items !== null && budget?.items?.length > 0
        ? budget.items
        : null;
    }
  };

  const records = getRecords();

  return showContent(records, isMobile);
};

ItemList.propTypes = {
  budget: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
};

export default ItemList;
