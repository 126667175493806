import { DateTime } from "luxon";
import {
  changeCase,
  confirmVal,
  findInObj,
  getLastDayOfMonth,
  today,
} from "./functionsCommon";

// colour for the buttons depending on balance status
export const buttonClasses = [
  { name: "not paid", class: "danger" },
  { name: "not due", class: "secondary" },
  { name: "paid", class: "success" },
  { name: "open", class: "secondary" },
  { name: "matured", class: "success" },
];

// Display friendly balance name for the card title
export const BalanceCode = (account) => {
  if (!account) return "missing account";

  let aName = ["bank", "name"].map((val) =>
    account !== null && account[val] !== undefined && account[val]
      ? account[val]
      : ""
  );
  return aName.join(" ");
};

// Display friendly loan name for the card title
export const LoanCode = (code) => {
  if (!code) return "missing loan";

  let name = code.split(".");
  name.shift();
  let chg = ["upper", "upper", "sentence"];
  let aName = chg.map((c, idx) =>
    name?.[idx] && chg?.[idx] ? changeCase(name[idx], chg[idx]) : ""
  );

  return aName.join(" ");
};

// Display friendly Account Name from account code
export const AccountCode = (code) => {
  if (!code) return "missing account";
  if (!code.includes(".")) return code;

  let name = code.split(".");
  name.shift();
  let chg = ["upper", "upper", "sentence"];
  let aName = chg.map((c, idx) =>
    name?.[idx] && chg?.[idx] ? changeCase(name[idx], chg[idx]) : ""
  );

  return aName.join(" ");
};

// Determine the code color & category based on balance date and / or paid
export const cardCategoryBalance = (bal, daysForWarning) => {
  if (bal === undefined || bal === null)
    return ["success", "text-white", "light"];

  let daysToDue =
    bal.date_due !== undefined && bal.date_due
      ? DateTime.fromISO(bal.date_due).diff(today(), "days")
      : null;

  let card_class = "light";
  let cardHasNoValue =
    (confirmVal(bal.minimum) !== null && confirmVal(bal.minimum) === 0) ||
    confirmVal(bal.date_paid) !== null;
  let cardNotDue = bal.status.toLowerCase() === "not due";

  if (cardHasNoValue || cardNotDue) {
    // paid or nothing due - minimum === 0
    card_class = ["success", "text-white", "light"];
  } else if (
    confirmVal(bal.date_due) === null ||
    bal.status === "not entered"
  ) {
    // information not entered
    card_class = ["secondary", "text-white", "light"];
  } else if (daysToDue > daysForWarning) {
    // pending payment
    card_class = ["warning", "text-dark", "secondary"];
  } else if (daysToDue >= 0) {
    // pending payment due in next 2 days
    card_class = ["warning", "text-white", "light"];
  } else if (daysToDue < 0) {
    // pending over due
    card_class = ["danger", "text-white", "light"];
  }
  return card_class;
};

// Determine the code color & category based on loan status
export const cardCategoryLoan = (loan) => {
  if (loan === undefined || loan === null)
    return ["success", "text-white", "light"];

  let loanCompleted = loan.status.toLowerCase() === "completed";
  let cardClass;
  if (loanCompleted) {
    cardClass = ["success", "text-white", "light"];
  } else {
    cardClass = ["primary", "text-white", "light"];
  }
  return cardClass;
};

export const getAccountFromCon = (accounts, _id) =>
  findInObj(accounts, "_id", _id);

// @name    getPeriodDates
// @desc    return current and next period dates (start, end, period)
export const getPeriodDates = (currentPeriod) => {
  const dp = "routes.periods.getPeriodDates";
  try {
    var new_dates = {};
    new_dates.currentPeriod = DateTime.fromISO(currentPeriod);

    // var d = new_dates.currentPeriod.getDate();
    var m = new_dates.currentPeriod.month;
    var y = new_dates.currentPeriod.year;
    new_dates.currentStart = DateTime.local(y, m, 1);
    new_dates.currentEnd = DateTime.local(y, m, getLastDayOfMonth(y, m));
    new_dates.nextPeriod = new_dates.currentPeriod.plus({ month: 1 });
    new_dates.nextStart = new_dates.currentStart.plus({ month: 1 });
    new_dates.nextEnd = new_dates.currentEnd.plus({ month: 1 });

    return new_dates;
  } catch (err) {
    console.error(`${dp}:error: ${err.message}`);
  }
};
