import React, { useEffect, useContext } from "react";
import BudgetsTag from "../budget/Budget";
import AuthContext from "../../context/auth/authContext";

const Budgets = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="grid-1">
      <div>
        <BudgetsTag />
      </div>
    </div>
  );
};

export default Budgets;
