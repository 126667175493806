import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import BudgetContext from "../../context/budget/budgetContext";
import { BudgetItemFields } from "../../utils/fieldDefBudgetItem";

import ItemList from "./ItemList";

import { DateTime } from "luxon";
import { findIndexInObj, ShowValueOrName } from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseButton,
  modalButton,
} from "../../utils/functionsForms";

const BudgetItem = ({ budget, fields, isMobile }) => {
  const budgetContext = useContext(BudgetContext);
  const {
    setCurrentBudget,
    setDeleteBudget,
    // deleteBudget
  } = budgetContext;

  // const { _id } = budget;
  let cardClass = ["secondary", "text-white", "light"];

  const fns = {
    btn_Edit: function btn_Edit() {
      return ActionButton(buttonStyle, setCurrentBudget, "Edit", budget);
    },
    btn_Delete: function btn_Delete() {
      return modalButton(
        "deleteModal",
        () => clickedDelete(budget._id),
        buttonStyle,
        "Delete"
      );
    },
  };

  const clickedDelete = (budgetId) => {
    setDeleteBudget(budgetId);
  };

  let isTable = false;
  const buttonColour = isTable ? "btn-primary" : "btn-outline-" + cardClass[2];
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const displayFields = (cardFields, leftright, fields, record) =>
    cardFields[leftright].map((name) => {
      let i = findIndexInObj(fields, "field", name);

      return (
        <div key={"card-field-" + name + "-" + record._id}>
          {name.substring(0, 4) === "btn_"
            ? fns[name]()
            : ShowValueOrName(name, fields[i].type, record[fields[i].field])}
          <br />
        </div>
      );
    });

  const displayCardContent = (cardDetail, fields, record) =>
    ["left", "right"].map((leftright) => (
      <div
        className="col"
        key={"card-fields-col-" + leftright + "-" + record._id}
      >
        <div
          // className={leftright === "right" ? "float-start" : "float-end"}
          className={leftright === "right" ? "float-end" : "float-start"}
          key={"card-fields-" + leftright + "-" + record._id}
        >
          {displayFields(cardDetail, leftright, fields, record)}
        </div>
      </div>
    ));

  const cardBody = {
    left: ["dateStart", "dateEnd"],
    right: ["active"],
  };
  const cardFooter = {
    left: ["dateStart"],
    right: ["dateEnd"],
  };

  // const onDelete = () => deleteBudget(_id);

  const ShowMobileCode = () => {
    let budgetClosed =
      budget?.dateEnd && DateTime.fromISO(budget?.dateEnd) < DateTime.now();
    let k = "card-" + budget._id;
    return (
      <div className={"card bg-" + cardClass[0] + " " + cardClass[1]} key={k}>
        <div className="card-header" key={k + "-header"}>
          <div className="row w-100 g-0" key={k + "-header-r1"}>
            <div className="col-8" key={k + "-header-r1c1"}>
              <div className="float-start" key={k + "-header-r1c1-d1"}>
                {collapseButton(
                  "budget-" + budget._id,
                  budget.name,
                  cardClass[1]
                )}
              </div>
            </div>
            <div className="col-4" key={k + "-header-r1c2"}>
              <div className="float-end" key={k + "-header-r1c2-d1"}>
                {budgetClosed ? "Closed" : budget?.dateEnd}
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-body collapse"
          key={"card-body-" + budget._id}
          id={"budget-" + budget._id}
          style={{ padding: "5px" }}
        >
          <div className="row w-100 g-0" key={k + "-content-r1"}>
            {displayCardContent(cardBody, fields, budget)}
          </div>
          <div className="row w-100 g-0" key={k + "-content-r3"}>
            <ItemList
              fields={BudgetItemFields}
              visible={true}
              budget={budget}
            />
          </div>

          <div className="row w-100 g-0" key={k + "-content-r2"}>
            <div
              className="d-flex justify-content-center"
              key={"card-body-buttons-" + budget._id}
            >
              {fns["btn_Edit"]()}
              {fns["btn_Delete"]()}
            </div>
          </div>
        </div>

        <div className="card-footer" key={k + "-footer"}>
          <div className="row w-100 g-0" key={k + "-footer-row"}>
            {displayCardContent(cardFooter, fields, budget)}
          </div>
        </div>
      </div>
    );
  };

  const ShowNonMobileCode = () => {
    let k = "budget" + budget._id;
    return (
      <Fragment>
        <div className="divTableRow" key={"container-" + k}>
          {fields.map((field, idx) => (
            <div className="divTableCell" key={"divTableCell-" + k + "-" + idx}>
              {budget[field.name]
                ? ShowValueOrName(field.name, field.type, budget[field.field])
                : field.title}
            </div>
          ))}
          <div className="divTableCell divButtonBlock">
            {fns["btn_Edit"]()}
            {fns["btn_Delete"]()}
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>{isMobile ? ShowMobileCode() : ShowNonMobileCode()}</Fragment>
  );
};

BudgetItem.propTypes = {
  budget: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
};

export default BudgetItem;
