import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import AccountContext from "../../context/account/accountContext";
import { AccountCode } from "../../utils/functionsBalances";
import { isArray, findInObj, returnISODate } from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseMultiButton,
  showFields,
} from "../../utils/functionsForms";

const AccountFormAddEdit = ({ add, fields, isMobile, isTable }) => {
  const accountContext = useContext(AccountContext);
  const { current, addAccount, updateAccount, clearCurrentAccount } =
    accountContext;

  let default_account_set = {};
  for (let field of fields) {
    default_account_set[field.field] = "";
  }
  const [account, setAccount] = useState(default_account_set);

  useEffect(() => {
    if (add) {
      setAccount(default_account_set);
    } else {
      setAccount(current);
    }
    // eslint-disable-next-line
  }, [accountContext, current]);

  const onChange = (e) => {
    let name, value;

    if (isArray(e) && e.length === 2) {
      name = e[0];
      value = e[1];
      // } else if (a && a.action) {
      //   name = "account";
      //   value = findInObj(accounts, "_id", e.value);
    } else {
      name = e.target.name;

      let field = findInObj(fields, "field", name);
      value =
        field.type === "date"
          ? e.target.value === ""
            ? null
            : returnISODate(e.target.value)
          : e.target.value;
    }

    setAccount({
      ...account,
      [name]: value,
    });
  };

  const onDateClear = (e) => {
    let name = e.target.name;
    let value = null;

    setAccount({
      ...account,
      [name]: value,
    });
  };

  const onSaveAdd = (account) => {
    if (add) {
      addAccount(account);
    } else {
      updateAccount(account);
    }
    clearForm();
  };

  const clearForm = () => {
    if (add) {
      setAccount(default_account_set);
    } else {
      clearCurrentAccount();
    }
  };

  const cardClass = ["secondary", "text-white", "light"];
  const buttonColour = isTable ? "btn-secondary" : "btn-light";
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(buttonStyle, onSaveAdd, "Save", account);
    },

    btn_Add: function btn_Add() {
      return ActionButton(buttonStyle, onSaveAdd, "Add", account);
    },

    btn_Cancel: function btn_Delete() {
      return ActionButton(buttonStyle, clearCurrentAccount, "Cancel", "");
    },
  };

  // const bAdd = false;
  const fieldArgs = {
    arrays: [],
    fields: fields,
    record: account,
    onChange: onChange,
    onDateClear: onDateClear,
    isMobile: isMobile,
    add: add,
  };

  const ShowMobileCode = () =>
    add ? (
      <div className="card bg-primary  mb-1" key={"card-1"}>
        <div className="card-header">
          {collapseMultiButton(
            ".multi-collapse",
            "multiAccount1 multiAccount2",
            "Add Account",
            buttonStyle,
            false,
            null
          )}
        </div>
        <div className="collapse multi-collapse" id="multiAccount1">
          <div className="card-body">
            <form id="form-add">{showFields(fieldArgs)}</form>
          </div>
        </div>
        <div className="collapse multi-collapse" id="multiAccount2">
          <div className="card-footer">
            <div className="d-flex justify-content-center">
              {fns["btn_Add"]()}
              {collapseMultiButton(
                ".multi-collapse",
                "multiAccount1 multiAccount2",
                "Cancel",
                buttonStyle,
                false,
                clearForm
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={`card bg-${cardClass[0]}  mb-1 form-group`}>
        <div className={`card-header ${cardClass[1]}`}>
          {add ? "Add Account" : `Edit Account: ${AccountCode(account?.code)}`}
        </div>
        <div className="card-body">
          <form id={`form-edit-${account._id}`}>
            {showFields({ ...fieldArgs })}
          </form>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-center">
            {add ? fns["btn_Add"]() : fns["btn_Save"]()}
            {!add ? fns["btn_Cancel"]() : ""}
          </div>
        </div>
      </div>
    );

  const ShowNonMobileCode = () => (
    <tr>
      <td style={{ whiteSpace: "nowrap" }}>
        {add ? fns["btn_Add"]() : fns["btn_Save"]()}
        {fns["btn_Cancel"]()}
      </td>
      {showFields(fieldArgs, { isTable: true })}
    </tr>
  );

  return !isTable ? ShowMobileCode() : ShowNonMobileCode();
};

AccountFormAddEdit.propTypes = {
  add: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
};

export default AccountFormAddEdit;
