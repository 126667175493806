export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// BUDGETS CONSTS
export const GET_BUDGET = "GET_BUDGET";
export const GET_BUDGETS = "GET_BUDGETS";
export const GET_BUDGET_ACTIVE = "GET_BUDGET_ACTIVE";
export const GET_BUDGET_TOTALS = "GET_BUDGET_TOTALS";
export const GET_MONTHLY = "GET_MONTHLY";
export const ADD_BUDGET = "ADD_BUDGET";
export const DELETE_BUDGET = "DELETE_BUDGET";
export const SET_CURRENT_BUDGET = "SET_CURRENT_BUDGET";
export const SET_DELETE_BUDGET = "SET_DELETE_BUDGET";
export const CLEAR_DELETE_BUDGET = "CLEAR_DELETE_BUDGET";
export const CLEAR_CURRENT_BUDGET = "CLEAR_CURRENT_BUDGET";
export const UPDATE_BUDGET = "UPDATE_BUDGET";
// export const ROLLOVER_BUDGETS = 'ROLLOVER_BUDGETS';
export const FILTER_BUDGET = "FILTER_BUDGET";
export const CLEAR_FILTER_BUDGET = "CLEAR_FILTER_BUDGET";
export const BUDGET_ERROR = "BUDGET_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_ERROR = "CLEAR_ERROR";
