import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import LoanContext from "../../context/loan/loanContext";
import {
  findIndexInObj,
  ShowValueOrName,
  isObject,
  isArray,
  formatValue,
  getAlign,
} from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseButton,
  modalButton,
} from "../../utils/functionsForms";
import { cardCategoryLoan, LoanCode } from "../../utils/functionsBalances";
import { isHide } from "../../utils/functionsTables";

const LoanItem = ({ loan, fields, isTable, hideCols }) => {
  const loanContext = useContext(LoanContext);
  const {
    setCurrentLoan,
    setDeleteLoan,
    // deleteLoan
  } = loanContext;

  const pageTitle = "Loans";
  const pageTitleLower = pageTitle.toLowerCase();
  const itemTitle = LoanCode(loan?.code);

  // const { _id } = loan;
  let cardClass = cardCategoryLoan(loan);

  const fns = {
    btn_MarkPaid: function btn_MarkPaid() {
      return ActionButton(buttonStyle, setCurrentLoan, "Mark Paid", loan);
    },
    btn_Edit: function btn_Edit() {
      return ActionButton(buttonStyle, setCurrentLoan, "Edit", loan);
    },
    btn_Delete: function btn_Delete() {
      return modalButton(
        "deleteModal",
        () => clickedDelete(loan._id),
        buttonStyle,
        "Delete"
      );
    },
  };

  const clickedDelete = (loanId) => {
    setDeleteLoan(loanId);
  };

  const buttonColour = isTable ? "btn-primary" : "btn-outline-" + cardClass[2];
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const displayFields = (cardFields, leftright, fields, record) =>
    cardFields[leftright].map((name) => {
      let i = findIndexInObj(fields, "field", name);

      return (
        <div key={"card-field-" + name + "-" + record._id}>
          {name.substring(0, 4) === "btn_"
            ? fns[name]()
            : ShowValueOrName(name, fields[i].type, record[fields[i].field])}
          <br />
        </div>
      );
    });

  const displayCardContent = (cardDetail, fields, record) =>
    ["left", "right"].map((leftright) => (
      <div
        className="col"
        key={"card-fields-col-" + leftright + "-" + record._id}
      >
        <div
          className={leftright === "right" ? "float-end" : "float-start"}
          key={"card-fields-" + leftright + "-" + record._id}
        >
          {displayFields(cardDetail, leftright, fields, record)}
        </div>
      </div>
    ));

  const displayFooterFields = (record) =>
    displayCardContent(cardFooter, fields, record);

  const cardBody = {
    left: ["startDate", "periodsCompleted", "balanceAmount", "totalAmount"],
    right: ["endDate", "periodsTotal", "installmentAmount", "active"],
  };
  const cardFooter = {
    left: ["nextInstallmentDate"],
    right: ["installmentAmount"],
  };

  const showCollapseHeader = (record) =>
    collapseButton(pageTitleLower + "-" + record?._id, itemTitle, cardClass[1]);

  const displayEditDeleteButtons = () => (
    <Fragment>
      {fns["btn_Edit"]()}
      {fns["btn_Delete"]()}
    </Fragment>
  );

  // const onDelete = () => deleteLoan(_id);

  const ShowMobileCode = (record) => {
    let k = "card-" + record._id;
    let actionStyle = isHide(hideCols, "Actions") ? { display: "none" } : {};

    return isTable ? (
      <Fragment>
        <td key={"cell-btns-body-row2" + record?._id} style={actionStyle}>
          {displayEditDeleteButtons()}
        </td>
        {record &&
          fields &&
          fields.map((fld) => {
            let fieldName = fld.field;
            let k = "cell-" + fieldName + "-" + record._id;
            let value = record?.[fieldName] ? record[fieldName] : "";

            if (isHide(hideCols, fld.title))
              return <td key={k} style={{ display: "none" }}></td>;

            if (fieldName === "account") value = value?.code ? value.code : "";
            if (isObject(value)) value = value[value.key(0)];
            if (isArray(value)) value = value[0];

            let align = getAlign(fld, "flex");

            return (
              <td className={"small text-" + align} key={k}>
                {formatValue(fld.type, value)}
              </td>
            );
          })}
      </Fragment>
    ) : (
      <div className={`card bg-${cardClass[0]} ${cardClass[1]}`} key={k}>
        <div className="card-header" key={k + "-header"}>
          {showCollapseHeader(record)}
        </div>
        <div
          className="collapse card-body"
          key={"card-body-" + record._id}
          id={pageTitleLower + "-" + record._id}
          style={{ padding: "5px" }}
        >
          <div className="row" key={"card-body-row1" + record._id}>
            {displayCardContent(cardBody, fields, record)}
          </div>
          <div className="row" key={"card-body-row2" + record._id}>
            <div
              className="d-flex justify-content-center"
              key={"card-body-buttons-" + record._id}
            >
              {displayEditDeleteButtons()}
            </div>
          </div>
        </div>

        <div className="card-footer" key={"card-footer-" + record._id}>
          <div className="row w-100 g-0" key={"card-footer-row1" + record._id}>
            {displayFooterFields(record)}
          </div>
        </div>
      </div>
    );
  };

  return <Fragment>{ShowMobileCode(loan)}</Fragment>;
};

LoanItem.propTypes = {
  loan: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  isTable: PropTypes.bool,
  hideCols: PropTypes.array,
};
export default LoanItem;
