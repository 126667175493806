import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  // useState,
} from "react";
import Spinner from "../layout/Spinner";

import { CSVLink } from "react-csv";
import { DateTime } from "luxon";
import {
  // getRecordCount,
  getRecords,
} from "../../utils/functionsCommon";
import AccountItem from "./AccountItem";
import AccountFormAddEdit from "./AccountFormAddEdit";
import AccountContext from "../../context/account/accountContext";
import PropTypes from "prop-types";
// import FilterSortBar from "../common/FilterSortBar";

let debug = 0;
const db = debug >= 1 ? true : false;
const lm = "com.acc.acclist.";

const AccountList = ({ fields, isMobile, isTable }) => {
  const accountContext = useContext(AccountContext);
  const {
    accounts,
    current,
    getAccounts,
    loading,
    // filterAccounts,
    filtered,
    filterOn,
    clearFilter,
  } = accountContext;

  const csvlink = useRef("");

  useEffect(() => {
    if (db) console.log(`${lm}:useeffect:start`);
    getAccounts({}, "");
    if (db) console.log(`${lm}:useeffect:finish`);

    // eslint-disable-next-line
  }, [filtered]);

  const showAccountTag = (showAddEdit, add, isTable, isMobile, key, record) => {
    return showAddEdit ? (
      <AccountFormAddEdit
        add={add}
        key={key}
        fields={fields}
        isMobile={isMobile}
        // isTable={showTable}
        isTable={isTable}
      />
    ) : (
      <AccountItem
        key={key}
        account={record}
        fields={fields}
        isMobile={isMobile}
        // isTable={showTable}
        isTable={isTable}
      />
    );
  };

  const showAccountRow = (record) => {
    let showAddEdit = current !== null && current._id === record._id;

    return showAccountTag(
      showAddEdit,
      false,
      isTable,
      isMobile,
      record._id,
      record
    );
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Code", key: "code" },
    { label: "Type", key: "type" },
    // { label: 'Type', key: 'type' },
    { label: "Bank", key: "bank" },
    { label: "Country", key: "country" },
    { label: "Country Code", key: "country_code" },
    { label: "Active", key: "active" },
  ];

  const ShowCsvLink = () => {
    const dateTime = DateTime.local();
    // we use a date string to generate our filename.
    const dateStr = dateTime.toFormat("ccc-LL-LLL-HH:mm");
    let data = !accounts
      ? []
      : accounts.map((row) => ({
          ...row,
        }));

    return accounts !== null && !loading ? (
      <CSVLink
        ref={csvlink}
        name="csvlink"
        filename={`account-extract-${dateStr}.csv`}
        data={data}
        headers={headers}
      >
        <button className="btn btn-light">Download Account List</button>
      </CSVLink>
    ) : (
      <span></span>
    );
  };

  const showAccounts = (accounts) =>
    accounts.map((account) =>
      !isTable ? (
        <div className="col" key={"accountList-col-" + account._id}>
          {showAccountRow(account)}
        </div>
      ) : (
        showAccountRow(account)
      )
    );

  const showEmptyRecords = () =>
    !isTable ? (
      <Fragment>
        Add an account or change /&nbsp;
        <button
          className="btn btn-link align-baseline"
          role="link"
          onClick={() => clearFilter()}
        >
          <u>reset</u>
        </button>
        &nbsp;the filter
      </Fragment>
    ) : (
      <tr>
        <td colSpan={10}>
          Add an account or change /&nbsp;
          <button
            className="btn btn-link align-baseline"
            role="link"
            onClick={() => clearFilter()}
          >
            <u>reset</u>
          </button>
          &nbsp;the filter
        </td>
      </tr>
    );

  const showContent = (records, isMobile) => {
    return (
      <Fragment>
        <div>
          <div className="card bg-primary mb-2">
            <div className="card-body">{ShowCsvLink()}</div>
          </div>
        </div>
        {!isTable ? (
          <Fragment>
            <div
              className="row row-cols-1 row-cols-md-3 g-2"
              key={"accList-cardrow"}
            >
              <div className={"col"} key={"accList-col-add"}>
                {showAccountTag(true, true, isTable, isMobile, "add-account")}
              </div>
              {records === null ? showEmptyRecords() : showAccounts(records)}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Actions</th>
                  {fields.map((field, idx) => (
                    <th key={"tbl-hdr-cell-" + field.title + "-" + idx}>
                      {field.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {showAccountTag(true, true, isTable, isMobile, "add-account")}
                {records === null ? showEmptyRecords() : showAccounts(records)}
              </tbody>
            </table>
          </Fragment>
        )}
      </Fragment>
    );
  };

  if (loading) return <Spinner />;
  const records = getRecords(accounts, filtered, filterOn);
  return showContent(records, isMobile);
};

AccountList.propTypes = {
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
};

export default AccountList;
