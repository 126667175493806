import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import { DateTime } from "luxon";

import AlertContext from "../../context/alert/alertContext";
import AccountContext from "../../context/account/accountContext";
import PeriodContext from "../../context/period/periodContext";
import BudgetContext from "../../context/budget/budgetContext";
import BalanceContext from "../../context/balance/balanceContext";

import PeriodSelect from "../periods/PeriodSelect";
import MissingPeriods from "../periods/MissingPeriods";
import ShowAllReports from "../reports/ShowAllReports";
// import BalanceImport from "./BalanceImport";
import BalanceList from "./BalanceList";
import DeleteModal from "../common/DeleteModal";

import {
  BalanceFields,
  BalanceDownloadHeaders,
} from "../../utils/fieldDefBalance";
import {
  formatDLDate,
  getRecordCount,
  getRecords,
} from "../../utils/functionsCommon";
import { modalDeleteHandler } from "../../utils/functionsForms";
import FilterSortBar from "../common/FilterSortBar";

let debug = 0;
const db = debug >= 1 ? true : false;

const Balances = () => {
  const periodContext = useContext(PeriodContext);
  const { getMaxPeriod, currentPeriod, setCurrentPeriod } = periodContext;

  const balanceContext = useContext(BalanceContext);
  const {
    balances,
    getBalances,
    deleteId,
    deleteBalance,
    loading,
    filtered,
    filterOn,
    getDayDueTotals,
  } = balanceContext;

  const budgetContext = useContext(BudgetContext);
  const { budgets, activeBudget, getBudgets, getActiveBudget } = budgetContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const accountContext = useContext(AccountContext);
  const { getAccounts } = accountContext;
  const { accounts } = accountContext;

  const [showTable, setShowTable] = useState(false);

  const loadit = async () => {
    if (currentPeriod === null) {
      setCurrentPeriod(await getMaxPeriod());
    }
  };

  const showError = (context) => {
    if (
      context.error !== null &&
      context.error !== "" &&
      context.error.length > 0
    ) {
      setAlert(context.error, "warning");
      context.clearErrors();
    }
  };

  useEffect(() => {
    showError(periodContext);
    showError(balanceContext);
    getAccounts();
    getBudgets();
    if (currentPeriod) getActiveBudget(currentPeriod);
    if (currentPeriod) getDayDueTotals("", currentPeriod);

    if (db)
      console.log(`com.balances.balances.currentPeriod: ${currentPeriod}`);
    loadit();

    // eslint-disable-next-line
  }, [currentPeriod]);

  const textFilterOptions = {
    title: "Balances",
    datasets: [accounts],
    filter: balanceContext.filterBalances,
    filtered: balanceContext.filtered,
    clearFilter: balanceContext.clearFilter,
    filters: [
      {
        dataset: accounts,
        fields: ["name", "bank", "code", "country_code", "country", "type"],
      },
    ],
  };

  const sortFilterOptions = [
    { sort: "date_due.asc", title: "Date Due - earliest first" },
    { sort: "date_due.desc", title: "Date Due - latest first" },
    { sort: "bank.asc", title: "Bank - A to Z" },
    { sort: "bank.desc", title: "Bank - Z to A" },
    { sort: "available.asc", title: "Available - small to large" },
    { sort: "available.desc", title: "Available - large to small" },
  ];

  const onSortChangeParent = (sortValue) => {
    getBalances({ period: currentPeriod }, sortValue);
  };

  let isMobile = true;
  const csvlink = useRef("");

  const ShowCsvLink = () => {
    const dateTime = DateTime.local();
    // we use a date string to generate our filename.
    const dateStr = dateTime.toFormat("ccc-LL-LLL-HH:mm");
    const records = getRecords(balances, filtered, filterOn);
    let data = !records
      ? []
      : records.map((row) => ({
          ...row,
          period: formatDLDate(row.period),
          date_due: formatDLDate(row.date_due),
        }));

    return balances !== null && !loading ? (
      <CSVLink
        ref={csvlink}
        name="csvlink"
        filename={`balances-extract-${dateStr}.csv`}
        data={data}
        headers={BalanceDownloadHeaders}
      >
        <button className="btn btn-primary">Download List of Balances</button>
      </CSVLink>
    ) : (
      <span></span>
    );
  };

  const ShowBudget = () => {
    if (!budgets || !activeBudget) return <div>No budget defined</div>;
    let k = "budget-";
    let dateStartStr = activeBudget?.dateStart
      ? DateTime.fromISO(activeBudget.dateStart).toFormat("dd-LLL-yy")
      : "No start";
    let dateEndStr = activeBudget?.dateEnd
      ? DateTime.fromISO(activeBudget.dateEnd).toFormat("dd-LLL-yy")
      : "No end";
    let total = 0;
    return (
      <Fragment>
        <span className="h5" key={k + "heading"}>
          {activeBudget?.name}
        </span>
        <div key={k + "dateRange"}>{dateStartStr + " - " + dateEndStr}</div>

        {activeBudget?.items ? (
          <table
            className="table table-sm table-striped w-50"
            key={"table" + k}
          >
            <thead key={"table-head" + k}>
              <tr key={"table-head-row" + k}>
                <th key={"table-head-cell1" + k}>Item</th>
                <th key={"table-head-cell2" + k}>Day</th>
                <th key={"table-head-cell3" + k}>Amount</th>
              </tr>
            </thead>
            <tbody key={"table-head-body" + k}>
              {activeBudget.items.map((item, idx) => {
                let k1 = k + item._id + idx;
                total += item?.expectedAmount || 0;
                return (
                  <tr key={"row-" + k1}>
                    <td key={"cell1-" + k1}>{item?.item}</td>
                    <td key={"cell2-" + k1}>{item?.dueDay}</td>
                    <td key={"cell3-" + k1}>
                      {item?.expectedAmount ? "$ " + item?.expectedAmount : " "}
                    </td>
                  </tr>
                );
              })}
              <tr key={"row-total" + k}>
                <td key={"cell1-total-" + k}>
                  <strong>Total</strong>
                </td>
                <td key={"cell2-total-" + k}> </td>
                <td key={"cell3-total-" + k}>
                  <strong>$ {total}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div>No budget items</div>
        )}
      </Fragment>
    );
  };

  const accordianSections = [
    { title: "Missing Periods", content: <MissingPeriods />, type: "Tag" },
    {
      title: "Reports",
      content: <ShowAllReports isMobile={isMobile} />,
      type: "Tag",
    },
    // { title: "Balance Import", content: <BalanceImport />, type: "Tag" },
    { title: "Download Balances", content: <ShowCsvLink />, type: "Tag" },
    { title: "Budget", content: <ShowBudget />, type: "Tag" },
  ];

  const ShowMobileCode = () => (
    <div id="accordion">
      <PeriodSelect />
      {accordianSections.map((section, index) => (
        <div className="card" key={`card-${index}`}>
          <div className="card-header" key={`card-header-${index}`}>
            <a
              className="collapsed card-link"
              key={`card-header-${index}-link`}
              data-bs-toggle="collapse"
              href={`#collapse${index}`}
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {section.title}
            </a>
          </div>
          <div
            id={`collapse${index}`}
            className="collapse"
            // data-parent="#accordion"
            key={`card-accordian-${index}`}
          >
            <div className="card-body" key={`card-body-${index}`}>
              {section.type === "Function"
                ? section.content()
                : section.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const ShowNonMobileCode = () => (
    <div>
      <div className="divTable blueTable">
        <div className="divTableHeading">
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Select Period</h4>
              <PeriodSelect />
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Missing Periods</h4>
              <MissingPeriods />
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Missing Periods</h4>
              <ShowAllReports isMobile={isMobile} />
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Download Balances</h4>
              {balances !== null && !loading ? ShowCsvLink() : <span></span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let recordCount = getRecordCount(balances, filtered, filterOn);
  return (
    <Fragment>
      <FilterSortBar
        title="Balances"
        recordCount={recordCount}
        textFilterOptions={textFilterOptions}
        sortFilterOptions={sortFilterOptions}
        filterOn={balanceContext.filterOn}
        clearFilter={balanceContext.clearFilter}
        onSortChangeParent={onSortChangeParent}
        setShowTable={setShowTable}
        isTable={showTable}
        loading={loading}
      />
      {isMobile ? ShowMobileCode() : ShowNonMobileCode()}
      <div className="mt-1">
        <BalanceList
          fields={BalanceFields}
          isMobile={isMobile}
          isTable={showTable}
          onSortChangeParent={onSortChangeParent}
        />
      </div>
      <DeleteModal
        deleteHandler={() => modalDeleteHandler(deleteBalance, deleteId)}
      />
    </Fragment>
  );
};

export default Balances;
