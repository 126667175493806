import React, { useReducer } from "react";
import axios from "axios";
import PeriodContext from "./periodContext";
import periodReducer from "./periodReducer";

import {
  CREATE_PERIOD,
  GET_MAXPERIOD,
  GET_ALLPERIODS,
  SET_CURRENTPERIOD,
  CLEAR_CURRENTPERIOD,
  GET_MISSINGPERIODS,
  PERIOD_ERROR,
  CLEAR_ERRORS,
} from "../types";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "state.period";

const PeriodState = (props) => {
  const initialState = {
    current: null,
    filtered: null,
    error: null,
    allPeriods: [],
    currentPeriod: null,
    maxPeriod: null,
    missingPeriods: null,
  };

  const [state, dispatch] = useReducer(periodReducer, initialState);

  // Get Max Period
  const getMaxPeriod = async () => {
    try {
      const t = await axios.get("/api/periods/max/").then((d) => {
        if (db)
          console.log(
            `[BalanceState.js:getMaxPeriod] maxPeriod: ${d.data.period}`
          );

        dispatch({
          type: GET_MAXPERIOD,
          payload: d.data.period,
        });

        return d.data.period;
      });
      return t;
    } catch (err) {
      dispatch({
        type: PERIOD_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Missing Periods
  const getMissingPeriods = async () => {
    try {
      await axios.get("/api/periods/missing").then((response) => {
        dispatch({
          type: GET_MISSINGPERIODS,
          payload: response.data,
        });
      });
    } catch (err) {
      dispatch({
        type: PERIOD_ERROR,
        payload: err.message,
      });
    }
  };

  // Get All Periods for dropdown
  const getAllPeriods = async () => {
    let lm = dp + ".getAllPeriods";
    if (db) console.log(`${lm}:start`);
    await axios
      .get("/api/periods/all")
      .then((res) => {
        if (db) console.log(`${lm}:res`);
        if (db) console.log(res);

        dispatch({
          type: GET_ALLPERIODS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PERIOD_ERROR,
          payload: err.message,
        });
      });
  };

  // Create a  Periods for dropdown
  const createPeriod = async (newPeriod) => {
    try {
      const res = await axios.get(`/api/periods/create/${newPeriod}`);

      dispatch({
        type: CREATE_PERIOD,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PERIOD_ERROR,
        payload: err.message,
      });
    }
  };

  const setCurrentPeriod = (period) => {
    dispatch({ type: SET_CURRENTPERIOD, payload: period });
  };

  const clearCurrentPeriod = () => {
    dispatch({ type: CLEAR_CURRENTPERIOD });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <PeriodContext.Provider
      value={{
        current: state.current,
        maxPeriod: state.maxPeriod,
        missingPeriods: state.missingPeriods,
        currentPeriod: state.currentPeriod,
        allPeriods: state.allPeriods,
        error: state.error,
        createPeriod,
        getAllPeriods,
        getMaxPeriod,
        getMissingPeriods,
        setCurrentPeriod,
        clearCurrentPeriod,
        clearErrors,
      }}
    >
      {props.children}
    </PeriodContext.Provider>
  );
};

PeriodState.propTypes = {
  children: PropTypes.object,
};

export default PeriodState;
