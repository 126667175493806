import React, { useState } from "react";

const StatusFilter = ({ options }) => {
  const [statusSelected, setStatusSelected] = useState("");
  // let reportItemType = "button";

  const ShowReportData = (key) => {
    let status = statusSelected === key ? "success" : "primary";
    return (
      <button
        className={"btn w-25 btn-" + status}
        key={"button-" + key}
        onClick={() => onClick(key)}
      >
        {key}
      </button>
    );
  };

  const onClick = (key) => {
    options.clearFilter();
    if (key === statusSelected) {
      setStatusSelected("");
    } else {
      setStatusSelected(key);
      if (key !== null) {
        options.filter({
          action: "filter",
          type: "textCompare",
          fields: options.filterFields,
          values: [key === "blank" ? options.blankValue : key],
          valueCount: 1,
        });
      }
    }
  };

  const ShowAdhocReport = () => {
    return (
      <div className="w-100">
        <div className="btn-group w-100">
          {options?.statusList !== undefined
            ? options.statusList.map((reportHeading) =>
                ShowReportData(reportHeading)
              )
            : ""}
        </div>
      </div>
    );
  };

  return ShowAdhocReport();
};

export default StatusFilter;
