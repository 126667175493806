import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import BalanceContext from "../../context/balance/balanceContext";
import {
  BalanceCode,
  cardCategoryBalance,
} from "../../utils/functionsBalances";

import {
  dateFormat,
  findIndexInObj,
  confirmVal,
  // formatDate,
  // formatFloat,
  ShowValueOrName,
  formatValue,
  getAlign,
} from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseButton,
  modalButton,
} from "../../utils/functionsForms";
import { isHide } from "../../utils/functionsTables";

const BalanceItem = ({ balance, fields, isTable, hideCols }) => {
  const balanceContext = useContext(BalanceContext);
  const { setCurrentBalance, setDeleteBalance } = balanceContext;

  const daysForWarning = 4;
  const pageTitle = "Balance";
  const pageTitleLower = pageTitle.toLowerCase();
  const itemTitle = BalanceCode(balance.account);

  let cardClass = cardCategoryBalance(balance, daysForWarning);
  const buttonColour = isTable ? "btn-primary" : "btn-outline-" + cardClass[2];
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const fns = {
    btn_MarkPaid: function btn_MarkPaid() {
      return ActionButton(buttonStyle, setCurrentBalance, "Mark Paid", balance);
    },
    btn_Edit: function btn_Edit() {
      return ActionButton(buttonStyle, setCurrentBalance, "Edit", balance);
    },
    btn_Delete: function btn_Delete() {
      return modalButton(
        "deleteModal",
        () => clickedDelete(balance._id),
        buttonStyle,
        "Delete"
      );
    },
  };

  const clickedDelete = (balanceId) => {
    setDeleteBalance(balanceId);
  };

  const displayFields = (cardFields, leftright, fields, record) =>
    cardFields[leftright].map((name) => {
      let i = findIndexInObj(fields, "field", name);

      return (
        <div key={"card-field-" + name + "-" + record._id}>
          {name.substring(0, 4) === "btn_"
            ? fns[name]()
            : ShowValueOrName(name, fields[i].type, record[fields[i].field])}
          <br />
        </div>
      );
    });

  const displayCardContent = (cardDetail, fields, record) =>
    ["left", "right"].map((leftright) => (
      <div
        className="col"
        key={"card-fields-col-" + leftright + "-" + record._id}
      >
        <div
          className={leftright === "right" ? "float-end" : "float-start"}
          key={"card-fields-" + leftright + "-" + record._id}
        >
          {displayFields(cardDetail, leftright, fields, record)}
        </div>
      </div>
    ));

  const cardBody = {
    left: ["outstanding", "due", "minimum"],
    right: ["status", "available", "rewards"],
  };
  const cardFooter = {
    left: ["status"],
    right: ["date_paid"],
  };
  const cardFooter2 = {
    left: ["status"],
    right: ["minimum"],
  };

  const showCollapseHeader = (record) =>
    collapseButton(pageTitleLower + "-" + record._id, itemTitle, cardClass[1]);

  const showMarkPaidButton = (record) =>
    record?.date_due ? dateFormat(record.date_due) : fns["btn_MarkPaid"]();

  const displayEditDeleteButtons = () => (
    <Fragment>
      {fns["btn_Edit"]()}
      {fns["btn_Delete"]()}
    </Fragment>
  );

  const displayFooterFields = (record) =>
    confirmVal(record.date_paid) !== null
      ? displayCardContent(cardFooter, fields, record)
      : displayCardContent(cardFooter2, fields, record);

  const ShowMobileCode = (record) => {
    let k = "card-" + record._id;
    let actionStyle = isHide(hideCols, "Actions") ? { display: "none" } : {};

    return isTable ? (
      <Fragment>
        <td key={"cell-btns-body-row2" + record?._id} style={actionStyle}>
          {displayEditDeleteButtons()}
        </td>
        {record &&
          fields &&
          fields.map((fld) => {
            let fieldName = fld.field;
            let value = record[fieldName] ? record[fieldName] : "";
            let k = "cell-" + fieldName + "-" + record._id;

            if (isHide(hideCols, fld.title))
              return <td key={k} style={{ display: "none" }}></td>;

            if (fieldName === "account") value = value?.code ? value.code : "";
            let align = getAlign(fld, "flex");

            return (
              <td className={"small text-" + align} key={k}>
                {formatValue(fld.type, value)}
              </td>
            );
          })}
      </Fragment>
    ) : (
      <div className={`card bg-${cardClass[0]} ${cardClass[1]}`} key={k}>
        <div className="card-header" key={k + "-header"}>
          <div className="row w-100 g-0" key={k + "-header-r1"}>
            <div className="col-8" key={k + "-header-r1c1"}>
              <div className="float-start" key={k + "-header-r1c1-d1"}>
                {showCollapseHeader(record)}
              </div>
            </div>
            <div className="col-4" key={k + "-header-r1c2"}>
              <div className="float-end" key={k + "-header-r1c2-d1"}>
                {showMarkPaidButton(record)}
              </div>
            </div>
          </div>
        </div>
        <div
          className="collapse card-body"
          key={"card-body-" + record._id}
          id={pageTitleLower + "-" + record._id}
          style={{ padding: "5px" }}
        >
          <div className="row" key={"card-body-row1" + record._id}>
            {displayCardContent(cardBody, fields, record)}
          </div>
          <div className="row" key={"card-body-row2" + record._id}>
            <div
              className="d-flex justify-content-center"
              key={"card-body-buttons-" + record._id}
            >
              {displayEditDeleteButtons()}
            </div>
          </div>
        </div>

        <div className="card-footer" key={"card-footer-" + record._id}>
          <div className="row w-100 g-0" key={"card-footer-row1" + record._id}>
            {displayFooterFields(record)}
          </div>
        </div>
      </div>
    );
  };

  return <Fragment>{ShowMobileCode(balance)}</Fragment>;
};

BalanceItem.propTypes = {
  balance: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  hideCols: PropTypes.array,
  // isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
};

export default BalanceItem;
