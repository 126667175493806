import React, { useState, useContext, useEffect, Fragment } from "react";
import PropTypes from "prop-types";

import LoanContext from "../../context/loan/loanContext";
import AccountContext from "../../context/account/accountContext";

import { isArray, findInObj, returnISODate } from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseMultiButton,
  showFields,
} from "../../utils/functionsForms";
import { cardCategoryLoan, LoanCode } from "../../utils/functionsBalances";
import { isHide } from "../../utils/functionsTables";

const LoanFormAddEdit = ({ add, fields, isMobile, isTable, hideCols }) => {
  const loanContext = useContext(LoanContext);
  const { current, addLoan, updateLoan, clearCurrentLoan } = loanContext;

  const accountContext = useContext(AccountContext);
  const { accounts } = accountContext;

  const pageTitle = "Balance";

  let default_loan_set = {};
  for (let field of fields) {
    default_loan_set[field.field] = "";
  }
  const [loan, setLoan] = useState(default_loan_set);

  useEffect(() => {
    if (add) setLoan(default_loan_set);
    if (!add) setLoan(current);
    // eslint-disable-next-line
  }, [loanContext, current]);

  const onChange = (e, a) => {
    let name, value;

    if (isArray(e) && e.length === 2) {
      name = e[0];
      value = e[1];
    } else if (a && a.action) {
      name = "account";
      value = findInObj(accounts, "_id", e.value);
    } else {
      name = e.target.name;

      let field = findInObj(fields, "field", name);
      value =
        field.type === "date"
          ? e.target.value === ""
            ? null
            : returnISODate(e.target.value)
          : e.target.value;
    }

    setLoan({
      ...loan,
      [name]: value,
    });
  };

  const onDateClear = (e) => {
    let name = e.target.name;
    let value = null;

    setLoan({
      ...loan,
      [name]: value,
    });
  };

  const onSaveAdd = (loan) => {
    if (add) addLoan(loan);
    if (!add) updateLoan(loan);
    clearForm();
  };

  const clearForm = () => {
    if (add) setLoan(default_loan_set);
    if (!add) clearCurrentLoan();
  };

  const cardClass = add
    ? ["primary", "text-white", "light"]
    : cardCategoryLoan(current);

  const arrays = { accounts: accounts };
  const buttonColour = isTable ? "btn-secondary" : "btn-light";
  const buttonStyle = "btn " + buttonColour + " btn-sm";
  const buttonAlign = isTable ? "justify-content" : "justify-content-center";

  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(buttonStyle, onSaveAdd, "Save", loan);
    },

    btn_Add: function btn_Add() {
      return ActionButton(buttonStyle, onSaveAdd, "Add", loan);
    },

    btn_Cancel: function btn_Delete() {
      return ActionButton(buttonStyle, clearCurrentLoan, "Cancel", "");
    },
  };

  const fieldArgs = {
    arrays: arrays,
    fields: fields,
    record: loan,
    onChange: onChange,
    onDateClear: onDateClear,
    isMobile: isMobile,
    add: add,
  };

  const showTitle = (k, title, record) =>
    add
      ? collapseMultiButton(
          ".multi-collapse",
          k + "-multiBalance1 multiBalance2",
          "Add " + title,
          buttonStyle,
          false,
          null
        )
      : `Edit ${title} ${LoanCode(record.code)}`;

  const showFormActionButtons = () => (
    <div className={"d-flex mt-1 " + buttonAlign}>
      {add ? fns["btn_Add"]() : fns["btn_Save"]()}
      {fns["btn_Cancel"]()}
    </div>
  );

  const showMobileCode = (record) => {
    let className = add
      ? "bg-primary"
      : `card bg-${cardClass[0]} ${cardClass[1]}`;
    let k = add ? "add" : "edit-" + record._id;
    let style = { isTable: isTable };
    let actStyle = isHide(hideCols, "Actions") ? { display: "none" } : {};

    return style?.isTable ? (
      <Fragment>
        <td className={"align-start"} key={"cell-btns2" + k} style={actStyle}>
          {showFormActionButtons()}
        </td>
        {showFields(fieldArgs, { ...style, hideCols })}
      </Fragment>
    ) : (
      <Fragment>
        <div className={`card ${className}`} key={"card-1"}>
          <div className="card-header">{showTitle(k, pageTitle, record)}</div>
          <div
            className={(add ? "collapse " : "") + " multi-collapse"}
            id={k + "-multiBalance1"}
          >
            <div className="card-body">
              <form id={"form-" + k}>
                {showFields(fieldArgs, { isTable: false })}
              </form>
            </div>
            {add ? showFormActionButtons() : ""}
          </div>
          <div className="card-footer">
            {!add ? showFormActionButtons() : ""}
          </div>
        </div>
      </Fragment>
    );
  };

  return showMobileCode(current);
};

LoanFormAddEdit.propTypes = {
  add: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
  hideCols: PropTypes.array,
};

export default LoanFormAddEdit;
