import React, { Fragment, useState } from "react";
import { currencyFormat, findIndexInObj } from "../../utils/functionsCommon";

const TotalsDisplay = ({ options }) => {
  const [calculationField, setCalculationField] = useState(
    options.defaultCalcField
  );
  let reportItemType = "button";

  const cardStyles =
    reportItemType.toLowerCase() === "button"
      ? ["btn-group", "button", "btn"]
      : ["card-group", "card", "card-body"];

  const ShowReportButtons = (field) => (
    <button
      className={
        "btn w-25 btn-" + (field === calculationField ? "success" : "primary")
      }
      key={"reportButtons-" + field}
      onClick={() => setCalculationField(field)}
    >
      {field}
    </button>
  );

  const ShowReportData = (hasData, field, data) => {
    // console.log(hasData);
    // console.log(field);
    // console.log(data);
    if (hasData && !data) {
      console.log("report data not provided:");
      console.log(data);
      return "";
    }

    let key = field;
    let value = hasData ? data?.["total" + calculationField] : 0;

    let styls =
      key && options?.reportStyles?.[key]
        ? options.reportStyles[key]
        : ["primary", "text-white"];
    return (
      <button
        className={cardStyles[2] + " w-25 text-center bg-" + styls.join(" ")}
        key={cardStyles[2] + key}
        onClick={() => filterList(key)}
      >
        {key}
        <br />
        {currencyFormat(value)}
      </button>
    );
  };

  const filterList = (key) => {
    if (key !== null) {
      options.filter({
        action: "filter",
        type: options.filterType,
        fields: options.filterFields,
        values: [key],
        valueCount: 1,
      });
    } else {
      options.clearFilter();
    }
  };

  const ShowAdhocReport = () => {
    return (
      <Fragment>
        <div className="w-100">
          <div className="btn-group w-100">
            {options?.calcFields?.length
              ? options.calcFields.map((calcField) =>
                  ShowReportButtons(calcField)
                )
              : ""}
          </div>
        </div>
        <div className="w-100">
          <div className="btn-group w-100">
            {options.totals?.length && options?.titleFields?.length
              ? options.titleFields.map((field) => {
                  let pos = findIndexInObj(options.totals, "_id", field);
                  // console.log("--------------");
                  // console.log(options.totals);
                  // console.log(field);
                  // console.log(pos);
                  // console.log(options.totals[pos]);
                  return pos > -1
                    ? ShowReportData(true, field, options.totals[pos])
                    : ShowReportData(false, field, {});
                })
              : ""}
            {/* {options.totals?.length && options?.titleFields?.length
              ? options.titleFields.map((field) => {
                  console.log(field);
                  console.log(options.totals);
                  return options.totals.map((totals) =>
                    field.toLowerCase() === totals._id.toLowerCase()
                      ? ShowReportData(totals)
                      : ""
                  );
                })
              : ""} */}
          </div>
        </div>
      </Fragment>
    );
  };

  return ShowAdhocReport();
};

export default TotalsDisplay;
