import React, { useReducer } from "react";
import axios from "axios";
import LoanContext from "./loanContext";
import loanReducer from "./loanReducer";
import {
  GET_LOANS,
  GET_LOAN_TOTALS,
  ADD_LOAN,
  DELETE_LOAN,
  UPDATE_LOAN,
  SET_CURRENT_LOAN,
  CLEAR_CURRENT_LOAN,
  LOAN_ERROR,
  GET_MONTHLY,
  GET_REPORTS,
  FILTER_LOAN,
  CLEAR_FILTER_LOAN,
  CLEAR_ERRORS,
  SET_DELETE_LOAN,
  CLEAR_DELETE_LOAN,
} from "../typesLoans";
import { cleanFilters } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "com.loanstate";

const LoanState = (props) => {
  const initialState = {
    loans: null,
    current: null,
    filtered: null,
    filterOn: false,
    filters: false,
    deleteId: null,
    error: [],
    loanTotals: null,
    monthly: null,
    report_data: [],
    report_adhoc_data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(loanReducer, initialState);

  // Get Loans
  const getLoans = async (filters, sort) => {
    let lm = dp + ".getLoans";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) cleanFilters(filters);

      // rollover the loans
      let res = [];
      res = await axios.post(`/api/loans/rolloverLoans`, {});

      // get the current loans
      res = await axios.get(`/api/loans/`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_LOANS,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getLoans:end`);
    } catch (err) {
      dispatch({
        type: LOAN_ERROR,
        payload: err.message,
      });
    }
  };

  // Filter Contacts
  const filterLoans = (array) => {
    dispatch({
      type: FILTER_LOAN,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_LOAN });
  };

  // Get Monthly Loan Installments
  const getMonthly = async (filters) => {
    let lm = dp + ".getMonthly";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }

      let res = [];
      res = await axios.get(`/api/loans/monthly`, { params: filters });

      dispatch({
        type: GET_MONTHLY,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getLoans:end`);
    } catch (err) {
      dispatch({
        type: LOAN_ERROR,
        payload: err.message,
      });
    }
  };

  // Get Monthly Loan Installments
  const getLoanTotals = async (filters) => {
    let lm = dp + ".getLoanTotals";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (db) console.log(`${lm}`);
      await axios.post("/api/loans/totals", {}, config).then((res) => {
        dispatch({
          type: GET_LOAN_TOTALS,
          payload: res.data,
        });
      });

      if (debug >= 1) console.log(`${lm}:end`);
    } catch (err) {
      dispatch({
        type: LOAN_ERROR,
        payload: err.message,
      });
    }
  };

  // Add Loan
  const addLoan = async (loan) => {
    let lm = dp + ".addLoan";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (db) console.log(`${lm}`);

    await axios
      .post("/api/loans", loan, config)
      .then((response) => {
        dispatch({
          type: ADD_LOAN,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOAN_ERROR,
          payload: err,
        });
      });
  };

  // Update Loan
  const updateLoan = async (loan) => {
    let lm = dp + ".updateLoan";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(`/api/loans/${loan._id}`, loan, config);

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_LOAN, payload: res.data });
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: LOAN_ERROR,
        payload: err,
      });
    }
  };

  const deleteLoan = async (id) => {
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/loans/${id}`);

      dispatch({ type: DELETE_LOAN, payload: id });
    } catch (err) {
      dispatch({
        type: LOAN_ERROR,
        payload: err,
      });
    }
  };

  const setCurrentLoan = (loan) => {
    dispatch({ type: SET_CURRENT_LOAN, payload: loan });
  };

  const clearCurrentLoan = () => {
    dispatch({ type: CLEAR_CURRENT_LOAN });
  };

  // Get Loans
  const getReports = async (report_name) => {
    let lm = dp + ".getReports";
    try {
      if (db) console.log(`${lm}:report_type: ${report_name}`);

      const params = new URLSearchParams([["name", report_name]]);

      if (db) console.log(`${lm}:params: ${params}`);

      // let res = [];
      let res = await axios.get(`/api/reports`, { params });
      // res should return {data: records, fields: field def}

      dispatch({
        type: GET_REPORTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LOAN_ERROR,
        payload: err,
      });
    }
  };

  // Get Adhoc Report
  const getAdhocReport = async (report_name) => {
    let lm = dp + ".getReports";
    // console.log('running');
    try {
      if (db) console.log(`${lm}:start`);

      const params = new URLSearchParams([["name", report_name]]);

      let res = await axios.get(`/api/reports/adhoc`, { params });
      // res should return {data: records, fields: field def}

      dispatch({
        type: GET_REPORTS,
        payload: res.data,
      });
      if (db) console.log(`${lm}:finish`);
    } catch (err) {
      dispatch({
        type: LOAN_ERROR,
        payload: err,
      });
    }
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteLoan = (loan) => {
    dispatch({ type: SET_DELETE_LOAN, payload: loan });
  };

  const clearDeleteLoan = () => {
    dispatch({ type: CLEAR_DELETE_LOAN });
  };

  return (
    <LoanContext.Provider
      value={{
        loans: state.loans,
        current: state.current,
        filtered: state.filtered,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        import_template: state.import_template,
        import_result: state.import_result,
        loanTotals: state.loanTotals,
        monthly: state.monthly,
        report_data: state.report_data,
        report_adhoc_data: state.report_adhoc_data,
        error: state.error,
        loading: state.loading,
        getAdhocReport,
        getLoans,
        getLoanTotals,
        getMonthly,
        addLoan,
        updateLoan,
        deleteLoan,
        setCurrentLoan,
        clearCurrentLoan,
        setDeleteLoan,
        clearDeleteLoan,
        getReports,
        filterLoans,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </LoanContext.Provider>
  );
};

LoanState.propTypes = {
  children: PropTypes.object,
};

export default LoanState;
