import React, { Fragment, useContext, useEffect } from "react";
import BudgetContext from "../../context/budget/budgetContext";
import BudgetList from "./BudgetList";
import { BudgetFields } from "../../utils/fieldDefBudget";

import AlertContext from "../../context/alert/alertContext";
import { modalDeleteHandler } from "../../utils/functionsForms";
import DeleteModal from "../common/DeleteModal";

const Budgets = () => {
  const budgetContext = useContext(BudgetContext);
  const {
    getBudgets,
    // getBudget, budgets, budget,
    deleteBudget,
    deleteId,
  } = budgetContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  // const accountContext = useContext(AccountContext);
  // const { getAccounts } = accountContext;

  const showError = (context) => {
    if (
      context.error !== null &&
      context.error !== "" &&
      context.error.length > 0
    ) {
      setAlert(context.error, "warning");
      context.clearErrors();
    }
  };

  useEffect(() => {
    showError(budgetContext);
    getBudgets();
    // getBudget();

    // eslint-disable-next-line
  }, []);

  let isMobile = true;

  const accordianSections = [];

  const ShowMobileCode = () => (
    <div id="accordion">
      {accordianSections.map((section, index) => (
        <div className="card" key={`card-${index}`}>
          <div className="card-header" key={`card-header-${index}`}>
            <a
              className="collapsed card-link"
              data-bs-toggle="collapse"
              href={`#collapse${index}`}
            >
              {section.title}
            </a>
          </div>
          <div
            id={`collapse${index}`}
            className="collapse"
            data-parent="#accordion"
            key={`card-accordian-${index}`}
          >
            <div className="card-body" key={`card-body-${index}`}>
              {section.type === "Function"
                ? section.content()
                : section.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const ShowNonMobileCode = () => (
    <div>
      <div className="divTable blueTable">
        <div className="divTableHeading">
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Download Budgets</h4>
              {/* {budgets !== null && !loading ? (
                <CSVLink
                  ref={csvlink}
                  name='csvlink'
                  data={budgetContext.budgets}
                  headers={headers}
                >
                  <font className='white_text'>Click to download</font>
                </CSVLink>
              ) : (
                <span></span>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      {isMobile ? ShowMobileCode() : ShowNonMobileCode()}
      <BudgetList fields={BudgetFields} isMobile={isMobile} />
      <DeleteModal
        deleteHandler={() => modalDeleteHandler(deleteBudget, deleteId)}
      />
    </Fragment>
  );
};

export default Budgets;
