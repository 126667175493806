import {
  GET_ACCOUNTS,
  IMPORT_ACCOUNT,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  SET_CURRENT_ACCOUNT,
  CLEAR_CURRENT_ACCOUNT,
  GET_IMPORT_TEMPLATE,
  CLEAR_IMPORT_RESULT,
  FILTER_ACCOUNT,
  CLEAR_FILTER_ACCOUNT,
  SET_DELETE_ACCOUNT,
  CLEAR_DELETE_ACCOUNT,
} from "../types";
import { checkIfMatches } from "../../utils/functionsFilters";

const fields = ["name", "code", "type", "bank", "country", "country_code"];

const accountReducer = (state, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };

    case ADD_ACCOUNT:
      return {
        ...state,
        accounts: [action.payload, ...state.accounts],
        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account._id === action.payload._id ? action.payload : account
        ),
        // as above state is being passed and state is immutable, need to make a copy
      };

    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter(
          (account) => account._id !== action.payload
        ),
      };

    case SET_CURRENT_ACCOUNT:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAR_CURRENT_ACCOUNT:
      return {
        ...state,
        current: null,
      };

    case SET_DELETE_ACCOUNT:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_ACCOUNT:
      return {
        ...state,
        deleteId: null,
      };

    case FILTER_ACCOUNT:
      return {
        ...state,
        filtered: state.accounts.filter((account) =>
          checkIfMatches(account, action, fields)
        ),
        filterOn: true,
      };

    case CLEAR_FILTER_ACCOUNT:
      return {
        ...state,
        filtered: null,
        filterOn: false,
      };

    case IMPORT_ACCOUNT:
      return {
        ...state,
        import_result: action.payload,
      };

    case CLEAR_IMPORT_RESULT:
      return {
        ...state,
        import_result: null,
      };

    case GET_IMPORT_TEMPLATE:
      return {
        ...state,
        account_template: action.payload,
        // ACCOUNTs: state.ACCOUNTs,
        // as above state is being passed and state is immutable, need to make a copy
      };

    default:
      return state;
  }
};

export default accountReducer;
