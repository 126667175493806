import React, { Fragment, useContext, useEffect, useState } from "react";
import LoanContext from "../../context/loan/loanContext";
import LoanList from "./LoanList";
import { LoanFields } from "../../utils/fieldDefLoan";

import AlertContext from "../../context/alert/alertContext";
import AccountContext from "../../context/account/accountContext";
import { modalDeleteHandler } from "../../utils/functionsForms";
import DeleteModal from "../common/DeleteModal";
import { getRecordCount } from "../../utils/functionsCommon";
import FilterSortBar from "../common/FilterSortBar";

// improvements
//    * cannot add multiple new loans, only one.  seems data is kept in state, need to clear

// let debug = 0;
// const db = debug >= 1 ? true : false;

const Loans = () => {
  const loanContext = useContext(LoanContext);
  const {
    loans,
    getLoans,
    getLoanTotals,
    filtered,
    filterOn,
    deleteLoan,
    deleteId,
    loading,
  } = loanContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const accountContext = useContext(AccountContext);
  const { accounts, getAccounts } = accountContext;

  const [showTable, setShowTable] = useState(false);

  const showError = (context) => {
    if (
      context.error !== null &&
      context.error !== "" &&
      context.error.length > 0
    ) {
      setAlert(context.error, "warning");
      context.clearErrors();
    }
  };

  useEffect(() => {
    showError(loanContext);
    getAccounts();
    getLoanTotals();

    // eslint-disable-next-line
  }, []);

  const textFilterOptions = {
    title: "Filter Loans ...",
    datasets: [accounts, loans],
    filter: loanContext.filterLoans,
    filtered: loanContext.filtered,
    clearFilter: loanContext.clearFilter,
    filters: [
      {
        dataset: accounts,
        fields: ["name", "bank", "code", "country_code", "country", "type"],
      },
      {
        dataset: loans,
        fields: ["status", "code", "loanType", "active", "periodFrequency"],
      },
    ],
  };
  const sortFilterOptions = [
    { sort: "code.asc", title: "Code - A to Z" },
    { sort: "code.desc", title: "Code - Z to A" },
    { sort: "endDate.asc", title: "End Date - earliest to latest" },
    { sort: "endDate.desc", title: "End Date - latest to earliest" },
    { sort: "periodsCompleted.asc", title: "Periods Completed - 1 to n" },
    { sort: "periodsCompleted.desc", title: "Periods Completed - n to 1" },
  ];

  const onSortChangeParent = (sortValue) => {
    getLoans({}, sortValue);
  };

  let isMobile = true;

  const accordianSections = [];

  const ShowMobileCode = () => (
    <div id="accordion">
      {accordianSections.map((section, index) => (
        <div className="card" key={`card-${index}`}>
          <div className="card-header" key={`card-header-${index}`}>
            <a
              className="collapsed card-link"
              data-bs-toggle="collapse"
              href={`#collapse${index}`}
            >
              {section.title}
            </a>
          </div>
          <div
            id={`collapse${index}`}
            className="collapse"
            data-parent="#accordion"
            key={`card-accordian-${index}`}
          >
            <div className="card-body" key={`card-body-${index}`}>
              {section.type === "Function"
                ? section.content()
                : section.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const ShowNonMobileCode = () => (
    <div>
      <div className="divTable blueTable">
        <div className="divTableHeading">
          <div className="divTableRow">
            <div className="divTableCell divButtonBlock">
              <h4 className="white_text">Download Loans</h4>
              {/* {loans !== null && !loading ? (
                <CSVLink
                  ref={csvlink}
                  name='csvlink'
                  data={loanContext.loans}
                  headers={headers}
                >
                  <font className='white_text'>Click to download</font>
                </CSVLink>
              ) : (
                <span></span>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let recordCount = getRecordCount(loans, filtered, filterOn);
  return (
    <Fragment>
      <FilterSortBar
        title="Loans"
        recordCount={recordCount}
        textFilterOptions={textFilterOptions}
        sortFilterOptions={sortFilterOptions}
        filterOn={loanContext.filterOn}
        clearFilter={loanContext.clearFilter}
        onSortChangeParent={onSortChangeParent}
        setShowTable={setShowTable}
        showTable={showTable}
        loading={loading}
      />
      {isMobile ? ShowMobileCode() : ShowNonMobileCode()}
      <LoanList
        fields={LoanFields}
        isMobile={isMobile}
        isTable={showTable}
        onSortChangeParent={onSortChangeParent}
      />
      <DeleteModal
        deleteHandler={() => modalDeleteHandler(deleteLoan, deleteId)}
      />
    </Fragment>
  );
};

export default Loans;
