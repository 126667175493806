import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  // useState,
} from "react";
import LoanItem from "./LoanItem";
import LoanContext from "../../context/loan/loanContext";
// import AccountContext from "../../context/account/accountContext";

import LoanFormAddEdit from "./LoanFormAddEdit";
import StatusFilter from "../common/StatusFilter";
// import FilterSortBar from "../common/FilterSortBar";
import TotalsDisplay from "../common/TotalsDisplay";

import Spinner from "../layout/Spinner";
// import LoanTotalOutstanding from "./LoanTotalOutstanding";
import MonthlyInstallments from "../common/MonthlyInstallments";

import { CSVLink } from "react-csv";
import { LoanDownloadHeaders } from "../../utils/fieldDefLoan";

import { DateTime } from "luxon";
import {
  formatDLDate,
  // getRecordCount,
  getRecords,
} from "../../utils/functionsCommon";
import PropTypes from "prop-types";
import { showFieldHeaders, showHiddenCols } from "../../utils/functionsTables";

// LoanList

// improvements
//    * mark loan as green if paid
//    * add filter (by default) to not show paid loans

let debug = 0;
const db = debug >= 1 ? true : false;
const lm = "com.loans.loanlist.";

const LoansList = ({ fields, isMobile, isTable, onSortChangeParent }) => {
  const loanContext = useContext(LoanContext);
  const {
    loans,
    current,
    getLoans,
    loanTotals,
    loading,
    filtered,
    filterLoans,
    filterOn,
    clearFilter,
  } = loanContext;

  const csvlink = useRef("");
  useEffect(() => {
    if (db) console.log(`${lm}:useeffect:start`);
    getLoans({}, {});
    if (db) console.log(`${lm}:useeffect:finish`);

    // eslint-disable-next-line
  }, []);
  // }, [filtered]);

  const [hideCols, setHideCols] = useState([]);

  const [sort, setSort] = useState("sort");
  const onSortChange = (e) => {
    setSort(e.target.value);
    onSortChangeParent(e.target.value);
  };

  const statusFilterOptions = {
    dataset: loanContext.loans,
    filter: loanContext.filterLoans,
    clearFilter: loanContext.clearFilter,
    statusList: ["Open", "Completed"],
    filterFields: ["status"],
    blankValue: "not entered",
  };

  const totalsDisplayBarOptions = {
    totals: loanTotals,
    defaultCalcField: "BalanceAmount",
    filterFields: ["status"],
    filterType: "textCompare",
    reportStyles: {
      completed: ["success", "text-white"],
      open: ["warning", "text-white"],
    },
    titleFields: ["open", "completed"],
    calcFields: ["TotalAmount", "BalanceAmount"],
    filter: filterLoans,
    clearFilter: clearFilter,
  };

  const monthlyInstallOptions = {
    reportName: "Monthly Installments",
    getFnc: loanContext.getMonthly,
    data: loanContext.monthly,
    fields: [
      { title: "Month", field: "month", type: "date", dateFormat: "LLL-yy" },
      { title: "Installments", field: "count", type: "number" },
      { title: "Amount", field: "totalInstallment", type: "currency" },
    ],
    dlTitle: "loan-monthly",
    dlHeaders: [
      { label: "Month", key: "month" },
      { label: "Installments", key: "count" },
      { label: "Installment Amount", key: "totalInstallment" },
    ],
  };

  const ShowCsvLink = () => {
    const dateTime = DateTime.local();
    // we use a date string to generate our filename.
    const dateStr = dateTime.toFormat("ccc-LL-LLL-HH:mm");
    let data = !loans
      ? []
      : loans.map((row) => ({
          ...row,
          startDate: formatDLDate(row.startDate),
          endDate: formatDLDate(row.endDate),
          nextInstallmentDate: formatDLDate(row.nextInstallmentDate),
        }));

    return loans !== null && !loading ? (
      <CSVLink
        ref={csvlink}
        name="csvlink"
        filename={`loan-extract-${dateStr}.csv`}
        data={data}
        headers={LoanDownloadHeaders}
      >
        <button className="btn btn-sm btn-light">Download Loan List</button>
      </CSVLink>
    ) : (
      <span></span>
    );
  };

  const showLoans = (loans) =>
    loans &&
    loans.map((loan) =>
      isTable ? (
        <tr key={"loansList-col-" + loan._id}>{showLoanRow(loan)}</tr>
      ) : (
        <div className="col" key={"loansList-col-" + loan._id}>
          {showLoanRow(loan)}
        </div>
      )
    );

  const showLoanRow = (record) => {
    let showAddEdit = current !== null && current._id === record._id;

    return showAddEdit ? (
      <LoanFormAddEdit
        add={false}
        key={record._id}
        fields={fields}
        isMobile={isMobile}
        isTable={isTable}
        hideCols={hideCols}
      />
    ) : (
      <LoanItem
        key={record._id}
        loan={record}
        fields={fields}
        isMobile={isMobile}
        isTable={isTable}
        hideCols={hideCols}
      />
    );
  };

  const showEmptyRecords = () => (
    <div className="card">
      <div className="card-body text-center">
        Add a loan or change /&nbsp;
        <button
          className="btn btn-link align-baseline"
          role="link"
          onClick={() => clearFilter()}
        >
          <u>reset</u>
        </button>
        &nbsp;the filter
      </div>
    </div>
  );

  const hideColsSet = {
    v: hideCols,
    f: setHideCols,
  };

  const sortColsSet = {
    v: sort,
    f: onSortChange,
  };

  const showContent = (records, isMobile) => {
    return (
      <Fragment>
        <div>
          <div className="card mb-1">
            <TotalsDisplay options={totalsDisplayBarOptions} />
            <StatusFilter options={statusFilterOptions} />
          </div>
          {/* <div className="card mb-1">
            <LoanTotalOutstanding />
          </div> */}

          <MonthlyInstallments options={monthlyInstallOptions} />
          <div className="card bg-primary mb-2">
            <div className="card-body">{ShowCsvLink()}</div>
          </div>
        </div>
        {isTable ? (
          <Fragment>
            <div
              className="col cols-12"
              key={"loanList-Table"}
              style={{ overflowX: "auto" }}
            >
              <div className="row" key={"loanList-hiddenCols"}>
                <div className="col-12" key={"loanList-hiddenCols"}>
                  {showHiddenCols(hideColsSet)}
                </div>
              </div>
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    {showFieldHeaders(
                      [{ field: "Actions", title: "Actions" }],
                      hideColsSet
                    )}

                    {showFieldHeaders(fields, hideColsSet, sortColsSet)}
                  </tr>
                </thead>
                <tbody className="divTableBody">
                  <tr key={"loanList-col-add"}>
                    <LoanFormAddEdit
                      add={true}
                      fields={fields}
                      isMobile={isMobile}
                      isTable={isTable}
                      hideCols={hideCols}
                    />
                  </tr>
                  {showLoans(records)}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <div
            className="row row-cols-1 row-cols-md-3 g-2"
            key={"loansList-cardrow"}
          >
            <div className="col" key={"loansList-col-add"}>
              <LoanFormAddEdit add={true} fields={fields} isMobile={isMobile} />
            </div>
            {records === null ? showEmptyRecords() : showLoans(records)}
          </div>
        )}
      </Fragment>
    );
  };

  if (loading) return <Spinner />;
  const records = getRecords(loans, filtered, filterOn);
  return showContent(records, isMobile);
};

LoansList.propTypes = {
  fields: PropTypes.array.isRequired,
  isTable: PropTypes.bool,
  isMobile: PropTypes.bool,
  onSortChangeParent: PropTypes.func.isRequired,
};
export default LoansList;
