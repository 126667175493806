import { DateTime } from "luxon";
import React, { Fragment, useContext, useEffect } from "react";
import PeriodContext from "../../context/period/periodContext";
import { isArrayEmpty } from "../../utils/functionsCommon";

const PeriodSelect = () => {
  const periodContext = useContext(PeriodContext);
  const {
    allPeriods,
    getAllPeriods,
    currentPeriod,
    setCurrentPeriod,
    clearCurrentPeriod,
  } = periodContext;

  useEffect(() => {
    getAllPeriods();
    // eslint-disable-next-line
  }, []);

  const setPeriod = (e) => {
    if (Date.parse(e.target.value)) {
      setCurrentPeriod(e.target.value);
    } else {
      clearCurrentPeriod();
    }
  };

  return (
    <Fragment>
      <div className="form-group">
        <select
          name="select_period"
          className="form-control-lg"
          style={{ width: "100%" }}
          value={
            currentPeriod !== null && currentPeriod !== "empty"
              ? currentPeriod
              : "Please Select."
          }
          onChange={(e) => {
            setPeriod(e);
          }}
        >
          <option key="Please Select." value="Please Select.">
            Please select.
          </option>
          {!isArrayEmpty(allPeriods) ? (
            allPeriods.map((p) => (
              <option key={p} value={p} className="small">
                {/* {new Intl.DateTimeFormat("en-AU").format(new Date(p))} */}
                {DateTime.fromISO(p).toFormat("LLL-yyyy")}
              </option>
            ))
          ) : (
            <option key="loading" value="loading">
              loading ...
            </option>
          )}
        </select>
      </div>
    </Fragment>
  );
};

export default PeriodSelect;
