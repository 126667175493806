import React, { useReducer } from "react";
import axios from "axios";
import BalanceContext from "./balanceContext";
import balanceReducer from "./balanceReducer";
import {
  GET_BALANCES,
  COPY_BALANCE_PERIOD,
  GET_TOTALS,
  GET_TOTALS_DAYSDUE,
  IMPORT_BALANCE,
  ADD_BALANCE,
  DELETE_BALANCE,
  UPDATE_BALANCE,
  SET_CURRENT_BALANCE,
  CLEAR_CURRENT_BALANCE,
  SET_DELETE_BALANCE,
  BALANCE_ERROR,
  GET_IMPORT_TEMPLATE,
  CLEAR_IMPORT_RESULT,
  GET_REPORTS,
  FILTER_BALANCE,
  CLEAR_FILTER_BALANCE,
  CLEAR_ERRORS,
  CLEAR_DELETE_ACCOUNT,
} from "../types";
import { DateTime } from "luxon";
import { cleanFilters } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "com.balancestate";

const BalanceState = (props) => {
  const initialState = {
    balances: null,
    current: null,
    filtered: null,
    filterOn: false,
    filters: null,
    deleteId: null,
    error: [],
    import_template: null,
    import_result: null,
    balanceTotals: null,
    totals: null,
    totalsDaysDue: null,
    report_data: {},
    report_adhoc_data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(balanceReducer, initialState);

  const copyThePeriod = async (date_period) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/balances/copyperiod/${date_period}`,
        config
      );

      dispatch({
        type: COPY_BALANCE_PERIOD,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Get Balances
  const getBalances = async (filters, sort) => {
    let lm = dp + ".getBalances";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/balances/`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_BALANCES,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getBalances:end`);
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Get Balances
  const getTotals = async (filters) => {
    let lm = dp + ".getTotals";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) {
        for (const property in filters)
          if (filters[property] === "" || filters[property] === null)
            delete filters[property];
      }

      let res = [];
      res = await axios.get(`/api/balances/totals`, { params: filters });

      dispatch({
        type: GET_TOTALS,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}:end`);
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Get getDayDueTotals
  const getDayDueTotals = async (filters, period) => {
    let lm = dp + ".getTotals";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (db) console.log(`${lm}`);
      await axios
        .post("/api/balances/daysDueTotals", { period }, config)
        .then((res) => {
          dispatch({
            type: GET_TOTALS_DAYSDUE,
            payload: res.data,
          });
        });

      if (debug >= 1) console.log(`${lm}:end`);
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Get Balances
  const getReports = async (period, report_name) => {
    let lm = dp + ".getReports";
    try {
      if (db) console.log(`${lm}:period: ${period}`);
      if (db) console.log(`${lm}:report_type: ${report_name}`);

      const params = new URLSearchParams([
        ["period", period],
        ["name", report_name],
      ]);

      if (db) console.log(`${lm}:params: ${params}`);

      // let res = [];
      await axios.get(`/api/reports`, { params }).then((res) => {
        dispatch({
          type: GET_REPORTS,
          payload: res.data,
        });
      });
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Get Adhoc Report
  const getAdhocReport = async (period, report_name) => {
    let lm = dp + ".getReports";
    // console.log('running');
    try {
      if (db) console.log(`${lm}:start`);

      const params = new URLSearchParams([
        ["period", period],
        ["name", report_name],
      ]);

      let res = await axios.get(`/api/reports/adhoc`, { params });
      // res should return {data: records, fields: field def}

      dispatch({
        type: GET_REPORTS,
        payload: res.data,
      });
      if (db) console.log(`${lm}:finish`);
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Add Contact
  const addBalance = async (balance) => {
    let lm = dp + ".addBalance";
    // not sending token as its send locally
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (db) console.log(`${lm}`);
    console.log(balance);
    await axios
      .post("/api/balances", balance, config)
      .then((response) => {
        dispatch({
          type: ADD_BALANCE,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: BALANCE_ERROR,
          payload: err,
        });
      });
  };

  // Update Contact
  const updateBalance = async (balance) => {
    let lm = dp + ".updateBalance: ";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(
        `/api/balances/${balance._id}`,
        balance,
        config
      );

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: UPDATE_BALANCE, payload: res.data });
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Get Import Template
  const getImportTemplate = async (options, cp) => {
    let lm = (dp = ".getImportTemplate");
    try {
      if (db) console.log(`${lm}:start`);

      const params = new URLSearchParams([
        ["estimateDates", options.estimateDates],
        ["copyInstallments", options.copyInstallments],
      ]);

      if (cp === undefined || cp === null || cp === "null")
        cp = DateTime.local();

      if (db) console.log(`${lm}.currentPeriod:${cp}`);

      let res = [];
      res = await axios.get(`/api/balances/importTemplate/${cp}`, {
        params,
      });

      dispatch({
        type: GET_IMPORT_TEMPLATE,
        payload: res,
      });
    } catch (err) {
      console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Update Contact
  const importBalances = async (import_data, period) => {
    let lm = (dp = ".importBalances");
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var theData = [{ data: import_data }];
      db = false;
      if (db) console.log(`${lm}: start`);
      if (db) console.log(`${lm}:import_data: ${import_data}`);
      if (db) console.log(`${lm}:period: ${period}`);
      if (db) console.log(`${lm}:theData:`);
      if (db) console.log(theData);

      // as we are being passed in the whole contact, we need to just refer
      //    to the ._id specifically
      const res = await axios.put(
        `/api/balances/import/${period}`,
        theData,
        config
      );

      if (db) console.log(`${lm}:result:`);
      if (db) console.log(res);

      // using res.data instead of the contact passed in as we want to get the
      //   contact item from the db instead of the argument
      dispatch({ type: IMPORT_BALANCE, payload: res.data, period: period });
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  const deleteBalance = async (id) => {
    try {
      // try registering user with form data and json config
      await axios.delete(`/api/balances/${id}`);

      dispatch({ type: DELETE_BALANCE, payload: id });
    } catch (err) {
      dispatch({
        type: BALANCE_ERROR,
        payload: err,
      });
    }
  };

  // Filter Contacts
  const filterBalances = (array) => {
    dispatch({
      type: FILTER_BALANCE,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_BALANCE, payload: { filterOn: false } });
  };

  const setCurrentBalance = (balance) => {
    dispatch({ type: SET_CURRENT_BALANCE, payload: balance });
  };

  const clearCurrentBalance = () => {
    dispatch({ type: CLEAR_CURRENT_BALANCE });
  };

  const clearImportResult = () => {
    dispatch({ type: CLEAR_IMPORT_RESULT });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteBalance = (balance) => {
    dispatch({ type: SET_DELETE_BALANCE, payload: balance });
  };

  const clearDeleteBalance = () => {
    dispatch({ type: CLEAR_DELETE_ACCOUNT });
  };

  return (
    <BalanceContext.Provider
      value={{
        balances: state.balances,
        current: state.current,
        filtered: state.filtered,
        filters: state.filters,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        import_template: state.import_template,
        import_result: state.import_result,
        balanceTotals: state.balanceTotals,
        totals: state.totals,
        totalsDaysDue: state.totalsDaysDue,
        report_data: state.report_data,
        report_adhoc_data: state.report_adhoc_data,
        error: state.error,
        loading: state.loading,
        getAdhocReport,
        getBalances,
        getTotals,
        getDayDueTotals,
        copyThePeriod,
        importBalances,
        addBalance,
        updateBalance,
        deleteBalance,
        setCurrentBalance,
        clearCurrentBalance,
        setDeleteBalance,
        clearDeleteBalance,
        getImportTemplate,
        clearImportResult,
        getReports,
        filterBalances,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </BalanceContext.Provider>
  );
};

BalanceState.propTypes = {
  children: PropTypes.object,
};

export default BalanceState;
