const LoanFields = [
  {
    field: "status",
    title: "Status",
    type: "string",
    input: ["button", ["Open", "Completed"]],
  },
  { field: "code", title: "Code", type: "string" },
  {
    field: "account",
    title: "Account",
    type: "string",
    input: ["dropdown", "accounts", "code"],
  },
  {
    field: "loanType",
    title: "Type",
    type: "string",
  },
  { field: "startDate", title: "Start Due", type: "date" },
  { field: "endDate", title: "End Date", type: "date" },
  { field: "nextInstallmentDate", title: "Next Date", type: "date" },
  { field: "periodsTotal", title: "Periods", type: "number" },
  { field: "periodsCompleted", title: "Completed", type: "number" },
  { field: "periodFrequency", title: "Frequency", type: "string" },
  { field: "interestRate", title: "Rate", type: "float" },
  { field: "totalAmount", title: "Total", type: "currency" },
  { field: "upfrontCosts", title: "Costs", type: "currency" },
  { field: "totalReceieved", title: "Received", type: "currency" },
  { field: "balanceAmount", title: "Balance", type: "currency" },
  { field: "installmentAmount", title: "Installment", type: "currency" },
  { field: "earlyPayDate", title: "Repaid Date", type: "date" },
  { field: "earlyPayBalance", title: "Repaid Balance", type: "currency" },
  { field: "earlyPayFee", title: "Repaid Fee", type: "currency" },
  { field: "comments", title: "Comments", type: "string" },
  { field: "active", title: "Active", type: "boolean" },
];

const LoanDownloadHeaders = [
  { label: "Status", key: "status" },
  { label: "Active", key: "active" },
  { label: "Code", key: "code" },
  { label: "LoanType", key: "loanType" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Next Date", key: "nextInstallmentDate" },
  { label: "Done", key: "periodsCompleted" },
  { label: "Total", key: "periodsTotal" },
  { label: "Frequency", key: "periodFrequency" },
  { label: "Rate", key: "interestRate" },
  { label: "Installment", key: "installmentAmount" },
  { label: "Balance", key: "balanceAmount" },
  { label: "Total Amount", key: "totalAmount" },
  { label: "Upfront Costs", key: "upfrontCosts" },
];

const LoanFilterFields = [
  "status",
  "code",
  "loanType",
  "periodFrequency",
  "account.name",
  "account.bank",
  "account.type",
  "account.country",
];

module.exports = { LoanFields, LoanDownloadHeaders, LoanFilterFields };
