import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import AccountContext from "../../context/account/accountContext";

import { findIndexInObj, ShowValueOrName } from "../../utils/functionsCommon";
import { AccountCode } from "../../utils/functionsBalances";
import {
  ActionButton,
  collapseButton,
  modalButton,
} from "../../utils/functionsForms";

const AccountItem = ({ account, fields, isTable }) => {
  const accountContext = useContext(AccountContext);
  const {
    setCurrentAccount,
    setDeleteAccount,
    // clearDeleteAccount,
    // deleteAccount,
  } = accountContext;

  // const { _id } = account;

  // console.log(isMobile);
  // const daysForWarning = 4;

  const recordId = account._id;
  const record = account;

  const buttonColour = isTable ? "btn-primary" : "btn-outline-light";
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const cardBody = {
    left: ["code", "bank", "type"],
    right: ["country", "country_code", "active"],
  };
  const cardFooter = {
    left: ["type"],
    right: [],
  };
  // const cardHeader = {
  //   left: ["code"],
  //   right: ["country_code"],
  // };

  const fns = {
    btn_Edit: function btn_Edit() {
      return ActionButton(buttonStyle, setCurrentAccount, "Edit", account);
    },
    btn_Delete: function btn_Delete() {
      return modalButton(
        "deleteModal",
        () => clickedDelete(account._id),
        buttonStyle,
        "Delete"
      );
    },
  };

  const clickedDelete = (accountId) => {
    setDeleteAccount(accountId);
  };

  const displayFields = (cardFields, leftright, fields, record) =>
    cardFields[leftright].map((name) => {
      let i = findIndexInObj(fields, "field", name);
      return (i===-1) ? "" : (
        <div key={"card-field-" + name + "-" + record._id}>
          {name.substring(0, 4) === "btn_"
            ? fns[name]()
            : ShowValueOrName(name, fields[i].type, record[fields[i].field])}
          <br />
        </div>
      );
//       return (
//         <div key={"card-field-" + name + "-" + record._id}>
// Test
//           <br />
//         </div>
//       );
    });

  const displayCardContent = (cardDetail, fields, record) =>
    ["left", "right"].map((leftright) => (
      <div
        className="col"
        key={"card-fields-col-" + leftright + "-" + record._id}
      >
        <div
          // className={leftright === "right" ? "float-start" : "float-end"}
          className={leftright === "right" ? "float-end" : "float-start"}
          key={"card-fields-" + leftright + "-" + record._id}
        >
          {displayFields(cardDetail, leftright, fields, record)}
        </div>
      </div>
    ));

  // const onDelete = () => deleteAccount(_id);

  const ShowMobileCode = () => {
    let k = "card-" + recordId;
    return (
      <div className={"card bg-secondary text-white"} key={k}>
        <div className="card-header" key={k + "-header"}>
          <div className="row w-100 g-0" key={k + "-header-r1"}>
            <div className="col-8" key={k + "-header-r1c1"}>
              <div className="float-start" key={k + "-header-r1c1-d1"}>
                {collapseButton(
                  "account-" + recordId,
                  AccountCode(record.code),
                  "text-white"
                )}
              </div>
            </div>
            <div className="col-4" key={k + "-header-r1c2"}>
              <div className="float-end" key={k + "-header-r1c2-d1"}>
                {record.country_code}
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-body collapse"
          key={"card-body-" + recordId}
          id={"account-" + recordId}
          style={{ padding: "5px" }}
        >
          <div className="row w-100 g-0" key={k + "-content-r1"}>
            {displayCardContent(cardBody, fields, record)}
          </div>

          <div className="row w-100 g-0" key={k + "-content-r2"}>
            <div
              className="d-flex justify-content-center"
              key={"card-body-buttons-" + recordId}
            >
              {fns["btn_Edit"]()}
              {fns["btn_Delete"]()}
            </div>
          </div>
        </div>

        <div className="card-footer" key={k + "-footer"}>
          <div className="row w-100 g-0" key={k + "-footer-row"}>
            {displayCardContent(cardFooter, fields, record)}
          </div>
        </div>
      </div>
    );
  };

  const ShowNonMobileCode = () => {
    return (
      <tr key={"container-" + recordId}>
        <td key={"row-" + recordId} style={{ whiteSpace: "nowrap" }}>
          {fns["btn_Edit"]()}
          {fns["btn_Delete"]()}
        </td>
        {fields.map((field, idx) => {
          return (
            <td key={"divTableCell-" + idx}>
              {record && field?.field && record[field.field]
                ? ShowValueOrName(field.title, field.type, record[field.field])
                : field.title}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <Fragment>{!isTable ? ShowMobileCode() : ShowNonMobileCode()}</Fragment>
  );
};

AccountItem.propTypes = {
  account: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isTable: PropTypes.bool,
};

export default AccountItem;
