import React, { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { DateTime } from "luxon";
import { formatValue } from "../../utils/functionsCommon";
import { collapseButton } from "../../utils/functionsForms";

import PropTypes from "prop-types";

const MonthlyInstallments = ({ options }) => {
  useEffect(() => {
    if (options?.getFnc) options.getFnc();
    //eslint-disable-next-line
  }, []);

  let dlOption = true;
  let errors = [];
  Object.keys(options).forEach((opt) => {
    if (!options[opt]) {
      let msg;
      if (opt.substring(0, 2) === "dl") {
        dlOption = false;
        msg = `[inst-report] Missing DL option: ${opt}, DL feature will not be enabled`;
      } else {
        msg = `[inst-report] Missing Report option ${opt}, may not work correctly`;
      }
      console.log(msg);
      errors.push(msg);
    }
  });
  if (errors?.length)
    errors.map((err, idx) => <li key={"error-" + idx}>{err}</li>);

  const rptName = !options?.reportName ? "inst-report" : options?.reportName;
  const kbase = rptName.replace(" ", "-").toLowerCase();
  const collapseTitle = rptName.replace(" ", "");
  const csvlink = useRef("");
  const dateTime = DateTime.local();
  const dateStr = dateTime.toFormat("ccc-LL-LLL-hh-mm");
  const tdStyle = { padding: "1px", textAlign: "center" };

  const emptyCell = (styl, key) => <td style={styl} key={key}></td>;
  const getFldValue = (record, fldDef) => {
    if (!fldDef?.field) return "Field not defined";
    if (!record || !record[fldDef.field]) return "No data";

    return formatValue(fldDef.type, record[fldDef.field]);
  };

  const ShowReportData = (record, idx) => {
    let k = kbase + "-month-" + idx;
    return (
      <tr key={k + "-row"}>
        {record && options?.fields?.length
          ? options.fields.map((field, idx) => {
              if (!field.field) return emptyCell(tdStyle, k + "-empty" + idx);
              let fldName = field.field;
              return (
                <td style={tdStyle} key={k + fldName + "-cell-" + idx}>
                  {getFldValue(record, field)}
                </td>
              );
            })
          : emptyCell(tdStyle, k + "-empty" + idx)}
      </tr>
    );
  };

  const showCSVLink = (k) => (
    <CSVLink
      ref={csvlink}
      name="csvlink"
      filename={`${
        options?.dlTitle ? options.dlTitle : "report"
      }-${dateStr}.csv`}
      data={options?.data?.length ? options.data : []}
      headers={options.dlHeaders}
    >
      <button
        key={k + "card-csv-btn"}
        className="btn btn-primary"
        type="button"
      >
        Download
      </button>
    </CSVLink>
  );

  const ShowAdhocReport = (records) => {
    let k = kbase + "-card-";
    return (
      <div className="card bg-light mb-1" key={k}>
        <div className="card-header" key={k + "hdr"}>
          <div className="card-title mb-0" key={k + "hdr-title"}>
            <div className="row" key={k + "card-hdr-row"}>
              <div className="col align-self-center" key={k + "card-hdr-col1"}>
                {collapseButton(collapseTitle, rptName, "btn-light")}
              </div>
              <div className="col" key={k + "card-hdr-col2"}>
                {dlOption && records?.length ? (
                  <span className="float-end" key={k + "card-csv-span"}>
                    {showCSVLink(k)}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={"card-body collapse text-center"}
          id={collapseTitle}
          style={{ padding: ".5rem", fontsize: "0.1em" }}
          key={k + "card-bdy"}
        >
          {records?.length ? (
            <table
              className="table table-striped table-light"
              key={k + "card-tbl"}
            >
              <thead key={k + "card-thd"}>
                <tr key={k + "card-thd-tr"}>
                  {options.fields?.length ? (
                    options.fields.map((field, idx) => (
                      <th key={k + "card-thd-th-" + idx}>{field.title}</th>
                    ))
                  ) : (
                    <th key={k + "card-thd-loading"}>loading ...</th>
                  )}
                </tr>
              </thead>
              <tbody key={k + "card-bdy"}>
                {records && records.length > 0
                  ? records.map((record, idx) => ShowReportData(record, idx))
                  : ""}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return ShowAdhocReport(options.data);
};

MonthlyInstallments.propTypes = {
  options: PropTypes.object.isRequired,
};

export default MonthlyInstallments;
