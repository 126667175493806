import { checkIfMatches } from "../../utils/functionsFilters";
import {
  GET_BUDGET,
  GET_BUDGETS,
  GET_BUDGET_ACTIVE,
  GET_BUDGET_TOTALS,
  GET_MONTHLY,
  // COPY_BUDGET_PERIOD,
  // IMPORT_BUDGET,
  ADD_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET,
  SET_CURRENT_BUDGET,
  CLEAR_CURRENT_BUDGET,
  // GET_IMPORT_TEMPLATE,
  // CLEAR_IMPORT_RESULT,
  // GET_REPORTS,
  FILTER_BUDGET,
  CLEAR_FILTER_BUDGET,
  BUDGET_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_BUDGET,
  CLEAR_DELETE_BUDGET,
} from "../typesBudget";

const fields = ["name", "dateStart", "dateEnd"];

const budgetReducer = (state, action) => {
  switch (action.type) {
    case GET_BUDGET:
      return {
        ...state,
        budget: action.payload,
      };

    case GET_BUDGET_ACTIVE:
      return {
        ...state,
        activeBudget: action.payload,
      };

    case GET_BUDGETS:
      return {
        ...state,
        budgets: action.payload,
      };

    case ADD_BUDGET:
      return {
        ...state,
        budgets: [action.payload, ...state.budgets],
      };

    case UPDATE_BUDGET:
      return {
        ...state,
        budget: action.payload,
        // as above state is being passed and state is immutable, need to make a copy
      };

    // case UPDATE_BUDGET:
    //   return {
    //     ...state,
    //     budgets: state.budgets.map((budget) =>
    //       budget._id === action.payload._id ? action.payload : budget
    //     ),
    //     // as above state is being passed and state is immutable, need to make a copy
    //   };

    case DELETE_BUDGET:
      return {
        ...state,
        budgets: state.budgets.filter(
          (budget) => budget._id !== action.payload
        ),
      };

    // case DELETE_BUDGET:
    //   return {
    //     ...state,
    //     budgets: state.budgets.filter(
    //       (budget) => budget._id !== action.payload
    //     ),
    //   };

    case SET_CURRENT_BUDGET:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAR_CURRENT_BUDGET:
      return {
        ...state,
        current: null,
      };

    case SET_DELETE_BUDGET:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_BUDGET:
      return {
        ...state,
        deleteId: null,
      };

    case GET_BUDGET_TOTALS:
      return {
        ...state,
        budgetTotals: action.payload,
      };

    case GET_MONTHLY:
      return {
        ...state,
        monthly: action.payload,
      };

    case FILTER_BUDGET:
      return {
        ...state,
        filtered: state.budgets.filter((budget) =>
          checkIfMatches(budget, action, fields)
        ),
        filterOn: true,
      };

    case CLEAR_FILTER_BUDGET:
      return {
        ...state,
        filtered: null,
        filterOn: false,
      };

    case BUDGET_ERROR:
      return {
        ...state,
        error: state?.error?.length
          ? [action.payload, ...state.error]
          : state.error,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default budgetReducer;
