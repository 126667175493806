import { DateTime } from "luxon";
import React from "react";
import Select from "react-select";
import { buttonClasses } from "./functionsBalances";
import { emptyArr, findInObj, objHasKey } from "./functionsCommon";

export const fieldTag = (key, isMobile, data, hidden) => {
  let style = hidden ? { display: "none" } : {};

  return isMobile ? (
    <div key={key} style={style}>
      {data}
    </div>
  ) : (
    <td key={key} style={style} className="p-1">
      {data}
    </td>
  );
};

export const buttonCenter = () => (
  <div className="d-flex justify-content-center"></div>
);

const createID = (field, record) => {
  return record?.["_id"] ? field + "-" + record["_id"] : field;
};

export const showFields = (args, style) =>
  args.fields.map((field, idx) => {
    let tagId = createID(
      field.field,
      args?.record?._id ? args?.record : { _id: "add" + idx }
    );

    if (style?.hideCols?.includes(field.title))
      return fieldTag(tagId, !style?.isTable, "", true);

    let new_args = {
      tagId: tagId,
      field: findInObj(args.fields, "field", field.field),
      record: args.record,
      onChange: args.onChange,
      onBlur: args.onBlur,
      onFocusOut: args.onFocusOut,      
      onDateClear: args.onDateClear,
      add: args.add,
      showLabel: !style?.isTable,
    };

    let data;
    if (field.input) {
      if (field.input[0] === "dropdown") {
        let selectTitle = field.input?.[2] ? field.input[2] : "_id";
        data = dropDownInput(
          args.arrays[field.input[1]],
          selectTitle,
          new_args
        );
      } else if (field.input[0] === "button") {
        data = buttonInput(new_args);
      } else {
        data = textInput(new_args);
      }
    } else if (field.type === "date") {
      data = showDatePicker(new_args);
      // data = textInput(new_args);
    } else {
      data = textInput(new_args);
    }

    // let k =
    //   (args?.record?._id ? args?.record?._id : "add") +
    //   "-" +
    //   field.field +
    //   "-" +
    //   idx;
    return fieldTag(tagId, !style?.isTable, data, false);
    // return style?.isTable || args?.isMobile ? (
    //   <td className="align-middle m-0 g-0 p-0" key={k}>
    //     {fieldTag(tagId, args.isMobile, data)}
    //   </td>
    // ) : (
    //   fieldTag(tagId, args.isMobile, data)
    // );
  });

export const buttonInput = (args) => {
  let buttons = args.field.input[1];

  // add, has no record
  // edit, has a record
  // loading only for edit
  return !emptyArr(buttons) &&
    (args.add || objHasKey(args.record, args.field.field)) ? (
    <div
      className="btn-toolbar"
      style={{ justifyContent: "center" }}
      key={`btn-toolbar-${args.tagId}`}
    >
      <div
        className={args.showLabel ? "btn-group" : "text-center"}
        data-bs-toggle="btn-success"
        key={`btn-group-${args.tagId}`}
      >
        {buttons.map((button) => {
          let buttonClass = findInObj(
            buttonClasses,
            "name",
            button.toLowerCase()
          );
          buttonClass = buttonClass ? buttonClass : "primary";

          let selected =
            !args.add && args.record[args.field.field] === button
              ? "btn-" + buttonClass.class + " active"
              : "btn-light";

          // 'btn btn-sm btn-' + selected + buttonClass.class,
          return ActionButton(
            "btn btn-sm " + selected,
            args.onChange,
            button,
            [args.field.field, button],
            args.tagId
          );
        })}
      </div>
    </div>
  ) : (
    <span key={args.field.field + "-loading"}>still loading</span>
  );
};

export const textInput = (args) => {
  args.inputType = "text";
  let opts = tagOptions(args);
  opts.className = "form-control";

  return (
    <div className="form-group">
      <div className="input-group  input-group-sm">
        {args?.showLabel ? (
          <div className="input-group-prepend">
            <span
              style={{ width: "6rem" }}
              className="input-group-text"
              id={`input-addon-${args.tagId}`}
            >
              <small>{args.field.title}</small>
            </span>
          </div>
        ) : (
          ""
        )}
        <input
          type="text"
          {...opts}
          id={opts.name}
          aria-describedby={`input-addon-${args.tagId}`}
          aria-label={args.field.title}
          aria-labelledby={args.field.title}
          title={args.field.title}
        />
      </div>
    </div>
  );
};

const showDatePicker = (args) => {
  args.inputType = "datepicker";
  let opts = tagOptions(args);
  let clearArgs = { target: { name: args.field.field, value: "" } };

  if (
    !args.add &&
    args.record &&
    args.field &&
    args.field.field &&
    args.record[args.field.field]
  ) {
    let theDate = DateTime.fromISO(args.record[args.field.field]);
    opts.value = theDate.toString().substring(0, 10);
    clearArgs = {
      target: { name: args.field.field, value: "", action: "clear" },
    };
  }

  opts.className = "form-control";
  return (
    // <div className='form-group'>
    <div className="form-group">
      <div className="input-group  input-group-sm">
        {args?.showLabel ? (
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              style={{ width: "6rem" }}
              id={`picker-addon-${args.tagId}`}
            >
              <small>{args.field.title}</small>
            </span>
          </div>
        ) : (
          ""
        )}
        <input
          {...opts}
          id={opts.name}
          type="date"
          aria-describedby={`button-${args.field.field} picker-addon-${args.tagId}`}
          aria-label={args.field.title}
          aria-labelledby={args.field.title}
          title={args.field.title}
        />
        <button
          type="button"
          key={`button-${args.field.field}`}
          id={`button-${args.field.field}`}
          className="btn btn-secondary"
          onClick={() => args.onDateClear(clearArgs, "clear")}
        >
          <i className="far fa-window-close	" />
        </button>
      </div>
    </div>
  );
};

export const dropDownInput = (value_list, titleField, args) => {
  args.inputType = "dropdown";
  // console.log(value_list);

  if (!emptyArr(value_list)) {
    let fieldName = args.field.field;
    let record = args.record;

    let selectList = value_list.map((item) => {
      let opt = { value: item._id, label: item[titleField] };
      return opt;
    });
    let opts = tagOptions(args);
    if (record && record[fieldName] && record[fieldName]._id) {
      record[fieldName].value = record[fieldName]._id;
      record[fieldName].label = record[fieldName][titleField];
    }

    opts.className = "form-control";
    return (
      <div className="form-group">
        <div className="input-group  input-group-sm ">
          {args?.showLabel ? (
            <div className="input-group-prepend">
              <span
                className="input-group-text h-100"
                style={{ width: "6rem" }}
                id={`picker-addon-${args.tagId}`}
              >
                <small>{args.field.title}</small>
              </span>
            </div>
          ) : (
            ""
          )}
          <Select
            style={{ margin: "0rem", width: "100%" }}
            {...opts}
            value={
              record && record[fieldName] && record[fieldName]._id
                ? record[fieldName]
                : "Select ..."
            }
            options={selectList}
          />
        </div>
      </div>
    );
  }

  return <span key={args.field.field + "-loading"}>still loading</span>;
};

const tagOptions = (args) => {
  let { field, title } = args.field;
  let opts = {};

  opts.name = field;
  opts.placeholder = title;
  opts.key = field + "-" + args.inputType + "-" + args.tagId;
  opts.onChange = args.onChange;
  opts.onBlur = args.onBlur;
  opts.onFocusOut = args.onFocusOut;
  if (!args.add) {
    if (objHasKey(args.record, field) && args.record[field] !== null) {
      opts.value = args.record[field];
    } else {
      opts.value = "";
    }
  }

  return opts;
};

export const collapseMultiButton = (
  target,
  controls,
  value,
  classname,
  show,
  onclick
) => (
  <button
    onClick={onclick !== null ? () => onclick() : (e) => e.preventDefault()}
    className={classname}
    data-bs-toggle="collapse"
    data-bs-target={target}
    aria-expanded={show}
    aria-controls={controls}
  >
    {value}
  </button>
);

export const collapseButton = (target, value, classname) => (
  <button
    type="button"
    className={"btn btn-link " + classname}
    data-bs-toggle="collapse"
    data-bs-target={"#" + target}
    aria-expanded="false"
    aria-controls="collapseExample"
    style={{ textDecoration: "none" }}
  >
    {value}
  </button>
);

export const ActionButton = (classname, action, name, param, keyExt) => (
  <button
    type="button"
    key={`button-${name}-${keyExt ? keyExt : ""}`}
    className={classname}
    onClick={() => action(param)}
  >
    {name}
  </button>
);

export const modalButton = (
  modalName,
  clickFunction,
  buttonStyle,
  buttonText
) => (
  <button
    className={buttonStyle}
    data-bs-toggle="modal"
    data-bs-target={"#" + modalName}
    onClick={clickFunction}
  >
    {buttonText}
  </button>
);

export const modalDeleteHandler = (deleteFunction, deleteId) => {
  if (deleteId) {
    deleteFunction(deleteId);
    let deleteModal = document.getElementById("closeDeleteModal");
    deleteModal.click();
    return false;
  } else {
    alert("No record to delete.");
  }
};

// export const ActionButton = (classname, action, name, param, id) => {
//   // console.log(name);
//   return (
//     <button
//       type='button'
//       key={`button-${(isObject(name) ? classname : name) + id}`}
//       className={classname}
//       onClick={() => action(param)}
//     >
//       {name}
//     </button>
//   );
// };
