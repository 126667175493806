import React from "react";
import PropTypes from "prop-types";

const DeleteModal = ({ deleteHandler }) => {
  return (
    <div id="deleteModal" className="modal fade">
      <div className="modal-dialog modal-confirm ">
        <div className="modal-content ">
          <div className="modal-header border-0">
            <div className="container-fluid ">
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div className="row">
                <div className="col d-flex justify-content-around pb-3">
                  <div className="icon-box  ">
                    <i className="fas fa-exclamation-circle fa-8x text-danger"></i>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col d-flex justify-content-around mt-2">
                  <h1 className="text-secondary">Are you sure?</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body mx-5">
            <h5 className="text-secondary text-center">
              Do you really want to delete these records? This process cannot be
              undone.
            </h5>
          </div>
          <div className="modal-footer border-0 mb-4 justify-content-center ">
            <button
              id="closeDeleteModal"
              type="button"
              className="btn btn-secondary btn-lg"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger btn-lg"
              data-dismiss="modal"
              onClick={(e) => deleteHandler(e)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
};

export default DeleteModal;
