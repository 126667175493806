import React, { Fragment } from "react";

export const isHide = (hc, fld) => hc?.includes(fld);

export const showHiddenCols = (vfHideSet) =>
  vfHideSet.v?.length > 0
    ? vfHideSet.v.map((col) => (
        <button
          className="btn btn-sm btn-secondary me-1 mb-1"
          key={"showHidden" + col}
          onClick={() =>
            vfHideSet.f(vfHideSet.v.filter((item) => item !== col))
          }
        >
          {col}
        </button>
      ))
    : "";

export const showFieldHeaders = (fieldArray, vfHideSet, vfSoftSet) =>
  fieldArray.map((field, idx) =>
    vfHideSet.v?.includes(field.title) ? (
      <th key={"th-" + idx} style={{ display: "none" }}></th>
    ) : (
      showHideSortButtons(field, vfHideSet, vfSoftSet, idx)
    )
  );

export const showHideSortButtons = (field, vfHideSet, vfSoftSet, idx) => (
  <th className="divTableHead justify-content-center" key={"th-" + idx}>
    <div className="row flex-nowrap justify-content-center">
      <div className="col-auto">{field.title}</div>
    </div>
    <div className="row flex-nowrap justify-content-center">
      <div className="col-auto pe-0">
        {showHideColIcon(field.title, vfHideSet)}
      </div>
      {field.title === "Actions" ? (
        ""
      ) : (
        <Fragment>
          <div className="col-auto p-0">
            {sortButton(field.field, true, vfSoftSet)}
          </div>
          <div className="col-auto ps-0">
            {sortButton(field.field, false, vfSoftSet)}
          </div>
        </Fragment>
      )}
    </div>
  </th>
);

const showIcon = (icon, key, style) => (
  <span className="material-icons" key={key} style={style}>
    {icon}
  </span>
);

let iconStyle = { fontSize: "1.0em" };

export const showHideColIcon = (title, vfHideSet) => {
  return (
    <button
      key={"showHideCol-btn-" + title.replace(" ", "")}
      onClick={() => vfHideSet.f([...vfHideSet.v, title])}
      className="btn p-0 m-0 g-0"
      data-inline="true"
    >
      {showIcon("visibility_off", "showHideCol-" + title, { ...iconStyle })}
    </button>
  );
};

export const sortButton = (field, asc, vfSoftSet) => {
  let sortCode = field + (asc ? ".asc" : ".desc");
  let colour = sortCode === vfSoftSet.v ? "red" : "black";
  let iconName = "arrow_" + (asc ? "up" : "down") + "ward";
  let key = "sortButton-" + field + "-" + sortCode;

  // { fontSize: "1.2em", display: "inline" }
  return (
    <button
      key={key}
      onClick={() => vfSoftSet.f({ target: { value: sortCode } })}
      className="btn p-0 m-0 g-0"
      data-inline="true"
    >
      {showIcon(iconName, key + "-icon", { ...iconStyle, color: colour })}
    </button>
  );
};
