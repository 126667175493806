import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import BudgetContext from "../../context/budget/budgetContext";

import { isArray, findInObj, returnISODate } from "../../utils/functionsCommon";
import {
  ActionButton,
  collapseMultiButton,
  showFields,
} from "../../utils/functionsForms";

const BudgetFormAddEdit = ({ add, fields, isMobile }) => {
  const budgetContext = useContext(BudgetContext);
  const {
    // budget,
    current,
    addBudget,
    updateBudget,
    clearCurrentBudget,
  } = budgetContext;

  let defaultBudgetSet = {};
  for (let field of fields) {
    defaultBudgetSet[field.field] = "";
  }
  const [updBudget, setUpdBudget] = useState(defaultBudgetSet);

  useEffect(() => {
    if (add) {
      setUpdBudget(defaultBudgetSet);
    } else {
      setUpdBudget(current);
    }
    // eslint-disable-next-line
  }, [budgetContext, current]);

  const onChange = (e) => {
    let name, value;

    if (isArray(e) && e.length === 2) {
      name = e[0];
      value = e[1];
      // } else if (a && a.action) {
      //   name = "account";
      //   value = findInObj(accounts, "_id", e.value);
    } else {
      name = e.target.name;

      let field = findInObj(fields, "field", name);
      value =
        field.type === "date"
          ? e.target.value === ""
            ? null
            : returnISODate(e.target.value)
          : e.target.value;
    }

    setUpdBudget({
      ...updBudget,
      [name]: value,
    });
  };

  const onDateClear = (e) => {
    let name = e.target.name;
    let value = null;

    setUpdBudget({
      ...updBudget,
      [name]: value,
    });
  };

  const onSaveAdd = (budget) => {
    console.log(budget);

    if (add) {
      addBudget(budget);
    } else {
      updateBudget(budget);
    }
    clearForm();
  };

  const clearForm = () => {
    if (add) {
      setUpdBudget(defaultBudgetSet);
    } else {
      clearCurrentBudget();
    }
  };

  const cardClass = add
    ? ["primary", "text-white", "light"]
    : ["secondary", "text-white", "light"];

  const arrays = {};

  // const buttonStyle = `btn btn-${add ? "outline " : cardClass[1]}-light btn-sm`;
  // const buttonStyle = `btn btn-outline-${cardClass[0]} btn-sm`;
  const isTable = false;
  const buttonColour = isTable ? "btn-secondary" : "btn-light";
  const buttonStyle = "btn " + buttonColour + " btn-sm";

  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(buttonStyle, onSaveAdd, "Save", updBudget);
    },

    btn_Add: function btn_Add() {
      return ActionButton(buttonStyle, onSaveAdd, "Add", updBudget);
    },

    btn_Cancel: function btn_Delete() {
      return ActionButton(buttonStyle, clearCurrentBudget, "Cancel", "");
    },
  };

  // const bAdd = false;
  const fieldArgs = {
    arrays: arrays,
    fields: fields,
    record: updBudget,
    onChange: onChange,
    onDateClear: onDateClear,
    isMobile: isMobile,
    add: add,
  };

  const ShowMobileCode = () =>
    add ? (
      <div className="card bg-primary  mb-1" key={"card-1"}>
        <div className="card-header">
          {collapseMultiButton(
            ".multi-collapse",
            "multiBudget1 multiBudget2",
            "Add Budget",
            buttonStyle,
            false,
            null
          )}
        </div>
        <div className="collapse multi-collapse" id="multiBudget1">
          <div className="card-body">
            <form id="form-add">{showFields(fieldArgs)}</form>
          </div>
        </div>
        <div className="collapse multi-collapse" id="multiBudget2">
          <div className="card-footer">
            <div className="d-flex justify-content-center">
              {fns["btn_Add"]()}
              {collapseMultiButton(
                ".multi-collapse",
                "multiBudget1 multiBudget2",
                "Cancel",
                buttonStyle,
                false,
                clearForm
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={`card bg-${cardClass[0]}  mb-1 form-group`}>
        <div className={`card-header ${cardClass[1]}`}>
          {add ? "Add Budget" : `Edit Budget: ${updBudget?.name}`}
        </div>
        <div className="card-body">
          <form id={`form-edit-${updBudget._id}`}>{showFields(fieldArgs)}</form>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-center">
            {add ? fns["btn_Add"]() : fns["btn_Save"]()}
            {fns["btn_Cancel"]()}
          </div>
        </div>
      </div>
    );

  const ShowNonMobileCode = () => (
    <div className="divTableRow">
      {showFields(fieldArgs)}
      <div className="divTableCell justify-content-center">
        {add ? fns["btn_Add"]() : fns["btn_Save"]()}
        {fns["btn_Cancel"]()}
      </div>
    </div>
  );

  return isMobile ? ShowMobileCode() : ShowNonMobileCode();
};

BudgetFormAddEdit.propTypes = {
  add: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
};

export default BudgetFormAddEdit;
